import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { arrayOf, func, number, shape, string } from 'prop-types';
import {
  FormField,
  IconButton,
  Input,
  LinkButton,
  Loader,
} from '@poly/admin-book';
import { propEqLegacy, strToPositiveNumber } from '@poly/utils';
import { ServiceTypesSelect } from '@poly/admin-ui';
import {
  FlexContainer,
  FlexContainerS,
  FooterContainer,
  LabelS,
  LineContainer,
  SpaceBetween,
  TotalContainer,
  ValueText,
} from '../common/styles.js';
import { getLinesTotal, getLineSubtotalByIndex } from '../common/utils.js';
import { useServiceTypeDetails } from './withServiceTypeDetails.js';

const psCategories = [
  // [<PS category code>, <PS GL code>, <PS category description>],
  ['76110000', '82160155', 'Cleaning & Janitorial Services'],
  ['46000000', '82160155', 'Security Charges'],
  ['81112201', '83325248', 'Fees- Miscellaneous'],
  ['80161508', '85690001', 'Document Shredding'],
  ['50202310', '83290001', 'Spring or mineral water'],
  [
    '43000000',
    '83330194',
    'Information Technology Broadcasting and Telecommunications',
  ],
];

function EntityAddressItemComp({ label, value }) {
  return (
    <FlexContainerS width={100}>
      <LabelS width={15}>{label}</LabelS>
      <ValueText>{value}</ValueText>
    </FlexContainerS>
  );
}

EntityAddressItemComp.propTypes = {
  label: string.isRequired,
  value: string,
};

export function EntityAddressComp({ address, addressByOcr, label }) {
  return (
    <FlexContainerS direction="column">
      <EntityAddressItemComp label={`${label} Address`} value={address} />
      <EntityAddressItemComp label="Address By OCR" value={addressByOcr} />
    </FlexContainerS>
  );
}

EntityAddressComp.propTypes = {
  address: string,
  addressByOcr: string,
  label: string.isRequired,
};

const LineHeaderLabel = styled(LabelS)`
  margin: 0;
`;

export function LinesHeader() {
  return (
    <SpaceBetween>
      <LineHeaderLabel width={2}>#</LineHeaderLabel>
      <LineHeaderLabel width={20}>Description</LineHeaderLabel>
      <LineHeaderLabel width={15}>Service Type</LineHeaderLabel>
      <LineHeaderLabel width={10}>Category</LineHeaderLabel>
      <LineHeaderLabel width={10}>GL Code</LineHeaderLabel>
      <LineHeaderLabel width={10}>Price</LineHeaderLabel>
      <LineHeaderLabel width={7}>Quantity</LineHeaderLabel>
      <LineHeaderLabel width={5}>Subtotal</LineHeaderLabel>
      <LineHeaderLabel width={2} />
    </SpaceBetween>
  );
}

// getPsGlCodeByServiceType :: ServiceType -> String
const getPsGlCodeByServiceType = R.compose(
  R.defaultTo(''),
  R.nth(1),
  R.defaultTo([]),
  R.find(R.__, psCategories),
  propEqLegacy('0'),
  R.prop('psCategoryId'),
  R.defaultTo({}),
);

// getPSCategoryByServiceType :: ServiceType -> String
const getTIAACategoryByServiceType = R.compose(
  R.defaultTo(''),
  R.prop('tiaaCategoryName'),
);

const ServiceTypeDetails = styled.div`
  position: relative;
`;

const InputS = styled(Input)`
  width: 100%;
`;

function ServiceTypeInfo({ serviceTypeId, getInfo }) {
  const { loading, serviceType } = useServiceTypeDetails(serviceTypeId);

  if (loading) return <Loader size={20} />;

  return serviceTypeId ? <span>{getInfo(serviceType)}</span> : null;
}

ServiceTypeInfo.propTypes = {
  serviceTypeId: string,
  getInfo: func.isRequired,
};

ServiceTypeInfo.displayName = 'ServiceTypeInfo';

export function UpdateInvoiceLineField({
  name,
  index,
  fields: { value: values, remove },
}) {
  return (
    <LineContainer>
      <FlexContainerS width={2}>{index + 1}</FlexContainerS>
      <FlexContainerS width={20}>
        <FormField
          name={`${name}.description`}
          Component={Input}
          showErrorMsg={false}
          additionalProps={{
            placeholder: 'Enter description',
          }}
        />
      </FlexContainerS>
      <FlexContainerS width={15}>
        <FormField
          name={`${name}.serviceTypeId`}
          Component={ServiceTypesSelect}
          additionalProps={{
            required: true,
            width: '100%',
          }}
          showErrorMsg={false}
        />
      </FlexContainerS>
      <FlexContainerS width={10}>
        <ServiceTypeDetails>
          <ServiceTypeInfo
            serviceTypeId={R.path([index, 'serviceTypeId'], values)}
            getInfo={getTIAACategoryByServiceType}
          />
        </ServiceTypeDetails>
      </FlexContainerS>
      <FlexContainerS width={10}>
        <ServiceTypeDetails>
          <ServiceTypeInfo
            serviceTypeId={R.path([index, 'serviceTypeId'], values)}
            getInfo={getPsGlCodeByServiceType}
          />
        </ServiceTypeDetails>
      </FlexContainerS>
      <FlexContainerS width={10}>
        <FormField
          parse={strToPositiveNumber}
          name={`${name}.price`}
          Component={InputS}
          showErrorMsg={false}
          additionalProps={{
            type: 'number',
            step: 0.01,
            min: 0,
          }}
        />
      </FlexContainerS>
      <FlexContainerS width={7}>
        <FormField
          parse={strToPositiveNumber}
          name={`${name}.quantity`}
          Component={InputS}
          showErrorMsg={false}
          additionalProps={{
            type: 'number',
            step: 0.01,
            min: 0,
          }}
        />
      </FlexContainerS>
      <FlexContainerS width={5}>
        <ValueText>{getLineSubtotalByIndex(values, index)}</ValueText>
      </FlexContainerS>
      <FlexContainerS width={2}>
        {values && values.length > 1 && (
          <IconButton
            onClick={() => remove(index)}
            initialColor="#ff6868"
            hoverColor="#ed3d3d"
            name="close"
            size={8}
          />
        )}
      </FlexContainerS>
    </LineContainer>
  );
}

UpdateInvoiceLineField.propTypes = {
  name: string.isRequired,
  index: number.isRequired,
  fields: shape({
    value: arrayOf(
      shape({
        price: number,
        quantity: number,
      }),
    ),
    remove: func.isRequired,
  }).isRequired,
};

// getCommonProps :: Properties -> Object
const getCommonProps = R.applySpec({
  addItem: R.compose(
    (push) => () => push('lines', {}),
    R.path(['mutators', 'push']),
  ),
  total: R.compose(getLinesTotal, R.path(['formData', 'lines'])),
});

export function InvoiceFooter(props) {
  const { addItem, total } = getCommonProps(props);

  return (
    <FooterContainer>
      <TotalContainer>
        <LinkButton onClick={addItem} type="button">
          Add Item
        </LinkButton>
        <FlexContainer>
          <LabelS>Total</LabelS>
          <ValueText>{total}</ValueText>
        </FlexContainer>
      </TotalContainer>
    </FooterContainer>
  );
}
