import styled, { css } from 'styled-components';
import { Table, moneyColumnStyles } from '@poly/admin-book';

const withSelectableStyles = css`
  th:first-child,
  td:first-child {
    width: 60px;
  }
`;

export const ApprovalTableC = styled(Table)`
  padding: 30px 0 3px 0;
  min-width: 1024px;

  th:nth-child(5),
  td:nth-child(5),
  th:nth-child(6),
  td:nth-child(6) {
    width: 9%;
  }

  ${moneyColumnStyles(7)};

  @media (max-width: 1450px) {
    th:nth-child(5),
    td:nth-child(5),
    th:nth-child(6),
    td:nth-child(6) {
      width: 12%;
    }
  }

  ${({ selectedRows }) => selectedRows && withSelectableStyles};
`;
