import React from 'react';
import * as R from 'ramda';
import { bool, string } from 'prop-types';
import {
  keywordSortQuery,
  useHighlightMatchesBySearch,
} from '@poly/client-utils';
import {
  TableWithPaginationContainer,
  TableCard,
  Table,
  Loader,
} from '@poly/admin-book';
import {
  useMapConfigToTableProps,
  useSetInitialPaginationOnMount,
  useTableSorting,
  getServiceTypes,
  useSetItemsCount,
  TablePagination,
} from '@poly/admin-ui';
import { ASC_SORT_ORDER, NOTHING_UI_STRING } from '@poly/constants';
import { assocBy } from '@poly/utils';
import { SupplierLink } from '../../containers/links/Links.js';
import { useSuppliersBySearch } from './apollo/useSuppliersBySearch.js';

const getSupplierLink = ({ _id, company }) => (
  <SupplierLink _id={_id} name={R.prop('name', company)} />
);

const getSupplierAddress = R.compose(
  R.when(R.anyPass([R.isEmpty, R.isNil]), R.always(NOTHING_UI_STRING)),
  R.path(['company', 'address', 'formatted_address']),
);

const tableConfig = [
  ['Name', getSupplierLink, keywordSortQuery(['company', 'name'])],
  [
    'Address',
    getSupplierAddress,
    keywordSortQuery(['company', 'address', 'formatted_address']),
  ],
  ['Service Types', R.prop('serviceTypesStr')],
];

function SuppliersTableComp(props) {
  return (
    <TableCard>
      <TableWithPaginationContainer paginationVisible>
        <Table {...props} />
      </TableWithPaginationContainer>
      <TablePagination />
    </TableCard>
  );
}

SuppliersTableComp.propTypes = { paginationVisible: bool };

// getTotalSuppliers :: SearchSuppliersQueryResult -> Number
const getTotalSuppliers = R.pathOr(0, ['searchSuppliers', 'total']);

// getSuppliers :: SearchSuppliersQueryResult :: [Supplier]
const getSuppliers = R.pathOr([], ['searchSuppliers', 'hits']);

// getSearchSuppliersServiceTypesStr :: SearchSuppliersQueryResult :: [ModifiedSupplier]
const getSearchSuppliersServiceTypesStr = R.over(
  R.lensPath(['searchSuppliers', 'hits']),
  R.map(assocBy('serviceTypesStr', getServiceTypes)),
);

export function SuppliersTable({ status }) {
  useSetInitialPaginationOnMount();

  const { sort, ...tableSortingProps } = useTableSorting({
    tableConfig,
    column: 1,
    order: ASC_SORT_ORDER,
  });

  const { loading, result } = useSuppliersBySearch({ sort, status });

  useSetItemsCount(getTotalSuppliers, result);

  const modifiedSuppliers = loading
    ? result
    : getSearchSuppliersServiceTypesStr(result);

  const { rows, ...tableProps } = useMapConfigToTableProps(
    getSuppliers,
    tableConfig,
    modifiedSuppliers,
  );

  const { highlightedRows } = useHighlightMatchesBySearch(
    R.identity,
    [
      ['company', 'name'],
      ['company', 'address', 'formatted_address'],
      ['serviceTypesStr'],
    ],
    rows,
  );

  if (loading) return <Loader />;

  return (
    <SuppliersTableComp
      {...tableSortingProps}
      {...tableProps}
      rows={highlightedRows}
    />
  );
}

SuppliersTable.displayName = 'SuppliersTable';

SuppliersTable.propTypes = {
  status: string,
};
