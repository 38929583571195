import {
  useEntitiesByReactiveSearch,
  useHasUserAccessWithPermission,
} from '@poly/client-utils';
import { READ_FILE_PERMISSION } from '@poly/security';
import { DESC_SORT_ORDER } from '@poly/constants';
import { useSelector } from 'react-redux';
import { usePagination } from '../../redux/pagination.js';
import { ENTITY_FILES_QUERY } from './queries.js';
import { FILES_SEARCH_CHANGED } from './subscriptions.js';

const useEntityFilesBySearch = ({ collection, entity, skipQuery = false }) => {
  const skip = skipQuery || !entity?._id;

  const { pagination } = usePagination();
  const searchText = useSelector((state) => state.searchText);

  const { result, loading } = useEntitiesByReactiveSearch({
    gqlSearchQuery: ENTITY_FILES_QUERY,
    gqlSearchChangedQuery: FILES_SEARCH_CHANGED,
    sort: { createdAt: DESC_SORT_ORDER },
    query: {
      nested: {
        path: 'relations',
        query: {
          bool: {
            must: [
              { match: { 'relations.collection': collection } },
              { match: { 'relations._id': entity?._id } },
            ],
          },
        },
      },
    },
    pagination,
    searchText,
    skipQuery: skip,
  });

  return {
    result,
    loading,
  };
};

export const useEntityFiles = ({ collection, entity }) => {
  const ifHasPermission = useHasUserAccessWithPermission(READ_FILE_PERMISSION);

  const { result, loading } = useEntityFilesBySearch({
    collection,
    entity,
    skipQuery: !ifHasPermission,
  });

  return {
    result,
    loading,
  };
};
