import * as R from 'ramda';
import { pathEqLegacy } from '@poly/utils';

import { batchStatuses } from '../../../../utils/constants/statuses.js';

// batchStatusIn :: [BatchStatus] -> Batch -> Boolean
const batchStatusIn = (statuses) =>
  R.pathSatisfies(R.includes(R.__, statuses), ['workflow', 'status']);

// isNewStatus :: Batch -> BatchStatus
// BatchStatus = String
export const isNewStatus = pathEqLegacy(
  ['workflow', 'status'],
  batchStatuses.NEW,
);

// isFundedStatus :: Batch -> BatchStatus
export const isFundedStatus = pathEqLegacy(
  ['workflow', 'status'],
  batchStatuses.FUNDED,
);

// isAllowedSendToXero :: Batch -> Boolean
export const isAllowedSendToXero = batchStatusIn([
  batchStatuses.APPROVED,
  batchStatuses.PENDING_SYNC,
  batchStatuses.SYNC_OK,
]);

// filterSelectedInvoices :: [ID] -> [OcrInvoice] -> [OcrInvoice]
export const filterSelectedInvoices = (selectedInvoicesIds) =>
  R.filter(R.propSatisfies(R.includes(R.__, selectedInvoicesIds), '_id'));

// isAllowedCheckPaymentFile :: Batch -> Boolean
export const isAllowedCheckPaymentFile = batchStatusIn([
  batchStatuses.SENT_TO_AP,
  batchStatuses.SENT_TO_CITI,
  batchStatuses.FUNDED,
  batchStatuses.CITY_SYNC_ERROR,
]);
