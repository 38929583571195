import React from 'react';
import { commonPeopleFields, usePagination } from '@poly/admin-ui';
import { gql } from '@apollo/client';
import { useRouterParams } from '@poly/client-routing';
import { PeopleTab } from '../../../containers/tabs/PeopleTab.js';

export const PROPERTY_PEOPLE = gql`
  query PROPERTY_PEOPLE($entityId: ID!, $searchInput: CollectionSearchParams!) {
    property(id: $entityId) {
      _id
      searchContacts(input: $searchInput) {
        hits {
          ...commonPeopleFields
        }
        total
      }
    }
  }
  ${commonPeopleFields}
`;

export function PropertyPeopleTab() {
  const { pagination } = usePagination();

  const { propertyId } = useRouterParams(['propertyId']);

  return (
    <PeopleTab
      gqlSearchQuery={PROPERTY_PEOPLE}
      pagination={pagination}
      entity={{
        _id: propertyId,
        name: 'property',
      }}
    />
  );
}
