import * as R from 'ramda';
import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { string } from 'prop-types';
import { assocBy } from '@poly/utils';
import { RecurringOCRInvoiceReceiptMethod } from '@poly/constants';
import {
  Heading,
  DefaultBodyWrapper,
  PageHeaderContainer,
  Table,
  useTableSortable,
  SectionsWrapper,
  Loader,
} from '@poly/admin-book';
import styled from 'styled-components';
import { SearchInputComp, useMapConfigToTableProps } from '@poly/admin-ui';

import { HolderS } from './styles.js';
import { missingInvoicesTableConfig } from './missingInvoicesTableConfig.js';
import {
  useSearchTextState,
  filterBySearchTextWithHighlight,
} from './helpers.js';

function SearchMissingInvoicesInput(props) {
  return (
    <SearchInputComp
      {...props}
      name="search-missing-invoices"
      placeholder="Search"
    />
  );
}

const TableS = styled(Table)`
  th:nth-child(1),
  td:nth-child(1) {
    width: 150px;
  }
  th:nth-child(5),
  td:nth-child(5) {
    width: 100px;
  }
  th:nth-child(6),
  td:nth-child(6) {
    width: 100px;
  }
`;

// eslint-disable-next-line import/no-unused-modules
export const missingInvoicesQuery = gql`
  query MISSING_OCR_INVOICES_REPORT_QUERY {
    missingOCRInvoicesReport {
      recurringInvoice {
        _id
        supplier {
          _id
          company {
            name
          }
        }
        property {
          _id
          name
        }
        referenceId
        supplierOuterContractId
        receiptMethod
      }
      expectedDates
    }
  }
`;

// getReceiptMethodCaption :: String -> String
const getReceiptMethodCaption = R.prop(
  R.__,
  R.invertObj(RecurringOCRInvoiceReceiptMethod),
);

const searchMissingInvoicesPaths = [
  ['recurringInvoice', 'referenceId'],
  ['recurringInvoice', 'supplier', 'company', 'name'],
  ['recurringInvoice', 'property', 'name'],
  ['recurringInvoice', 'receiptMethod'],
  ['recurringInvoice', 'supplierOuterContractId'],
];

// propsToFilteredMissingInvoicesEntries :: TableProps -> [MissingOCRInvoiceReportEntry]
//  TableProps = {searchText: String, missingOCRInvoicesReport: [MissingOCRInvoiceReportEntry]}
const propsToFilteredMissingInvoicesEntries = (props) =>
  R.compose(
    filterBySearchTextWithHighlight(
      searchMissingInvoicesPaths,
      props.searchText,
    ),
    R.map(
      R.compose(
        // prevent prop types warning at Table
        assocBy('_id', R.path(['recurringInvoice', '_id'])),
        R.over(
          R.lensPath(['recurringInvoice', 'receiptMethod']),
          getReceiptMethodCaption,
        ),
      ),
    ),
    R.propOr([], 'missingOCRInvoicesReport'),
  )(props);

function MissingInvoicesTable(props) {
  const tableConfigProps = useMapConfigToTableProps(
    propsToFilteredMissingInvoicesEntries,
    missingInvoicesTableConfig,
    props,
  );
  const tableSortProps = useTableSortable(tableConfigProps);
  return <TableS {...props} {...tableConfigProps} {...tableSortProps} />;
}

MissingInvoicesTable.propTypes = {
  searchText: string.isRequired,
};

export function MissingInvoicesPage() {
  const [searchText, onSearchTextChange] = useSearchTextState();

  const { data, loading } = useQuery(missingInvoicesQuery, {
    fetchPolicy: 'network-only',
  });

  return (
    <>
      <PageHeaderContainer>
        <Heading size="h2">Missing Invoices</Heading>
        <p>Shows missing invoices over last 3 months</p>
        <SearchMissingInvoicesInput
          onChange={onSearchTextChange}
          value={searchText}
        />
      </PageHeaderContainer>
      <DefaultBodyWrapper>
        <HolderS>
          <SectionsWrapper>
            {loading ? (
              <Loader />
            ) : (
              <MissingInvoicesTable
                searchText={searchText}
                missingOCRInvoicesReport={data?.missingOCRInvoicesReport}
              />
            )}
          </SectionsWrapper>
        </HolderS>
      </DefaultBodyWrapper>
    </>
  );
}
