import React from 'react';
import { Select } from '@poly/admin-book';

export function ThresholdSelect(props) {
  const thresholdSelectOptions = [
    { value: 0, label: 'Full Spend' },
    { value: 600, label: '$600 and Greater' },
  ];

  const thresholdSelectProps = {
    ...props,
    width: '170px',
    name: 'ThresholdSelect',
    dataTestId: 'threshold-select',
    options: thresholdSelectOptions,
    placeholder: 'Select amount threshold',
  };

  return <Select {...thresholdSelectProps} />;
}
