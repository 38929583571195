import * as R from 'ramda';
import { gql, useQuery } from '@apollo/client';
import { commonUpdateFields, usePagination } from '@poly/admin-ui';
import { useRouterParams } from '@poly/client-routing';
import {
  useEntitySubscriptionQuery,
  useSubscriptionByChanges,
} from '@poly/client-utils';
import { batchFields } from './fragments.js';
import { OCR_INVOICES_BY_SEARCH_SUB } from '../../invoice/apollo/useOcrInvoicesBySearch.js';

const BATCH_QUERY = gql`
  query BATCH($id: ID!) {
    batch(id: $id) {
      ...batchFields
    }
  }

  ${batchFields}
`;

const BATCH_CHANGED = gql`
  subscription BATCH_CHANGED($input: SingleDocSubInput!) {
    batchChanged(input: $input) {
      id
      type
      document {
        ...batchFields
      }
    }
  }

  ${batchFields}
`;

export const BATCH_UPDATES = gql`
  query BATCH_UPDATES($id: ID!, $documentUpdatesInput: DocumentUpdatesInput!) {
    batch(id: $id) {
      _id
      documentUpdates(input: $documentUpdatesInput) {
        hits {
          ...commonUpdateFields
        }
        total
      }
    }
  }

  ${commonUpdateFields}
`;

export const useBatchDetails = () => {
  const { batchId } = useRouterParams(['batchId']);

  const { data, loading } = useQuery(BATCH_QUERY, {
    variables: { id: batchId },
    fetchPolicy: 'network-only',
  });

  return {
    data,
    loading,
    batchId,
  };
};

export const useBatchSubscription = () => {
  const { pagination } = usePagination();
  const routerParams = useRouterParams(['batchId']);

  const { restProps } = useEntitySubscriptionQuery({
    gqlQuery: BATCH_QUERY,
    gqlSubscription: BATCH_CHANGED,
    queryEndpointName: 'batch',
    subscriptionEndpointName: 'batchChanged',
    queryParamsFromProps: R.compose(
      R.objOf('id'),
      R.path(['routerParams', 'batchId']),
    ),
    subscriptionParamsFromProps: R.compose(
      R.objOf('id'),
      R.path(['routerParams', 'batchId']),
    ),
    propsOfComponent: {
      pagination,
      routerParams,
    },
    fetchPolicy: 'network-only',
    alias: 'useBatchSubscription',
  });

  useSubscriptionByChanges({
    gqlQueryChanged: OCR_INVOICES_BY_SEARCH_SUB,
    refetch: restProps.refetch,
    result: restProps,
  });

  return {
    result: restProps,
  };
};
