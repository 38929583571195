import React from 'react';
import { useDispatch } from 'react-redux';
import {
  ConfirmModal,
  GalleryModal,
  ModalWindow as ModalWindowImp,
  openConfirmModal,
} from '@poly/admin-ui';
import { ConfirmPrintModal } from './confirmPrintModal/index.js';
import { CreateSupplierModal } from '../../modules/supplier/CreateSupplierModal/CreateSupplierModal.js';
import { ApproveInvoicesModal } from '../../modules/batch/ApproveInvoicesModal.js';
import { EditServiceTypeModal } from '../../modules/serviceType/EditServiceTypeModal/EditServiceTypeModal.js';
import { CreateServiceTypeModal } from '../../modules/serviceType/CreateServiceTypeModal/CreateServiceTypeFormModal.js';
import { OpenXtractaModal } from '../../components/modals/OpenXtractaModal.js';
import { ConfirmInvoiceSaveModal } from '../../modules/invoice/ConfirmInvoiceSaveModal/ConfirmInvoiceSaveModal.js';
import { ModalNames } from '../../utils/constants/modals.js';
import { ConfirmCancelFormEdit } from '../../components/modals/ConfirmCancelFormEdit.js';
import { AddFilesModal } from '../forms/addFilesForm/AddFilesForm.js';
import { AddUpdateModal } from '../forms/addUpdateForm/AddUpdateForm.js';
import { AddPeopleModal } from '../../modules/contact/contactForm/AddPeopleForm.js';
import { EditPeopleModal } from '../../modules/contact/contactForm/EditPeopleForm.js';
import { confirmButtons } from './confirmButtons/index.js';
import { confirmButtonNames } from './confirmButtons/constants.js';
import { confirmCancelFormPayload } from '../buttons/ActionButtons.js';
import { UpdateBatchModalForm } from '../../modules/batch/UpdateBatchModalForm.js';
import { AddTaskForm } from '../forms/taskForm/AddTaskForm.js';
import { EditTaskForm } from '../forms/taskForm/EditTaskForm.js';
import { CompleteTaskForm } from '../forms/taskForm/CompleteTaskForm.js';
import { PostTaskUpdateForm } from '../forms/taskForm/PostTaskUpdateForm.js';
import { CreateEditRecurringInvoiceModal } from '../../modules/recurringInvoice/CreateEditRecurringInvoiceModal.js';

const modals = {
  [ModalNames.APPROVE_INVOICES_MODAL]: ApproveInvoicesModal,
  [ModalNames.OPEN_XTRACTA_MODAL]: OpenXtractaModal,
  [ModalNames.CONFIRM_INVOICE_SAVE]: ConfirmInvoiceSaveModal,
  [ModalNames.CREATE_SUPPLIER]: CreateSupplierModal,
  [ModalNames.CONFIRM_PRINT]: ConfirmPrintModal,
  [ModalNames.CREATE_SERVICE_TYPE]: CreateServiceTypeModal,
  [ModalNames.EDIT_SERVICE_TYPE]: EditServiceTypeModal,
  [ModalNames.ADD_FILES]: AddFilesModal,
  [ModalNames.ADD_UPDATE]: AddUpdateModal,
  [ModalNames.ADD_PEOPLE]: AddPeopleModal,
  [ModalNames.CONFIRM_CANCEL_FORM_EDIT]: ConfirmCancelFormEdit,
  [ModalNames.EDIT_PEOPLE]: EditPeopleModal,
  [ModalNames.GALLERY]: GalleryModal,
  [ModalNames.EDIT_BATCH]: UpdateBatchModalForm,
  [ModalNames.ADD_TASK]: AddTaskForm,
  [ModalNames.EDIT_TASK]: EditTaskForm,
  [ModalNames.COMPLETE_TASK]: CompleteTaskForm,
  [ModalNames.POST_TASK_UPDATE]: PostTaskUpdateForm,
  [ModalNames.CREATE_EDIT_RECURRING_INVOICE_MODAL]:
    CreateEditRecurringInvoiceModal,
  empty: () => null,
};

export function Modal() {
  const dispatch = useDispatch();

  const onConfirmClose = () =>
    dispatch(
      openConfirmModal({
        payload: {
          ...confirmCancelFormPayload,
          confirmButtonName: confirmButtonNames.CONFIRM_CLOSE_MODAL,
        },
      }),
    );

  return <ModalWindowImp modals={modals} onConfirmClose={onConfirmClose} />;
}

export function ConfirmModalWindow() {
  return <ConfirmModal confirmButtons={confirmButtons} />;
}
