import styled from 'styled-components';
import { FileViewer, Heading } from '@poly/admin-book';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 98%;
`;

Container.displayName = 'InvoiceVerifyContainer';

export const FileViewerS = styled(FileViewer)`
  width: 35%;
  height: 100%;
`;

FileViewerS.displayName = 'StyledFileViewer';

export const FormSection = styled.div`
  height: 100%;
  width: 65%;
`;

FormSection.displayName = 'StyledFormSection';

export const FormHeader = styled(Heading).attrs({ size: 'h2' })`
  margin: 10px 0 0 25px;
`;

FormHeader.displayName = 'StyledFormHeader';

export const FormWrapper = styled.div`
  height: calc(100% - 40px);
  padding: 20px 30px;
`;

FormWrapper.displayName = 'StyledFormWrapper';
