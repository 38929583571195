import React from 'react';
import * as R from 'ramda';
import { NOTHING_UI_STRING, OCRInvoiceStatuses } from '@poly/constants';
import { PageHeaderComponentByTabQuery } from '@poly/admin-ui';
import { EntityDetail, EntityStatus, Loader } from '@poly/admin-book';
import { UPDATE_OCR_INVOICE_PERMISSION } from '@poly/security';
import { shape, string, bool } from 'prop-types';
import {
  formatDateOr,
  useHasUserAccessWithPermission,
} from '@poly/client-utils';
import { useLocation } from '@poly/client-routing';
import { forceTitleCase } from '@poly/utils';
import { getInvoiceStatusColorAndText } from '../../../../utils/statuses.js';
import { getPreviousLink } from './invoicePageHeaderUtils.js';
import { useInvoiceAccount } from '../../apollo/useInvoiceAccount.js';
import { INVOICE_EDIT_FORM_ID } from '../InvoiceEditForm/invoiceEditConstants.js';
import {
  BackButton,
  EntityId,
  HeaderFlexRow,
} from '../../../../components/lib/pageHeader.js';
import { ActionButtons } from '../../../../containers/buttons/ActionButtons.js';
import { checkIfIsItInvoiceRoute } from '../../../../containers/appBar/AppBar.js';

function InvoiceHeaderC({
  status,
  invoiceId,
  previousLink,
  paidAt,
  checkNumber,
  allowEditInvoice,
  viewerHasPermission,
}) {
  return (
    <PageHeaderComponentByTabQuery key={invoiceId}>
      <HeaderFlexRow>
        <BackButton link={previousLink} />
        <EntityId>{invoiceId}</EntityId>
        <EntityStatus title="Invoice" status={status} />
        <EntityDetail title="Check #" value={checkNumber} />
        <EntityDetail title="Date" value={paidAt} />
      </HeaderFlexRow>
      {allowEditInvoice && viewerHasPermission && (
        <ActionButtons
          formId={INVOICE_EDIT_FORM_ID}
          permission={UPDATE_OCR_INVOICE_PERMISSION}
        />
      )}
    </PageHeaderComponentByTabQuery>
  );
}

InvoiceHeaderC.propTypes = {
  status: shape({
    text: string.isRequired,
    color: string.isRequired,
  }).isRequired,
  invoiceId: string.isRequired,
  previousLink: string.isRequired,
  paidAt: string,
  checkNumber: string,
  allowEditInvoice: bool,
  viewerHasPermission: bool,
};

InvoiceHeaderC.defaultProps = {
  paidAt: '',
  checkNumber: '',
};

// getCommonProps :: SearchInvoicesQueryResult -> Object
const getCommonProps = R.applySpec({
  invoiceId: R.path(['ocrInvoice', 'invoiceId']),
  status: R.compose(
    R.evolve({ text: R.when(R.identity, (x) => forceTitleCase(x)) }),
    getInvoiceStatusColorAndText,
    R.path(['ocrInvoice', 'workflow', 'status']),
  ),
  paidAt: R.compose(
    formatDateOr(NOTHING_UI_STRING),
    R.path(['ocrInvoice', 'workflow', 'check', 'issuanceDate']),
  ),
  checkNumber: R.pathOr(NOTHING_UI_STRING, [
    'ocrInvoice',
    'workflow',
    'check',
    'checkNumber',
  ]),
  allowEditInvoice: R.complement(
    R.pathSatisfies(
      R.includes(R.__, [OCRInvoiceStatuses.APPROVED, OCRInvoiceStatuses.PAID]),
      ['ocrInvoice', 'workflow', 'status'],
    ),
  ),
});

export function InvoicePageHeader() {
  const ifHasPermission = useHasUserAccessWithPermission(
    UPDATE_OCR_INVOICE_PERMISSION,
  );

  const location = useLocation();

  const isInvoiceRoute = checkIfIsItInvoiceRoute(location);

  const { data, loading } = useInvoiceAccount(isInvoiceRoute);

  const commonProps = getCommonProps(data);

  if (loading) return <Loader />;

  return (
    <InvoiceHeaderC
      {...commonProps}
      viewerHasPermission={ifHasPermission}
      previousLink={getPreviousLink(location.state)}
    />
  );
}

InvoicePageHeader.displayName = 'InvoicePageHeader';
