import * as R from 'ramda';
import { roundTo, insertParamsIntoURL, ofArrayLegacy } from '@poly/utils';
import { OCRInvoiceStatuses } from '@poly/constants';
import { paginationToQueryParams } from '@poly/client-utils';

import { routes } from '../routes/constants.js';
import { formatTotal } from './foramatters/index.js';
import { ALL, ALL_VALUES } from './constants/general.js';

const { INVOICE } = routes;

// calcInvoicesTotal :: [Object] -> Number
export const calcInvoicesTotal = R.reduce(
  (acc, { total }) => roundTo(2)(acc + total),
  0,
);

// getFormattedInvoicesTotal :: [Object] -> String
export const getFormattedInvoicesTotal = R.pipe(calcInvoicesTotal, formatTotal);

// getInvoicesQueryVars :: ([String], Object) -> Object
export const getInvoicesQueryVars = (invoiceIds, pagination) => ({
  variables: {
    searchInput: {
      query: {
        bool: {
          must_not: [{ match: { status: OCRInvoiceStatuses.BLOCKED } }],
          must: [{ terms: { _id: invoiceIds } }],
        },
      },
      ...paginationToQueryParams(pagination),
    },
  },
});

/**
 * generateQueueInvoicesMustQuery :: {batchStatus, propertyId, managerId} -> [MatchQueries]
 */
export const generateQueueInvoicesMustQuery = R.converge(R.concat, [
  R.pipe(
    R.prop('OCRInvoiceStatus'),
    R.ifElse(
      R.both(R.identity, R.complement(R.equals(ALL))),
      R.pipe(R.objOf('workflow.status'), R.objOf('match'), ofArrayLegacy),
      R.always([]),
    ),
  ),
  R.pipe(
    R.pick(['propertyId', 'managerId']),
    R.reject(R.anyPass([R.isEmpty, R.equals(ALL_VALUES)])),
    R.toPairs,
    R.map(R.pipe(([key, value]) => ({ [key]: value }), R.objOf('match'))),
  ),
]);

// createInvoiceExternalLink :: String -> String
export const createInvoiceExternalLink = (id) =>
  `${window.location.origin}${insertParamsIntoURL({ invoiceId: id })(INVOICE)}`;
