import * as R from 'ramda';
import { formatPhoneNumber, forceTitleCase } from '@poly/utils';
import { pathOrNothingUI } from '@poly/client-utils';

const formatPhone = R.compose(formatPhoneNumber('-'), R.objOf('number'));

export const preparePropertyDetails = R.converge(R.mergeDeepLeft, [
  R.applySpec({
    phone: R.compose(formatPhone, R.prop('phone')),
    fax: R.compose(formatPhone, R.prop('fax')),
    remarks: pathOrNothingUI(['remarks']),
    occupancy: R.compose(forceTitleCase, R.prop('occupancy')),
    ownership: R.compose(forceTitleCase, R.prop('ownership')),
    budgetCode: pathOrNothingUI(['clientOuterId']),
    peopleSoftID: pathOrNothingUI(['clientExternalId']),
    tier: pathOrNothingUI(['tier']),
    affiliate: pathOrNothingUI(['clientUnitName']),
    type: pathOrNothingUI(['typeByClient']),
    square: pathOrNothingUI(['square']),
    clientName: R.path(['client', 'name']),
  }),
  R.pick(['address', 'addressTwo']),
]);
