import React from 'react';
import { formatSelectOptionsByConstantsValue } from '@poly/client-utils';
import { propertyStatuses } from '@poly/constants';
import { Select } from '@poly/admin-book';

export function StatusSelect(props) {
  const options = formatSelectOptionsByConstantsValue(propertyStatuses);

  return <Select {...props} options={options} />;
}

StatusSelect.displayName = 'PropertyStatusSelect';
