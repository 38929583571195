import React, { useState } from 'react';
import * as R from 'ramda';
import { LinkButtonWithLoader } from '@poly/admin-book';
import {
  commonSortQuery,
  ExcelExportCellType,
  createExcelExportCell,
  performExcelExport,
} from '@poly/client-utils';
import { EXPORT_XLS_CAPTION, DESC_SORT_ORDER } from '@poly/constants';
import { useApolloClient } from '@apollo/client';
import { usePaginationParams } from '@poly/admin-ui';
import { useRouterParams } from '@poly/client-routing';
import { formatDate } from '@poly/utils';

import { OCR_INVOICES_BY_SEARCH } from '../../invoice/apollo/useOcrInvoicesBySearch.js';
import { createInvoiceExternalLink } from '../../../utils/invoices.js';

const useGetPropertyInvoices = () => {
  const [loading, setLoading] = useState(false);
  const apolloClient = useApolloClient();

  const paginationParams = usePaginationParams();

  const { propertyId } = useRouterParams(['propertyId']);

  const getPropertyInvoicesP = async () => {
    setLoading(true);
    const result = await apolloClient.query({
      query: OCR_INVOICES_BY_SEARCH,
      variables: {
        searchInput: {
          query: { match: { propertyId } },
          sort: commonSortQuery(['invoiceId'])(DESC_SORT_ORDER),
          ...paginationParams,
        },
      },
      fetchPolicy: 'network-only',
    });
    setLoading(false);
    return result;
  };

  return { getPropertyInvoicesP, loading };
};

// getInvoicesRowConfigBase :: (Invoice -> String) -> [Invoice] -> [ExcelExportDataCell]
export const getInvoicesRowConfigBase = (getField) =>
  R.compose(
    R.zipWith(createExcelExportCell, [
      ExcelExportCellType.hyperlink,
      ...R.repeat(ExcelExportCellType.default, 3),
      ExcelExportCellType.defaultCurrency,
      ...R.repeat(ExcelExportCellType.default, 4),
    ]),
    R.juxt([
      R.applySpec({
        text: R.propOr('', 'invoiceId'),
        hyperlink: R.compose(createInvoiceExternalLink, R.propOr('', '_id')),
      }),
      getField,
      R.propOr('', 'invoiceNumber'),
      R.compose(formatDate, R.prop('invoiceDate')),
      R.propOr(0, 'total'),
      R.pathOr('', ['batch', 'batchId']),
      R.pathOr('', ['workflow', 'check', 'checkNumber']),
      R.compose(formatDate, R.path(['workflow', 'check', 'issuanceDate'])),
      R.pathOr('', ['workflow', 'status']),
    ]),
  );

export const getInvoicesExportExcelConfigBase =
  ({ fileName, titleName, getRows }) =>
  (invoices) => ({
    exportFileName: fileName,
    columnWidths: [20, 40, 25, 15, 15, 20, 15, 10, 15],
    rows: [
      R.zipWith(
        createExcelExportCell,
        [
          ...R.repeat(ExcelExportCellType.tableHeader, 4),
          ExcelExportCellType.tableCurrencyHeader,
          ...R.repeat(ExcelExportCellType.tableHeader, 4),
        ],
        [
          'Invoice ID',
          titleName,
          'Invoice #',
          'Inv Date',
          'Amount',
          'Batch ID',
          'Check #',
          'Date Paid',
          'Status',
        ],
      ),
      ...R.map(getRows, invoices),
    ],
  });

// getPropertyInvoicesRowConfig :: [Invoice] -> [ExcelExportDataCell]
const getPropertyInvoicesRowConfig = getInvoicesRowConfigBase(
  R.pathOr('', ['supplier', 'company', 'name']),
);

// eslint-disable-next-line import/no-unused-modules
export const getPropertyInvoicesExportExcelConfig =
  getInvoicesExportExcelConfigBase({
    fileName: 'property_invoices.xlsx',
    titleName: 'Supplier',
    getRows: getPropertyInvoicesRowConfig,
  });

export function PropertyInvoicesExportXLSBtn() {
  const { getPropertyInvoicesP, loading } = useGetPropertyInvoices();

  const handleClick = async () => {
    const { data } = await getPropertyInvoicesP();

    const invoices = R.pathOr([], ['searchOCRInvoices', 'hits'], data);

    const excelConfig = getPropertyInvoicesExportExcelConfig(invoices);

    performExcelExport(excelConfig);
  };

  return (
    <LinkButtonWithLoader loader={loading} onClick={handleClick}>
      {EXPORT_XLS_CAPTION}
    </LinkButtonWithLoader>
  );
}
