import React from 'react';
import { SupplierSources } from '@poly/constants';
import {
  ConvertSupplierComponent,
  EditBranchSupplierForm,
  EditSupplierForm as CommonEditSupplierForm,
} from '@poly/admin-ui';
import { editSupplierFormId } from './constants.js';
import { routes } from '../../../routes/constants.js';
import { useSupplierAccount } from '../apollo/useSupplierAccount.js';

const convertSupplierFormId = 'convertSupplierFormId';

function ConvertTIAASupplierComponent() {
  return (
    <ConvertSupplierComponent
      formId={convertSupplierFormId}
      masterSupplierUrl={routes.MASTER_SUPPLIER}
    />
  );
}

export function EditSupplierForm() {
  const { supplier } = useSupplierAccount();

  const commonProps = {
    formId: editSupplierFormId,
    supplierSource: SupplierSources.TIAA,
    supplierUrl: routes.SUPPLIER,
    masterSupplierRootUrl: routes.MASTER_SUPPLIER,
    ConvertSupplierComponent: ConvertTIAASupplierComponent,
    supplier,
  };

  return !supplier?.masterSupplier ? (
    <CommonEditSupplierForm {...commonProps} />
  ) : (
    <EditBranchSupplierForm {...commonProps} />
  );
}
