import React from 'react';
import styled from 'styled-components';
import { LinkButtonWithLoader } from '@poly/admin-book';
import { CommonPrintLayout } from '@poly/admin-ui';
import { PRINT_PDF_CAPTION } from '@poly/constants';
import { openPrintWindowWithData } from '@poly/client-utils';
import { bool } from 'prop-types';

import {
  userManagementPropTypes,
  UserManagementTable,
} from './UserManagementTable.js';

const ExportPDFBtn = styled(LinkButtonWithLoader)`
  text-align: end;
`;

function UserManagementPrintTable(props) {
  return <UserManagementTable {...props} isPrint />;
}

export function UserManagementExportPdfBtn({ users, loading }) {
  const handleClick = async () => {
    await openPrintWindowWithData({
      Layout: CommonPrintLayout,
      Table: UserManagementPrintTable,
      fileName: 'user_management',
      metaData: {
        title: 'User Management',
      },
      users,
    });
  };
  return (
    <ExportPDFBtn onClick={handleClick} disabled={loading}>
      {PRINT_PDF_CAPTION}
    </ExportPDFBtn>
  );
}

UserManagementExportPdfBtn.propTypes = {
  users: userManagementPropTypes.isRequired,
  loading: bool.isRequired,
};
