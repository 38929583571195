import React from 'react';
import * as R from 'ramda';
import { assocBy } from '@poly/utils';
import { GlobalSearchResultComponent } from '@poly/admin-ui';

import { globalSearchQuery } from '../globalSearchQuery.js';
import { globalSearchTabsConfig } from './globalSearchConfig.js';

const transformData = assocBy(
  'searchChecks',
  R.compose(
    R.applySpec({ hits: R.identity, total: R.prop('length') }),
    R.filter(R.path(['workflow', 'check', 'checkNumber'])),
    R.pathOr([], ['searchOCRInvoices', 'hits']),
  ),
);

export function GlobalSearchResult() {
  return (
    <GlobalSearchResultComponent
      transformData={transformData}
      globalSearchQuery={globalSearchQuery}
      globalSearchTabsConfig={globalSearchTabsConfig}
    />
  );
}

GlobalSearchResult.displayName = 'GlobalSearchResult';
