import { gql } from '@apollo/client';
import { useEntitiesByReactiveSearch } from '@poly/client-utils';
import { useSelector } from 'react-redux';
import { usePagination } from '@poly/admin-ui';

export const SERVICE_TYPES_BY_SEARCH = gql`
  query SERVICE_TYPES_BY_SEARCH($searchInput: CollectionSearchParams!) {
    searchServiceTypes(input: $searchInput) {
      hits {
        _id
        name
        status
        description
        psCategoryId
        tiaaCategoryName
      }
      total
    }
  }
`;

const SERVICE_TYPES_BY_SEARCH_SUB = gql`
  subscription SERVICE_TYPES_BY_SEARCH_SUB($input: CollectionSearchParams) {
    searchServiceTypeChanged(input: $input) {
      id
      type
    }
  }
`;

export const useServiceTypesBySearch = ({ query, sort, skipQuery }) => {
  const { pagination } = usePagination();
  const searchText = useSelector((state) => state.searchText);

  const { loading, result } = useEntitiesByReactiveSearch({
    gqlSearchQuery: SERVICE_TYPES_BY_SEARCH,
    gqlSearchChangedQuery: SERVICE_TYPES_BY_SEARCH_SUB,
    query,
    sort,
    pagination,
    searchText,
    skipQuery,
    alias: 'useServiceTypesBySearch',
  });

  return { loading, result };
};

export const useServiceTypesByStatusAndSearch = (status, sort) => {
  const { loading, result } = useServiceTypesBySearch({
    query: { term: { status } },
    sort,
  });

  return { loading, result };
};
