import * as R from 'ramda';
import {
  LOGIN_APP_PERMISSION,
  getAccessItemTypeValuesByKey,
} from '@poly/security';
import { ClientAppNames } from '@poly/constants';
import { propEqLegacy } from '@poly/utils';

const isLoginUserPermission = propEqLegacy('permission', LOGIN_APP_PERMISSION);

// getClientAppByAccessItem :: UserGroupAccessItem -> String
const getClientAppByAccessItem = R.compose(
  R.defaultTo(''),
  R.head,
  getAccessItemTypeValuesByKey('clientApp'),
);

const withinClientApps = R.flip(R.includes);

// userAppName :: User -> Sting
// User = Object
const userAppName = R.compose(
  R.defaultTo(''),
  R.find(withinClientApps(ClientAppNames.TIAA_PORTAL)),
  R.map(getClientAppByAccessItem),
  R.filter(isLoginUserPermission),
  R.defaultTo([]),
  R.path(['userGroups', 0, 'userGroup', 'accessItems']),
);

/**
 * clientUserToInitialFormData ::  User -> FormData
 * User, FormData = Object
 */
export const clientUserToInitialFormData = R.applySpec({
  userId: R.pathOr(null, ['_id']),
  userGroupId: R.pathOr('', ['userGroups', 0, 'userGroup', '_id']),
  userAppName,
  email: R.propOr('', 'email'),
  firstName: R.propOr('', 'firstName'),
  lastName: R.propOr('', 'lastName'),
  work: R.pathOr('', ['profile', 'workPhoneNumber']),
  cell: R.pathOr('', ['profile', 'cellPhoneNumber']),
  status: R.propOr('', 'status'),
});
