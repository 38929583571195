import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  propertyOwnershipTypes,
  propertyOccupancyTypes,
} from '@poly/constants';
import { Loader, useNotificationContext } from '@poly/admin-book';
import { initialPagination } from '@poly/client-utils';
import {
  AddPropertyForm as CommonAddPropertyForm,
  useClientsSearch,
} from '@poly/admin-ui';
import {
  getCommonRouterProps,
  useLocation,
  useParams,
} from '@poly/client-routing';

import { routes } from '../../../routes/constants.js';
import { addPropertyFormId } from '../../../utils/constants/property.js';
import { transformPropertyFormSections } from './propertyFormHelpers.js';
import { setOpenModal } from '../../../state/modules/modal.js';

const useCurrentClientId = () => {
  const { loading, result } = useClientsSearch(initialPagination);

  return {
    clientId: result?.searchClients?.hits[0]._id,
    loading,
  };
};

export function AddPropertyForm() {
  const dispatch = useDispatch();
  const { payload } = useSelector((state) => state.modal);

  const location = useLocation();
  const params = useParams();

  const router = getCommonRouterProps({ params, location });

  const notificationContext = useNotificationContext();

  const { clientId, loading } = useCurrentClientId();

  if (loading) return <Loader />;

  const onCancel = () => dispatch(setOpenModal({ name: null }));

  return (
    <CommonAddPropertyForm
      {...payload}
      {...notificationContext}
      router={router}
      clientId={clientId}
      onCancel={onCancel}
      formId={addPropertyFormId}
      propertyRootUrl={routes.PROPERTY}
      transformConfig={transformPropertyFormSections}
      additionalInitialValues={{
        ownership: propertyOwnershipTypes.owned,
        occupancy: propertyOccupancyTypes.occupied,
      }}
    />
  );
}

AddPropertyForm.displayName = 'AddPropertyForm';
