import React from 'react';
import { GlobalAddBtn } from '@poly/admin-ui';
import styled from 'styled-components';
import {
  CREATE_MASTER_SUPPLIER_PERMISSION,
  CREATE_SUPPLIER_PERMISSION,
} from '@poly/security';
import { routes } from '../../routes/constants.js';

const globalAddButtonItems = [
  {
    title: 'Add Supplier',
    route: routes.ADD_SUPPLIER,
    permissions: [CREATE_SUPPLIER_PERMISSION],
  },
  {
    title: 'Add Master Supplier',
    route: routes.ADD_MASTER_SUPPLIER,
    permissions: [CREATE_MASTER_SUPPLIER_PERMISSION],
  },
];

const Wrapper = styled.div`
  min-width: 20px;
  margin-right: 10px;
  height: 100%;
`;

export function GlobalAddButton() {
  return (
    <Wrapper>
      <GlobalAddBtn subItems={globalAddButtonItems} renderButtonByPermission />
    </Wrapper>
  );
}
