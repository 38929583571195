import React from 'react';
import { SupplierSources, supplierTypes } from '@poly/constants';
import { DefaultBodyWrapper } from '@poly/admin-book';
import { AddMasterSupplierForm, FormPageHeader } from '@poly/admin-ui';
import { routes } from '../../routes/constants.js';

const addMasterSupplierFormId = 'addMasterSupplierFormId';

export function AddMasterSupplierPage() {
  return (
    <>
      <FormPageHeader
        title="Create New Master Supplier"
        formId={addMasterSupplierFormId}
        redirectUrl={routes.SUPPLIERS}
      />
      <DefaultBodyWrapper>
        <AddMasterSupplierForm
          masterSupplierRootUrl={routes.MASTER_SUPPLIER}
          supplierRootUrl={routes.SUPPLIER}
          supplierSource={SupplierSources.TIAA}
          initialValues={{
            type: supplierTypes.ADMINISTRATIVE,
            supplierSource: SupplierSources.TIAA,
          }}
          formId={addMasterSupplierFormId}
        />
      </DefaultBodyWrapper>
    </>
  );
}
