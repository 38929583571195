import * as R from 'ramda';
import { Input } from '@poly/admin-book';
import { ifNotEmpty, validatePhone } from '@poly/client-utils';

import { halfWidth } from '../../../common.js';
import { PhoneNumberInput } from '../../../fields/index.js';

// isFieldAlarmLockBoxInfoEnabled :: String -> FormData -> Boolean
const isFieldAlarmLockBoxInfoEnabled = R.path([
  'client',
  'configs',
  'propertyFields',
  'alarmInfo',
]);

export const alarmLockBoxInformationSection = [
  {
    label: 'Alarm Information',
    id: 'alarmLockBoxInformation',
    order: 3,
    layout: {
      column: 1,
    },
    renderIf: R.path(['client', 'configs', 'propertyFields', 'alarmInfo']),
    fields: [
      {
        label: 'Alarm Code',
        order: 1,
        layout: { row: 1, width: halfWidth },
        field: {
          name: 'alarmInfo.alarmCode',
          Component: Input,
        },
        renderIf: isFieldAlarmLockBoxInfoEnabled,
      },
      {
        label: 'Alarm System',
        order: 2,
        layout: { row: 1, width: halfWidth },
        field: {
          name: 'alarmInfo.alarmSystem',
          Component: Input,
        },
        renderIf: isFieldAlarmLockBoxInfoEnabled,
      },
      {
        label: 'Account Number',
        order: 3,
        layout: { row: 2, width: halfWidth },
        field: {
          name: 'alarmInfo.accountNumber',
          Component: Input,
        },
        renderIf: isFieldAlarmLockBoxInfoEnabled,
      },
      {
        label: 'Gate Code',
        order: 4,
        layout: { row: 2, width: halfWidth },
        field: {
          name: 'alarmInfo.gateCode',
          Component: Input,
        },
        renderIf: isFieldAlarmLockBoxInfoEnabled,
      },
      {
        label: 'Password',
        order: 5,
        layout: { row: 3, width: halfWidth },
        field: {
          name: 'alarmInfo.password',
          Component: Input,
        },
        renderIf: isFieldAlarmLockBoxInfoEnabled,
      },
      {
        label: 'Alarm Company Phone',
        order: 6,
        layout: { row: 3, width: halfWidth },
        field: {
          name: 'alarmInfo.alarmCompanyPhone',
          Component: PhoneNumberInput,
        },
        renderIf: isFieldAlarmLockBoxInfoEnabled,
        validators: [[ifNotEmpty(validatePhone), 'Incorrect phone']],
      },
    ],
  },
];
