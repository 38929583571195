import * as R from 'ramda';
import { useMutation, gql } from '@apollo/client';
import { useNotificationContext } from '@poly/admin-book';
import { useDispatch } from 'react-redux';
import { setEntity } from '../../../state/modules/entity.js';
import { setOpenModal } from '../../../state/modules/modal.js';

import { prepareSupplierAddressBeforeMutate } from './utils.js';

const CREATE_SUPPLIER_MUTATION = gql`
  mutation CREATE_SUPPLIER_MUTATION($input: CreateSupplierInput!) {
    createSupplier(input: $input) {
      supplier {
        _id
      }
    }
  }
`;

export const useCreateSupplier = () => {
  const [mutate] = useMutation(CREATE_SUPPLIER_MUTATION);
  const { showSuccessNotification, showErrorNotification } =
    useNotificationContext();

  const dispatch = useDispatch();

  return {
    createSupplier: (input) =>
      mutate({
        variables: { input: prepareSupplierAddressBeforeMutate(input) },
      })
        .then(
          R.pipe(
            R.compose(
              dispatch,
              setEntity,
              R.objOf('supplierId'),
              R.path(['data', 'createSupplier', 'supplier', '_id']),
            ),
            R.tap(() =>
              showSuccessNotification('Supplier was successfully created'),
            ),
            () => dispatch(setOpenModal({ name: null })),
          ),
        )
        .catch((error) => showErrorNotification(error.message)),
  };
};
