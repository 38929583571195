import React from 'react';
import { ForgotPasswordPageBaseAdmin } from '@poly/admin-ui';

import { routes } from '../routes/constants.js';
import { APP_TITLE } from './loginPage.js';

export function ForgotPasswordPage() {
  return (
    <ForgotPasswordPageBaseAdmin title={APP_TITLE} loginRoute={routes.LOGIN} />
  );
}
