import React from 'react';
import { formatSelectOptionsByConstants } from '@poly/client-utils';
import { propertyAffiliates } from '@poly/constants';
import { Select } from '@poly/admin-book';

export function PropertyAffiliateSelect(props) {
  const selectProps = {
    ...props,
    name: 'PropertyAffiliateSelect',
    options: formatSelectOptionsByConstants(propertyAffiliates),
  };

  return <Select {...selectProps} />;
}

PropertyAffiliateSelect.displayName = 'PropertyAffiliateSelect';
