import React from 'react';
import { shape, string, arrayOf, object, oneOfType } from 'prop-types';
import { Loader, SectionsWrapper, SideSection } from '@poly/admin-book';
import { useRouterParams } from '@poly/client-routing';
import {
  ContactInfoSection,
  RemarksSection,
  useContactDetails,
} from '@poly/admin-ui';
import { getContactReferences } from '../contactUtils.js';

function ContactInfoTabComp({ references, contactUser }) {
  return (
    <SectionsWrapper>
      <SideSection>
        <ContactInfoSection {...{ ...contactUser, references }} />
      </SideSection>
      <SideSection>
        <RemarksSection remarks={contactUser.remarks} />
      </SideSection>
    </SectionsWrapper>
  );
}

ContactInfoTabComp.propTypes = {
  contactUser: shape({ _id: string.isRequired }).isRequired,
  references: oneOfType([string, arrayOf(object)]).isRequired,
};

export function ContactInfoTab() {
  const { contactId } = useRouterParams(['contactId']);

  const { data, loading } = useContactDetails(contactId);

  if (loading) return <Loader />;

  const { references } = getContactReferences({
    contactUser: data?.contactUser,
  });

  return (
    <ContactInfoTabComp
      references={references}
      contactUser={data?.contactUser}
    />
  );
}

ContactInfoTab.displayName = 'ContactInfoTab';
