import { createStore, combineReducers } from 'redux';
import {
  globalSearchTextReducer,
  modalReducer,
  notificatorReducer,
  confirmModalReducer,
} from '@poly/admin-ui';

import { searchReducer } from './modules/search.js';
import { filtersReducer } from '../modules/invoice/SpendDetailsPage/spendDetailsFilterReducer.js';
import { setEntityReducer } from './modules/entity.js';
import { processesReducer } from './modules/process.js';
import { paginationReducer } from './modules/pagination.js';
import { selectedRowsReducer } from './modules/selected-rows.js';

export const store = createStore(
  combineReducers({
    selectedRows: selectedRowsReducer,
    modal: modalReducer,
    notifications: notificatorReducer,
    filters: filtersReducer,
    searchText: searchReducer,
    pagination: paginationReducer,
    entity: setEntityReducer,
    processes: processesReducer,
    globalSearchText: globalSearchTextReducer,
    confirmModal: confirmModalReducer,
  }),
  {},
  window.__REDUX_DEVTOOLS_EXTENSION__?.(),
);
