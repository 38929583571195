import React from 'react';
import * as R from 'ramda';
import { useDispatch } from 'react-redux';
import { string, arrayOf } from 'prop-types';
import { UserEmployeeInfoStatus } from '@poly/constants';
import { Select } from '@poly/admin-book';
import {
  getAdminUsersByEmploymentStatusQuery,
  USER_SEARCH_CHANGED,
  entities,
} from '@poly/admin-ui';
import {
  useSetDefaultValueToURL,
  getAppendDefaultEntity,
  useQueryWithSearch,
} from '@poly/client-utils';

import { ALL_VALUES } from '../../../utils/constants/general.js';
import { USERS_BY_SEARCH } from '../../../apollo/queries/user.js';
import { setSelectedRows } from '../../../state/modules/selected-rows.js';
import { useDefaultUser } from './useDefaultUser.js';

// prepareStaticOptions :: [[String]] -> [Option]
const prepareStaticOptions = R.map(
  R.applySpec({ value: R.nth(0), label: R.nth(1) }),
);

// applyStaticOptions :: [[String]] -> [Option] -> [Option]
export const applyStaticOptions = (staticOptions = []) =>
  R.unless(
    () => R.isEmpty(staticOptions),
    R.concat(prepareStaticOptions(staticOptions)),
  );

// getUserOptions :: [[String]] -> SearchUsersQueryResult -> [Option]
const getUserOptions = (staticOptions) =>
  R.compose(
    applyStaticOptions(staticOptions),
    R.map(R.applySpec({ value: R.prop('_id'), label: R.prop('fullName') })),
    R.pathOr([], ['searchUsers', 'hits']),
  );

export function UserSelect(props) {
  const { value, staticOptions } = props;

  const { user } = useDefaultUser(value);

  const { result, error, onSearchChange } = useQueryWithSearch({
    ...props,
    entity: entities.USER,
    gqlSearchQuery: USERS_BY_SEARCH,
    gqlSearchChangedQuery: USER_SEARCH_CHANGED,
  });

  const preparedData = getAppendDefaultEntity({
    entityPath: ['user'],
    allEntitiesPath: ['searchUsers'],
    props: { user, searchUsers: result?.searchUsers },
  });

  const selectProps = {
    ...props,
    error,
    isClearable: true,
    name: 'UserSelect',
    onInputChange: onSearchChange,
    placeholder: 'Start typing users',
    options: getUserOptions(staticOptions)(preparedData),
  };

  return <Select {...selectProps} />;
}

UserSelect.displayName = 'UserSelect';

UserSelect.propTypes = {
  value: string,
  staticOptions: arrayOf(arrayOf(string)),
};

export function ManagerSelectWithRouter() {
  const dispatch = useDispatch();

  const { updateQueryParams, routerQuery } =
    useSetDefaultValueToURL('managerId');

  const onChange = (value) => {
    updateQueryParams({ managerId: value });
    dispatch(setSelectedRows([]));
  };

  return (
    <UserSelect
      width="200px"
      onChange={onChange}
      value={routerQuery?.managerId || ''}
      staticOptions={[[ALL_VALUES, 'No Manager']]}
      query={getAdminUsersByEmploymentStatusQuery(
        UserEmployeeInfoStatus.ACTIVE,
      )}
    />
  );
}

ManagerSelectWithRouter.displayName = 'ManagerSelectWithRouter';
