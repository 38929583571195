import * as R from 'ramda';
import { shape, string } from 'prop-types';
import React from 'react';
import { useRouterParams } from '@poly/client-routing';
import {
  MasterSupplierBranchesTab as MasterSupplierBranchesTabImp,
  supplierAddressColumn,
} from '@poly/admin-ui';
import { keywordSortQuery } from '@poly/client-utils';

import { SupplierLink } from '../../containers/links/Links.js';
import { routes } from '../../routes/constants.js';

function SupplierLinkC({ _id, company }) {
  const name = R.prop('name', company);
  return <SupplierLink _id={_id} name={name} />;
}

SupplierLinkC.propTypes = {
  _id: string.isRequired,
  company: shape({ name: string.isRequired }),
};

const tableConfig = [
  ['Name', SupplierLinkC, keywordSortQuery(['company', 'name'])],
  supplierAddressColumn,
  ['Service Types', R.prop('serviceTypesStr')],
];

export function MasterSupplierBranchesTab() {
  const { supplierId } = useRouterParams(['supplierId']);

  return (
    <MasterSupplierBranchesTabImp
      tableConfig={tableConfig}
      addBranchRoute={routes.ADD_BRANCH_SUPPLIER}
      supplierId={supplierId}
    />
  );
}
