import * as R from 'ramda';
import { ofArrayLegacy, propEqLegacy } from '@poly/utils';
import { OCRInvoiceStatuses } from '@poly/constants';

import { ALL_VALUES } from '../../../utils/constants/general.js';
import { deserializeDateRange } from '../../../utils/general.js';

export const filtersNames = {
  SPEND_DETAILS_DATE_RANGE: 'spendDetailsDateRange',
  SELECTED_PROPERTY: 'property',
};

const getPropertyParams = R.ifElse(
  R.both(
    R.prop(filtersNames.SELECTED_PROPERTY),
    R.complement(propEqLegacy(filtersNames.SELECTED_PROPERTY, ALL_VALUES)),
  ),
  R.pipe(
    R.prop(filtersNames.SELECTED_PROPERTY),
    R.assocPath(['match', 'propertyId'], R.__, {}),
  ),
  R.always(null),
);

const getCreatedAtParams = R.pipe(
  R.prop(filtersNames.SPEND_DETAILS_DATE_RANGE),
  deserializeDateRange,
  R.applySpec({ gte: R.prop('startDate'), lte: R.prop('endDate') }),
  R.assocPath(['range', 'invoiceDate'], R.__, {}),
);

const getStatusParams = R.compose(
  R.assocPath(['bool', 'must'], R.__, {}),
  ofArrayLegacy,
  R.applySpec({
    match: {
      'workflow.status': R.always(OCRInvoiceStatuses.PAID),
    },
  }),
);

// argsToArray :: (Arg, Arg ... Arg) -> [Arg]
// Arg = Any
const argsToArray = (...args) => args;

export const getSpendDetailQuery = R.pipe(
  R.converge(R.compose(R.filter(R.identity), argsToArray), [
    getPropertyParams,
    getCreatedAtParams,
    getStatusParams,
  ]),
  R.assocPath(['bool', 'must'], R.__, {}),
);

// calculateSubtotalLine :: Line -> Number
export const calculateSubtotalLine = R.compose(
  R.apply(R.multiply),
  R.props(['quantity', 'price']),
);
