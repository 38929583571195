import * as R from 'ramda';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { startOfDay, endOfDay, subMonths } from 'date-fns';
import { alwaysNewDate, ensureIsDate } from '@poly/utils';
import { setFilters } from '../spendDetailsFilterReducer.js';
import { SpendDetailsToolbarC } from './SpendDetailsToolbarC.js';
import { serializeDateRange } from '../../../../utils/general.js';
import { filtersNames } from '../spendDetailsUtils.js';

const { SELECTED_PROPERTY, SPEND_DETAILS_DATE_RANGE } = filtersNames;

const defaultFilters = (currentDate) => ({
  [SELECTED_PROPERTY]: '',
  [SPEND_DETAILS_DATE_RANGE]: {
    startDate: subMonths(ensureIsDate(currentDate), 1),
    endDate: currentDate,
  },
});

const prepareFiltersForCache = R.evolve({
  [SPEND_DETAILS_DATE_RANGE]: R.pipe(
    serializeDateRange,
    R.assoc('__typename', 'Date'),
  ),
});

export function SpendDetailsToolbar() {
  const dispatch = useDispatch();

  const [selectedFilters, setSelectedFilters] = useState(
    prepareFiltersForCache(defaultFilters(alwaysNewDate())),
  );

  useEffect(
    () => () => {
      dispatch(
        setFilters(prepareFiltersForCache(defaultFilters(alwaysNewDate()))),
      );
    },
    [],
  );

  const onPropertySelect = (selectedProperty) => {
    setSelectedFilters((prev) => ({
      ...prev,
      [SELECTED_PROPERTY]: selectedProperty,
    }));
  };

  const onDateRangeChange = (dateRange) => {
    setSelectedFilters((prev) => ({
      ...prev,
      [SPEND_DETAILS_DATE_RANGE]: dateRange,
      startDate: startOfDay(ensureIsDate(dateRange?.startDate)),
      endDate: endOfDay(ensureIsDate(dateRange?.endDate)),
    }));
  };

  const onApplyFilter = () => {
    dispatch(
      setFilters(
        prepareFiltersForCache({
          [SELECTED_PROPERTY]: selectedFilters.property,
          [SPEND_DETAILS_DATE_RANGE]: selectedFilters.spendDetailsDateRange,
        }),
      ),
    );
  };

  return (
    <SpendDetailsToolbarC
      {...selectedFilters}
      onPropertySelect={onPropertySelect}
      onDateRangeChange={onDateRangeChange}
      onApplyFilter={onApplyFilter}
    />
  );
}
