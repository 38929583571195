import React from 'react';
import { useDispatch } from 'react-redux';
import { func } from 'prop-types';
import styled from 'styled-components';
import { Button, ModalActions } from '@poly/admin-book';
import { useUpdateQueryParams } from '@poly/client-routing';
import { setOpenModal } from '../../state/modules/modal.js';

const Container = styled.div`
  width: 400px;
`;

const CancelBtn = styled(Button)`
  margin-right: 20px;
`;

function ConfirmInvoiceSaveModalC({ onConfirm, onCancel }) {
  return (
    <Container>
      <p>Are you sure you want to cancel edit?</p>
      <ModalActions>
        <CancelBtn onClick={onCancel} size="small">
          No
        </CancelBtn>
        <Button size="small" styleType="basicSecondary" onClick={onConfirm}>
          Yes
        </Button>
      </ModalActions>
    </Container>
  );
}

ConfirmInvoiceSaveModalC.propTypes = {
  onConfirm: func.isRequired,
  onCancel: func.isRequired,
};

export function ConfirmCancelFormEdit() {
  const dispatch = useDispatch();

  const updateQueryParams = useUpdateQueryParams();

  const onConfirm = () => {
    dispatch(setOpenModal({ name: null }));
    updateQueryParams({ isEdit: false });
  };

  const onCancel = () => dispatch(setOpenModal({ name: null }));

  return <ConfirmInvoiceSaveModalC onConfirm={onConfirm} onCancel={onCancel} />;
}
