import * as R from 'ramda';
import { applySpecWithFields, assocByPath, removePropDeep } from '@poly/utils';

import { formatTotal } from '../../../../utils/foramatters/index.js';
import { invoiceDetailsFromInvoice } from '../common/utils.js';
import { unsetSupplierOuterContractId } from '../../InvoiceVerifyPage/InvoiceVerifyForm/utils.js';

const toDateIfNotNull = (key) =>
  R.compose(R.when(R.identity, R.constructN(1, Date)), R.prop(key));

// invoiceToFormData :: OCRInvoice -> OCRInvoiceData
export const invoiceToFormData = R.applySpec({
  lines: R.compose(
    R.map(
      R.compose(
        R.omit(['serviceType']),
        applySpecWithFields({
          serviceTypeId: R.path(['serviceType', '_id']),
        }),
      ),
    ),
    removePropDeep('__typename'),
    R.defaultTo([]),
    R.prop('lines'),
  ),
  invoiceNumber: R.prop('invoiceNumber'),
  invoiceDate: toDateIfNotNull('invoiceDate'),
  dueDate: toDateIfNotNull('dueDate'),
  supplierId: R.path(['supplier', '_id']),
  propertyId: R.path(['property', '_id']),
  managerId: R.path(['manager', '_id']),
  receiveDate: toDateIfNotNull('receiveDate'),
  supplierOuterContractId: R.prop('supplierOuterContractId'),
  isNotRecurringInvoice: R.prop('isNotRecurringInvoice'),
  total: R.compose(formatTotal, R.prop('total')),
  status: R.path(['workflow', 'status']),
  invoiceDetails: invoiceDetailsFromInvoice,
});

// invoiceDataToUpdateMutation :: OCRInvoiceData -> UpdateOCRInvoiceInput
export const invoiceDataToUpdateMutation = R.compose(
  unsetSupplierOuterContractId,
  R.omit(['invoiceDetails', 'status', 'total', 'checkNumber']),
  assocByPath(['workflow', 'status'], R.prop('status')),
);
