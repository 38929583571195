import React from 'react';
import { Loader } from '@poly/admin-book';
import {
  Container,
  FileViewerS,
  FormHeader,
  FormSection,
  FormWrapper,
} from './styles.js';
import { InvoiceVerifyForm } from './InvoiceVerifyForm/InvoiceVerifyForm.js';
import { useInvoiceVerifyLogic } from './useInvoiceVerifyLogic.js';

export function InvoiceVerifyPage() {
  const { loading, url, fileType, invoice, isSynchronized } =
    useInvoiceVerifyLogic();

  if (loading) return <Loader />;
  if (!isSynchronized) return null;

  return (
    <Container>
      <FileViewerS {...{ url, fileType }} />
      <FormSection>
        <FormHeader>Verify Invoice</FormHeader>
        <FormWrapper>
          <InvoiceVerifyForm {...{ invoice }} />
        </FormWrapper>
      </FormSection>
    </Container>
  );
}
