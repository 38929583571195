import * as R from 'ramda';
import { applyOr } from '@poly/utils';
import { formatDateOr } from '@poly/client-utils';

import { formatTotal } from '../../../../utils/foramatters/index.js';

const NOTHING_UI = '—';
export const prepareDataForDisplay = R.applySpec({
  supplierCompanyName: R.pathOr(NOTHING_UI, ['supplier', 'company', 'name']),
  supplierId: R.path(['supplier', '_id']),
  total: R.compose(applyOr(formatTotal)(NOTHING_UI), R.prop('total')),
  invoiceDate: R.compose(formatDateOr(NOTHING_UI), R.prop('invoiceDate')),
  propertyName: R.pathOr(NOTHING_UI, ['property', 'name']),
  propertyAddress: R.pathOr(NOTHING_UI, [
    'property',
    'address',
    'formatted_address',
  ]),
  propertyId: R.path(['property', '_id']),
  invoiceNumber: R.prop('invoiceNumber'),
  receiveDate: R.compose(formatDateOr(NOTHING_UI), R.prop('receiveDate')),
  check: R.pathOr(NOTHING_UI, ['workflow', 'check', 'checkNumber']),
  dueDate: R.compose(formatDateOr(NOTHING_UI), R.prop('dueDate')),
  paidDate: R.compose(
    formatDateOr(NOTHING_UI),
    R.path(['workflow', 'check', 'issuanceDate']),
  ),
  invoiceFileLink: R.prop('invoiceFileLink'),
  batch: R.prop('batch'),
  managerFullName: R.path(['manager', 'fullName']),
  supplierOuterContractId: R.prop('supplierOuterContractId'),
  isRecurringInvoice: R.compose(
    R.ifElse(R.equals(true), R.always('No'), R.always('Yes')),
    R.prop('isNotRecurringInvoice'),
  ),
});
