import React from 'react';
import { number } from 'prop-types';
import { TableExportXLSBtn } from '@poly/admin-ui';
import { PRINT_TYPES } from '@poly/constants';

import { TablePrintBtn } from '../../../containers/buttons/TablePrintPDFBtn.js';
import { useSupplierTaxReportQuery } from './useSupplierTaxReportQuery.js';
import { generateXLSReport } from './xlsExport.js';

export function SupplierTaxReportPrintPDFBtn({ year }) {
  const { report } = useSupplierTaxReportQuery(year);

  const printProps = {
    printType: PRINT_TYPES.REPORT_SUPPLIER_STATUS,
    metaData: { title: 'Supplier Tax Report' },
    showWarningModal: false,
    data: report,
  };

  return <TablePrintBtn {...printProps} />;
}

SupplierTaxReportPrintPDFBtn.displayName = 'SupplierTaxReportPrintPDFBtn';
SupplierTaxReportPrintPDFBtn.propTypes = { year: number.isRequired };

export function SuppliersTaxReportExportXLSBtn({ year }) {
  const { report } = useSupplierTaxReportQuery(year);

  return <TableExportXLSBtn data={report} xlsGenerator={generateXLSReport} />;
}

SuppliersTaxReportExportXLSBtn.displayName = 'SuppliersTaxReportExportXLSBtn';
SuppliersTaxReportExportXLSBtn.propTypes = { year: number.isRequired };
