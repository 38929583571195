import React from 'react';
import styled from 'styled-components';
import {
  TableWithPaginationContainer,
  pipColumnStyles,
  TableCard,
  Table,
} from '@poly/admin-book';

const TableS = styled(Table)`
  ${pipColumnStyles(1)};
`;

export function PropertySuppliersTableComp(props) {
  return (
    <TableCard>
      <TableWithPaginationContainer>
        <TableS {...props} />
      </TableWithPaginationContainer>
    </TableCard>
  );
}
