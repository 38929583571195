import React from 'react';
import { useApolloClient } from '@apollo/client';
import { EditTaskForm as EditTaskFormImp } from '@poly/admin-ui';

export const editTaskFormId = 'editTaskFormId';

export function EditTaskForm(props) {
  const apolloClient = useApolloClient();

  const formProps = {
    ...props,
    apolloClient,
    title: 'Edit Task',
    disableMentions: true,
    formId: editTaskFormId,
    isTaskUpdateMessageOptional: true,
  };

  return <EditTaskFormImp {...formProps} />;
}
