import { gql, useMutation } from '@apollo/client';
import { OCRInvoiceStatuses } from '@poly/constants';
import { insertParamsIntoURL } from '@poly/utils';
import { useNavigate } from '@poly/client-routing';
import { useNotificationContext } from '@poly/admin-book/src/index.js';
import { routes } from '../../../routes/constants.js';

export const UPDATE_INVOICE_MUTATION = gql`
  mutation UPDATE_INVOICE_MUTATION(
    $invoiceId: ID!
    $update: UpdateOCRInvoiceInput!
  ) {
    updateOCRInvoice(invoiceId: $invoiceId, update: $update) {
      _id
    }
  }
`;

const useUpdateVerifyInvoice = () => {
  const [mutate] = useMutation(UPDATE_INVOICE_MUTATION, {
    alias: 'useVerifyInvoice',
  });

  return {
    updateOCRInvoice: ({ id, update }) =>
      mutate({
        variables: {
          invoiceId: id,
          update,
        },
      }),
  };
};

export const useVerifyInvoice = () => {
  const navigate = useNavigate();

  const { updateOCRInvoice } = useUpdateVerifyInvoice();

  const { showSuccessNotification } = useNotificationContext();

  return {
    verifyInvoice: async ({ id, update }) => {
      await updateOCRInvoice({
        id,
        update: {
          ...update,
          workflow: {
            status: OCRInvoiceStatuses.VERIFIED,
          },
        },
      });
      navigate(insertParamsIntoURL({ invoiceId: id }, routes.INVOICE));
      showSuccessNotification('The invoice was successfully verified');
    },
  };
};
