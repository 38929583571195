import * as R from 'ramda';
import React, { useContext } from 'react';
import { shape, number, string, func } from 'prop-types';
import { SupplierSelect, useOnSubmitSetStopSubmitting } from '@poly/admin-ui';
import { formatSelectOptionsByConstants } from '@poly/client-utils';
import { usePristineSubscribe } from '@poly/client-routing';
import {
  RecurringOCRInvoiceReceiptMethod,
  MAXIMUM_MONTH_DAY_NUMBER,
} from '@poly/constants';
import {
  createGetFormFieldWidth,
  ModalContext,
  FormCreator,
  Select,
  Input,
} from '@poly/admin-book';

import { PropertySelect } from '../property/PropertySelect/PropertySelect.js';

export const createEditRecurringInvoiceFormId =
  'createEditRecurringInvoiceForm';

// define 4 columns with 5px between
const getFormColumnWidth = createGetFormFieldWidth(2, 5);

const columnWidth1 = getFormColumnWidth(1);

// receiptMethodOptions :: RecurringOCRInvoiceReceiptMethods -> [Option]
const receiptMethodOptions = R.compose(
  formatSelectOptionsByConstants,
  R.invertObj,
)(RecurringOCRInvoiceReceiptMethod);

function ReceiptMethodSelect(props) {
  const selectProps = {
    ...props,
    name: 'ReceiptMethodSelect',
    options: receiptMethodOptions,
  };

  return <Select {...selectProps} />;
}

function RecurringOCRInvoicePropertySelect(props) {
  const modalContextProps = useContext(ModalContext);

  const selectProps = {
    ...props,
    ...modalContextProps,
    includeAllOption: false,
  };

  return <PropertySelect {...selectProps} />;
}

const recurringOCRInvoiceFormSections = [
  {
    id: 'main',
    layout: { column: 1 },
    order: 1,
    fields: [
      {
        label: 'Supplier',
        order: 1,
        layout: { row: 1 },
        field: {
          name: 'supplierId',
          Component: SupplierSelect,
        },
        required: true,
      },
      {
        label: 'Property',
        order: 2,
        layout: { row: 2, width: columnWidth1 },
        field: {
          name: 'propertyId',
          Component: RecurringOCRInvoicePropertySelect,
        },
        required: true,
      },
      {
        label: 'Receipt Method',
        order: 3,
        layout: { row: 2, width: columnWidth1 },
        required: true,
        field: {
          name: 'receiptMethod',
          Component: ReceiptMethodSelect,
        },
      },
      {
        label: 'Account Number',
        order: 4,
        layout: { row: 3, width: columnWidth1 },
        field: {
          name: 'supplierOuterContractId',
          Component: (props) => (
            <Input {...props} placeholder="Account Number" />
          ),
        },
      },
      {
        label: 'Expected Day Of Month',
        order: 4,
        layout: { row: 3, width: columnWidth1 },
        field: {
          name: 'endDayOfTheMonth',
          Component: (props) => (
            <Input
              {...props}
              type="number"
              min={1}
              max={MAXIMUM_MONTH_DAY_NUMBER}
            />
          ),
        },
        required: true,
        validators: [
          [
            (value) => value > 0 && value <= MAXIMUM_MONTH_DAY_NUMBER,
            `Should be number from 1 to ${MAXIMUM_MONTH_DAY_NUMBER}`,
          ],
        ],
      },
    ],
  },
];

// convertDayToNumber :: Object -> Object
const convertDayToNumber = R.over(R.lensProp('endDayOfTheMonth'), parseInt);

export function RecurringOCRInvoiceForm({
  invoice,
  onSubmit: onSubmitOriginal,
}) {
  const { onSubmit } = useOnSubmitSetStopSubmitting(
    createEditRecurringInvoiceFormId,
    onSubmitOriginal,
  );
  const pristineSubscribeProps = usePristineSubscribe();

  const formProps = {
    onSubmit: R.compose(onSubmit, convertDayToNumber),
    initialValues: invoice,
    sections: recurringOCRInvoiceFormSections,
    layout: {
      overflow: 'visible',
      card: false,
    },
    id: createEditRecurringInvoiceFormId,
    ...pristineSubscribeProps,
  };

  return <FormCreator {...formProps} />;
}

RecurringOCRInvoiceForm.propTypes = {
  invoice: shape({
    _id: string,
    endDayOfTheMonth: number,
    supplierOuterContractId: string,
    receiptMethod: string,
    propertyId: string,
    supplierId: string,
  }),
  onSubmit: func.isRequired,
};
