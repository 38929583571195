import * as R from 'ramda';
import { formatDate, unwindByProp } from '@poly/utils';
import {
  ExcelExportCellType,
  createExcelExportCell,
  performExcelExport,
} from '@poly/client-utils';

import { getGlCodeByCategoryId } from '../../../../utils/constants/serviceTypes.js';
import { calculateSubtotalLine } from '../spendDetailsUtils.js';

const COLUMN_WIDTH = [15, 30, 15, 40, 40, 40, 15, 15];
const TITLE_ROW = [
  'Invoice ID',
  'Invoice #',
  'Invoice Date',
  'Property',
  'Supplier',
  'Service Type',
  'GL Code',
  'Total',
];

// getSpendDetailReportRows :: [Invoice] -> [ExcelExportDataCell]
const getSpendDetailReportRows = R.compose(
  R.map(
    R.compose(
      R.zipWith(createExcelExportCell, [
        ...R.repeat(ExcelExportCellType.default, 7),
        ...R.repeat(ExcelExportCellType.defaultCurrency, 1),
      ]),
      R.converge(Array.of, [
        R.prop('invoiceId'),
        R.applySpec({
          text: R.prop('invoiceNumber'),
          hyperlink: R.prop('invoiceFileLink'),
        }),
        R.compose(formatDate, R.prop('invoiceDate')),
        R.path(['property', 'name']),
        R.path(['supplier', 'company', 'name']),
        R.path(['lines', 'serviceType', 'name']),
        R.compose(
          getGlCodeByCategoryId,
          R.path(['lines', 'serviceType', 'psCategoryId']),
        ),
        R.compose(calculateSubtotalLine, R.prop('lines')),
      ]),
    ),
  ),
  unwindByProp('lines'),
);

// eslint-disable-next-line import/no-unused-modules
export const getSpendDetailReportExcelConfig = (invoices) => ({
  exportFileName: 'tiaa_spend_report.xlsx',
  columnWidths: COLUMN_WIDTH,
  rows: [
    R.zipWith(
      createExcelExportCell,
      [
        ...R.repeat(ExcelExportCellType.tableHeader, 7),
        ...R.repeat(ExcelExportCellType.tableCurrencyHeader, 1),
      ],
      TITLE_ROW,
    ),
    ...getSpendDetailReportRows(invoices),
  ],
});

// spendDetailReportXLS :: [Invoice] -> Promise Error Boolean
export const spendDetailReportXLS = R.compose(
  performExcelExport,
  getSpendDetailReportExcelConfig,
);
