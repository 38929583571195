import React from 'react';
import * as R from 'ramda';
import { shape, string } from 'prop-types';
import {
  defaultTheme,
  EntityAddress,
  SectionWithCard,
  Status,
} from '@poly/admin-book';
import {
  EmailLink,
  MasterSupplierLink,
  supplierAccountPropsTypes,
  WebsiteLink,
} from '@poly/admin-ui';
import { supplierEmailsTypes, supplierTypes } from '@poly/constants';
import { formatPhoneNumber, forceTitleCase } from '@poly/utils';
import { SupplierServices } from './SupplierServices.js';
import { BooleanTextWithDot } from '../utils.js';
import { routes } from '../../../../routes/constants.js';

const {
  colors: {
    statuses: { active },
  },
} = defaultTheme;

function MBEElement({ minority }) {
  return minority ? (
    <Status color={active} text={`Yes - ${forceTitleCase(minority)}`} />
  ) : (
    <BooleanTextWithDot isTrue={false} />
  );
}

MBEElement.propTypes = {
  minority: string,
};

const getEmailFields = (type, emails) =>
  type === supplierTypes.ADMINISTRATIVE
    ? [
        {
          title: 'Email',
          elem: <EmailLink email={emails[supplierEmailsTypes.GENERAL]} />,
        },
      ]
    : [
        {
          title: 'Service email',
          elem: <EmailLink email={emails[supplierEmailsTypes.SERVICE]} />,
        },
        {
          title: 'Accounting Email',
          elem: <EmailLink email={emails[supplierEmailsTypes.ACCOUNT]} />,
        },
      ];

const getWebsiteField = (type, checkType, website) =>
  type === checkType
    ? [
        {
          title: 'Website',
          elem: <WebsiteLink website={website} />,
        },
      ]
    : [];

const getMasterSupplierField = (masterSupplier) =>
  masterSupplier
    ? [
        {
          title: 'Master Supplier',
          elem: (
            <MasterSupplierLink
              {...masterSupplier}
              masterSupplierRootUrl={routes.MASTER_SUPPLIER}
            />
          ),
        },
      ]
    : [];

export function GeneralInfoSection({
  name,
  type,
  address,
  addressTwo,
  phone,
  fax,
  emails,
  website,
  service_24_7,
  services = [],
  mwbe,
  dba,
  masterSupplier,
}) {
  return (
    <SectionWithCard
      title="General Information"
      list={[
        {
          title: 'Supplier Name',
          elem: name,
          fullWidth: R.equals(type, supplierTypes.SUBCONTRACTOR),
        },
        ...(dba
          ? [
              {
                title: 'DBA',
                elem: dba,
              },
            ]
          : []),
        ...getMasterSupplierField(masterSupplier),
        {
          title: 'Address',
          elem: <EntityAddress {...{ address, addressTwo }} />,
        },
        {
          title: '24/7 Service',
          elem: <BooleanTextWithDot isTrue={service_24_7} />,
        },
        { title: 'Phone', elem: formatPhoneNumber('-')({ number: phone }) },
        { title: 'Fax', elem: formatPhoneNumber('-')({ number: fax }) },
        ...getEmailFields(type, emails),
        ...getWebsiteField(type, supplierTypes.ADMINISTRATIVE, website),
        {
          title: 'Service Types',
          elem: <SupplierServices {...{ services }} />,
        },
        ...getWebsiteField(type, supplierTypes.SUBCONTRACTOR, website),
        {
          title: 'MBE',
          elem: <MBEElement minority={mwbe.minority} />,
        },
        {
          title: 'WBE',
          elem: <BooleanTextWithDot isTrue={mwbe.isWBE} />,
        },
      ]}
    />
  );
}

GeneralInfoSection.propTypes = {
  ...supplierAccountPropsTypes.generalInfoPropTypes,
  type: string.isRequired,
  masterSupplier: shape({ _id: string.isRequired }),
};
