import React from 'react';
import * as R from 'ramda';
import { node } from 'prop-types';
import { ApolloProvider } from '@apollo/client';
import { Provider as ReduxProvider } from 'react-redux';
import {
  useUserLogout,
  handleApplicationMaintenance,
} from '@poly/client-utils';
import { createApolloErrorHandlerForAdminUI } from '@poly/admin-ui';
import { createApolloClient } from '@poly/apollo-client';
import {
  ThemeProvider as BookAdminThemeProvider,
  defaultTheme,
} from '@poly/admin-book';

import { store } from './state/store.js';
import { authRoutesList, routes } from './routes/constants.js';

export const theme = defaultTheme;

export function ThemeProvider({ children }) {
  return (
    <BookAdminThemeProvider theme={theme}>{children}</BookAdminThemeProvider>
  );
}

ThemeProvider.propTypes = {
  children: node,
};

export function StoreProvider({ children }) {
  return <ReduxProvider store={store}>{children}</ReduxProvider>;
}

StoreProvider.propTypes = {
  children: node,
};

// shouldLogout :: String -> Boolean
const shouldLogout = R.compose(
  R.complement(R.includes(R.__, authRoutesList)),
  R.when(R.includes('set-password'), R.always(routes.RESET_PASSWD)),
);

export function ApolloClientProvider({ children }) {
  const logOutUser = useUserLogout();

  const logOutHandler = () =>
    logOutUser({ localStorage, loginRoute: routes.LOGIN });

  const apolloErrorHandler = createApolloErrorHandlerForAdminUI({
    store,
    logOutHandler,
  });

  const onTokenHasExpired = () => {
    if (shouldLogout(window.location.pathname)) {
      logOutHandler();
    }
  };

  const apolloClient = createApolloClient(
    onTokenHasExpired,
    apolloErrorHandler,
    handleApplicationMaintenance,
  );

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
}

ApolloClientProvider.propTypes = {
  children: node,
};
