import React from 'react';
import { gql } from '@apollo/client';
import { UpdateCollections } from '@poly/constants';
import { commonUpdateFields, entities } from '@poly/admin-ui';
import { Loader } from '@poly/admin-book';
import { useLocation } from '@poly/client-routing';
import { checkIfIsItInvoiceRoute } from '../../../containers/appBar/AppBar.js';
import { UpdatesTab } from '../../../containers/tabs/UpdatesTab.js';
import { useInvoiceAccount } from '../apollo/useInvoiceAccount.js';

export const INVOICE_UPDATES = gql`
  query INVOICE_UPDATES(
    $id: ID!
    $documentUpdatesInput: DocumentUpdatesInput!
  ) {
    ocrInvoice(id: $id) {
      _id
      documentUpdates(input: $documentUpdatesInput) {
        hits {
          ...commonUpdateFields
        }
        total
      }
    }
  }

  ${commonUpdateFields}
`;

export function InvoiceUpdatesTab() {
  const location = useLocation();

  const isInvoiceRoute = checkIfIsItInvoiceRoute(location);

  const { data, loading } = useInvoiceAccount(isInvoiceRoute);

  if (loading) return <Loader />;

  return (
    <UpdatesTab
      collection={UpdateCollections.ocrInvoices}
      updatesQuery={INVOICE_UPDATES}
      entity={{
        _id: data?.ocrInvoice?._id,
        name: entities.OCR_INVOICE,
        humanReadableId: data?.ocrInvoice?.invoiceId,
      }}
    />
  );
}
