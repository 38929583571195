import React from 'react';
import * as R from 'ramda';
import { ASC_SORT_ORDER } from '@poly/constants';
import { Loader } from '@poly/admin-book';
import {
  commonSortQuery,
  keywordSortQuery,
  useSortableTable,
} from '@poly/client-utils';
import {
  useMapConfigToTableProps,
  useSetInitialPaginationOnMount,
  useSetItemsCount,
} from '@poly/admin-ui';
import { string } from 'prop-types';
import { ServiceTypesTableC } from './ServiceTypesTableC.js';
import { getGLCodeByCategory } from '../../../utils/service-types.js';
import { getPropOrNothingUI } from '../../../utils/general.js';
import { useServiceTypesByStatusAndSearch } from '../apollo/withServiceTypesBySearch.js';
import { EditServiceTypeBtn } from '../../../containers/buttons/EditServiceTypeBtn.js';

const config = [
  ['Service Type', R.prop('name'), keywordSortQuery(['name'])],
  [
    'PeopleSoft Category Description',
    getPropOrNothingUI('tiaaCategoryName'),
    commonSortQuery(['tiaaCategoryName']),
  ],
  [
    'Category Code',
    getPropOrNothingUI('psCategoryId'),
    commonSortQuery(['psCategoryId']),
  ],
  ['GL Code', getGLCodeByCategory('psCategoryId')],
  ['', (serviceType) => <EditServiceTypeBtn {...{ serviceType }} />],
];

// getServiceTypesTotal :: SearchServiceTypesQueryResult -> Number
const getServiceTypesTotal = R.pathOr(0, ['searchServiceTypes', 'total']);

// getServiceTypes :: SearchServiceTypesQueryResult -> [ServiceType]
const getServiceTypes = R.pathOr([], ['searchServiceTypes', 'hits']);

export function ServiceTypesTable({ status }) {
  useSetInitialPaginationOnMount();

  const { sort, ...tableSortingProps } = useSortableTable({
    tableConfig: config,
    column: 1,
    order: ASC_SORT_ORDER,
  });

  const { loading, result } = useServiceTypesByStatusAndSearch(status, sort);

  useSetItemsCount(getServiceTypesTotal, result);

  const tableProps = useMapConfigToTableProps(getServiceTypes, config, result);

  if (loading) return <Loader />;

  return <ServiceTypesTableC {...tableSortingProps} {...tableProps} />;
}

ServiceTypesTable.displayName = 'ServiceTypesTable';

ServiceTypesTable.propTypes = {
  status: string,
};
