import styled from 'styled-components';
import { Table, moneyColumnStyles } from '@poly/admin-book';

export const Report1099TableC = styled(Table)`
  th:nth-child(2),
  td:nth-child(2) {
    width: 10%;
  }

  th:nth-child(3),
  td:nth-child(3),
  th:nth-child(7),
  td:nth-child(7),
  th:nth-child(8),
  td:nth-child(8) {
    width: 60px;
  }

  th:nth-child(4),
  td:nth-child(4),
  th:nth-child(5),
  td:nth-child(5),
  th:nth-child(6),
  td:nth-child(6) {
    width: 15%;
    overflow-wrap: break-word;
  }

  ${moneyColumnStyles(9)};
`;
