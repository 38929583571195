import React from 'react';
import { shape, string } from 'prop-types';
import { TextButton } from '@poly/admin-book';
import { usePickupInvoice } from '../../utils/hooks/xtracta.js';

export function PickupInvoiceBtn({ invoice, ...restProps }) {
  const { pickUpInvoice } = usePickupInvoice();

  return (
    <TextButton {...restProps} onClick={() => pickUpInvoice(invoice)}>
      Pickup
    </TextButton>
  );
}

PickupInvoiceBtn.propTypes = {
  invoice: shape({
    _id: string.isRequired,
    workflow: shape({
      status: string.isRequired,
    }).isRequired,
  }).isRequired,
};
