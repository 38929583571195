import React from 'react';
import { useDispatch } from 'react-redux';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';
import { FormField, IconButton, Input, LinkButton } from '@poly/admin-book';
import { strToPositiveNumber, formatDate } from '@poly/utils';
import styled from 'styled-components';
import { SupplierSelect, ServiceTypesSelect } from '@poly/admin-ui';
import { setOpenModal } from '../../../../state/modules/modal.js';

import {
  FlexColumn,
  FlexContainer,
  FlexContainerS,
  FooterContainer,
  InvoiceInfoItemWrapper,
  LabelS,
  LineContainer,
  LinkButtonS,
  SpaceBetween,
  TotalContainer,
  ValueText,
  VerifyButton,
} from '../../InvoicePage/common/styles.js';
import { InvoicePDFLink } from '../../../../containers/links/Links.js';
import { ModalNames } from '../../../../utils/constants/modals.js';
import { BlockInvoiceBtn, CancelVerifyingBtn } from './buttons.js';
import { getLineSubtotalByIndex } from '../../InvoicePage/common/utils.js';

export function InvoiceInfoComp({
  invoiceId,
  invoiceNumber,
  invoiceDate,
  invoiceFileLink,
}) {
  return (
    <FlexContainer>
      <InvoiceInfoItemWrapper>
        <LabelS>Invoice ID</LabelS>
        <ValueText>{invoiceId}</ValueText>
      </InvoiceInfoItemWrapper>
      <InvoiceInfoItemWrapper direction="column">
        <LabelS>Invoice Number</LabelS>
        <InvoicePDFLink {...{ invoiceNumber, invoiceFileLink }} />
      </InvoiceInfoItemWrapper>
      <InvoiceInfoItemWrapper direction="column">
        <LabelS>Invoice Date</LabelS>
        <ValueText>{formatDate(invoiceDate)}</ValueText>
      </InvoiceInfoItemWrapper>
    </FlexContainer>
  );
}

InvoiceInfoComp.propTypes = {
  invoiceId: string.isRequired,
  invoiceNumber: string.isRequired,
  invoiceDate: string.isRequired,
  invoiceFileLink: string.isRequired,
};

function EntityAddressItemComp({ label, value }) {
  return (
    <FlexContainerS width={100}>
      <LabelS width={15}>{label}</LabelS>
      <ValueText>{value}</ValueText>
    </FlexContainerS>
  );
}

EntityAddressItemComp.propTypes = {
  label: string.isRequired,
  value: string,
};

function AddSupplierLink() {
  const dispatch = useDispatch();

  const onClick = () =>
    dispatch(setOpenModal({ name: ModalNames.CREATE_SUPPLIER }));

  return (
    <LinkButtonS type="button" onClick={onClick}>
      Add Supplier
    </LinkButtonS>
  );
}

export function SupplierSelectWithBtnComp(props) {
  return (
    <FlexColumn>
      <SupplierSelect {...props} />
      <AddSupplierLink />
    </FlexColumn>
  );
}

export function LinesHeader() {
  return (
    <SpaceBetween>
      <LabelS width={2}>#</LabelS>
      <LabelS width={35}>Description</LabelS>
      <LabelS width={25}>Service Type</LabelS>
      <LabelS width={10}>Price</LabelS>
      <LabelS width={10}>Quantity</LabelS>
      <LabelS width={5}>Subtotal</LabelS>
      <LabelS width={2} />
    </SpaceBetween>
  );
}

const InputS = styled(Input)`
  width: 100%;
`;

export function InvoiceLineField({
  name,
  index,
  fields: { value: values, remove },
}) {
  return (
    <LineContainer>
      <FlexContainerS width={2}>{index + 1}</FlexContainerS>
      <FlexContainerS width={35}>
        <FormField
          name={`${name}.description`}
          Component={InputS}
          showErrorMsg={false}
          additionalProps={{
            placeholder: 'Enter description',
          }}
        />
      </FlexContainerS>
      <FlexContainerS width={25}>
        <FormField
          name={`${name}.serviceTypeId`}
          Component={ServiceTypesSelect}
          additionalProps={{
            required: true,
            width: '100%',
          }}
        />
      </FlexContainerS>
      <FlexContainerS width={10}>
        <FormField
          parse={strToPositiveNumber}
          name={`${name}.price`}
          Component={InputS}
          showErrorMsg={false}
          additionalProps={{
            type: 'number',
            step: 0.01,
            min: 0,
          }}
        />
      </FlexContainerS>
      <FlexContainerS width={10}>
        <FormField
          parse={strToPositiveNumber}
          name={`${name}.quantity`}
          Component={InputS}
          showErrorMsg={false}
          additionalProps={{
            type: 'number',
            step: 0.01,
            min: 0,
          }}
        />
      </FlexContainerS>
      <FlexContainerS width={5}>
        <ValueText>{getLineSubtotalByIndex(values, index)}</ValueText>
      </FlexContainerS>
      <FlexContainerS width={2}>
        {values && values.length > 1 && (
          <IconButton
            onClick={() => remove(index)}
            initialColor="#ff6868"
            hoverColor="#ed3d3d"
            name="close"
            size={8}
          />
        )}
      </FlexContainerS>
    </LineContainer>
  );
}

InvoiceLineField.propTypes = {
  name: string.isRequired,
  index: number.isRequired,
  fields: shape({
    value: arrayOf(
      shape({
        price: number,
        quantity: number,
      }),
    ),
    remove: func.isRequired,
  }).isRequired,
};

export function InvoiceFooter({ total, _id, addItem, loading }) {
  return (
    <FooterContainer>
      <TotalContainer>
        <LinkButton onClick={addItem} type="button">
          Add Item
        </LinkButton>
        <FlexContainer>
          <LabelS>Total</LabelS>
          <ValueText>{total}</ValueText>
        </FlexContainer>
      </TotalContainer>
      <SpaceBetween>
        <BlockInvoiceBtn invoiceId={_id} disabled={loading} />
        <FlexContainer>
          <CancelVerifyingBtn invoiceId={_id} disabled={loading} />
          <VerifyButton type="submit" size="small" disabled={loading}>
            Verify
          </VerifyButton>
        </FlexContainer>
      </SpaceBetween>
    </FooterContainer>
  );
}

InvoiceFooter.propTypes = {
  total: string.isRequired,
  _id: string.isRequired,
  addItem: func.isRequired,
  loading: bool.isRequired,
};
