import React from 'react';
import {
  READ_SERVICE_TYPE_PERMISSION,
  READ_BATCH_PERMISSION,
  READ_SUPPLIER_PERMISSION,
  CREATE_BATCH_PERMISSION,
  READ_PROPERTY_PERMISSION,
  READ_SPEND_REPORT_PERMISSION,
  UPDATE_OCR_INVOICE_PERMISSION,
  READ_RECURRING_INVOICE_PERMISSION,
  READ_SUPPLIER_TAX_REPORT_PERMISSION,
  READ_SUPPLIER_SPEND_REPORT_PERMISSION,
  READ_MASTER_SUPPLIER_PERMISSION,
  CREATE_USER_PERMISSION,
  UPDATE_USER_PERMISSION,
} from '@poly/security';
import { NotificationDot } from '@poly/admin-book';
import { PopUpMenuItemCounter } from '@poly/admin-book/src/AppBar/styles.js';
import { useMissingOCRInvoicesCounter } from '../../modules/recurringInvoice/useMissingOCRInvoicesCounter.js';
import { routes } from '../../routes/constants.js';

function NotificationDotComp() {
  const counterProps = useMissingOCRInvoicesCounter();

  // we don't want to render red dot for now
  const value = 0;

  return <NotificationDot {...counterProps} value={value} />;
}

function PopUpMenuItemCounterComp() {
  const counterProps = useMissingOCRInvoicesCounter();

  return <PopUpMenuItemCounter {...counterProps} />;
}

export const appBarMenuItems = [
  {
    title: 'Queue',
    link: routes.QUEUE,
    relatedRoutes: [routes.INVOICE_VERIFY],
    permissions: [UPDATE_OCR_INVOICE_PERMISSION],
  },
  {
    title: 'Approval',
    link: routes.APPROVAL,
    permissions: [CREATE_BATCH_PERMISSION],
  },
  {
    title: 'Batches',
    link: routes.BATCHES,
    relatedRoutes: [routes.BATCH],
    permissions: [READ_BATCH_PERMISSION],
  },
  {
    title: 'Reports',
    Bubble: NotificationDotComp,
    subItems: [
      {
        title: 'Missing Invoices',
        route: routes.MISSING_INVOICES,
        CounterComponent: PopUpMenuItemCounterComp,
        permissions: [READ_RECURRING_INVOICE_PERMISSION],
      },
      {
        title: 'Recurring Invoices',
        route: routes.RECURRING_INVOICES,
        permissions: [READ_RECURRING_INVOICE_PERMISSION],
      },
      {
        title: 'Spend Detail',
        route: routes.SPEND_DETAIL,
        permissions: [READ_SPEND_REPORT_PERMISSION],
      },
      {
        title: '1099',
        route: routes.REPORT_1099,
        permissions: [READ_SUPPLIER_SPEND_REPORT_PERMISSION],
      },
      {
        title: 'Supplier Tax',
        route: routes.SUPPLIER_TAX,
        permissions: [READ_SUPPLIER_TAX_REPORT_PERMISSION],
      },
      {
        title: 'Service Types',
        route: routes.SERVICE_TYPES,
        permissions: [READ_SERVICE_TYPE_PERMISSION],
      },
    ],
    isSub: true,
  },
  {
    title: 'Properties',
    link: routes.PROPERTIES,
    relatedRoutes: [routes.PROPERTY, routes.ADD_PROPERTY],
    permissions: [READ_PROPERTY_PERMISSION],
  },
  {
    title: 'Suppliers',
    link: routes.SUPPLIERS,
    relatedRoutes: [routes.SUPPLIER, routes.ADD_SUPPLIER],
    permissions: [READ_SUPPLIER_PERMISSION],
  },
  {
    title: 'Master Suppliers',
    link: routes.MASTER_SUPPLIERS,
    relatedRoutes: [routes.MASTER_SUPPLIER, routes.ADD_MASTER_SUPPLIER],
    permissions: [READ_MASTER_SUPPLIER_PERMISSION],
  },
  {
    title: 'User Management',
    link: routes.USER_MANAGEMENT,
    relatedRoutes: [routes.USER_MANAGEMENT],
    permissions: [CREATE_USER_PERMISSION, UPDATE_USER_PERMISSION],
  },
];
