import React from 'react';
import * as R from 'ramda';
import {
  usersEmailColumn,
  systemAccessStatusesColors,
  TablePagination,
  useMapConfigToTableProps,
} from '@poly/admin-ui';
import {
  Table,
  Dot,
  TableWithPaginationContainer,
  TableCard,
  Loader,
} from '@poly/admin-book';
import styled from 'styled-components';
import { NOTHING_UI_STRING } from '@poly/constants';
import { keywordSortQuery } from '@poly/client-utils';
import {
  array,
  arrayOf,
  bool,
  object,
  oneOfType,
  shape,
  string,
} from 'prop-types';

import { AddUserButton } from './AddUserButton.js';
import { EditUserButton } from './EditUserButton.js';

const TableCardS = styled(TableCard)`
  margin-top: -40px;
`;

const UsersTableComp = styled(Table)`
  th:nth-child(1),
  td:nth-child(1) {
    width: 25px;
    padding-top: 10px;
  }

  td:nth-child(1) {
    padding-top: 15px;
  }

  th:nth-child(2),
  td:nth-child(2) {
    width: 200px;
  }

  td:last-child {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  th:nth-child(5),
  td:nth-child(5) {
    width: 90px;
    text-align: right;
    padding-right: 30px;
  }
`;

export const usersTableConfig = [
  [
    '',
    ({ status }) => {
      const color = systemAccessStatusesColors[status];
      return <Dot color={color} />;
    },
  ],
  [
    'Name',
    R.propOr(NOTHING_UI_STRING, 'fullName'),
    keywordSortQuery(['profile', 'fullName']),
  ],
  usersEmailColumn,
  ['Role', R.pathOr(NOTHING_UI_STRING, ['userGroups', 0, 'userGroup', 'name'])],
  ['', ({ _id }) => <EditUserButton userId={_id} />],
];

export const userManagementPropTypes = arrayOf(
  shape({
    _id: string.isRequired,
    status: string.isRequired,
    fullName: oneOfType([string, array]).isRequired,
    userGroups: arrayOf(
      shape({
        userGroup: shape({
          name: string.isRequired,
        }),
      }),
    ),
  }),
);

export function UserManagementTable({ users, isPrint, tableSortProps }) {
  const tableConfig = isPrint
    ? R.dropLast(1, usersTableConfig)
    : usersTableConfig;

  const tableCommonProps = useMapConfigToTableProps(
    R.identity,
    tableConfig,
    users,
  );

  return <UsersTableComp {...{ ...tableCommonProps, ...tableSortProps }} />;
}

UserManagementTable.propTypes = {
  isPrint: bool,
  users: userManagementPropTypes.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  tableSortProps: object,
};

UserManagementTable.defaultProps = {
  tableSortProps: {},
};

const TableWithToolBarWrapper = styled.div`
  position: relative;
  height: auto;
  min-height: calc(100% - 80px);
`;

const TableToolBarWrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 45px;
  top: 15px;
  height: 16px;
  z-index: 1;
`;

export function UserManagementTableWithPagination({
  loading,
  users,
  tableSortProps,
}) {
  if (loading) {
    return <Loader />;
  }
  return (
    <TableCardS>
      <TableWithPaginationContainer paginationVisible>
        <TableWithToolBarWrapper>
          <TableToolBarWrapper>
            <AddUserButton />
          </TableToolBarWrapper>
          <UserManagementTable users={users} tableSortProps={tableSortProps} />
        </TableWithToolBarWrapper>

        <TablePagination />
      </TableWithPaginationContainer>
    </TableCardS>
  );
}
UserManagementTableWithPagination.propTypes = {
  users: userManagementPropTypes,
  loading: bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  tableSortProps: object,
};
