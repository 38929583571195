import React from 'react';
import { Select } from '@poly/admin-book';

export function UserRoleSelect(props) {
  // Note: this implemented within
  // https://gitlab.com/askpoly/poly-apps/-/issues/5588
  const options = [];

  return <Select {...props} options={options} loading={false} />;
}
