import React from 'react';
import * as R from 'ramda';
import { ofArrayLegacy } from '@poly/utils';
import { useDispatch } from 'react-redux';
import { bool, string, shape, arrayOf } from 'prop-types';
import { entities } from '@poly/admin-ui';
import { Select } from '@poly/admin-book';
import {
  useSetDefaultValueToURL,
  getAppendDefaultEntity,
  useQueryWithSearch,
} from '@poly/client-utils';

import {
  PROPERTIES_BY_SEARCH,
  PROPERTIES_BY_SEARCH_SUB,
} from '../apollo/usePropertiesBySearch.js';
import { ALL_VALUES } from '../../../utils/constants/general.js';
import { applyStaticOptions } from '../../../components/selects/UserSelect/UserSelect.js';
import { setSelectedRows } from '../../../state/modules/selected-rows.js';
import { useDefaultProperty } from './useDefaultProperty.js';

// getPropertyOptions :: ({ includeAllOption: Boolean, staticOptions: [[String]] })
// -> SearchPropertiesQueryResult -> [Option]
const getPropertyOptions = ({ includeAllOption, staticOptions }) =>
  R.compose(
    R.when(
      R.always(includeAllOption),
      R.prepend({ value: ALL_VALUES, label: 'All Properties' }),
    ),
    applyStaticOptions(staticOptions),
    R.map(R.applySpec({ value: R.prop('_id'), label: R.prop('name') })),
    R.pathOr([], ['searchProperties', 'hits']),
  );

export function PropertySelect(props) {
  const {
    value,
    staticOptions,
    error: errorFromProps,
    includeAllOption = true,
  } = props;

  const { data } = useDefaultProperty(value);

  const { result, error, onSearchChange } = useQueryWithSearch({
    gqlSearchQuery: PROPERTIES_BY_SEARCH,
    gqlSearchChangedQuery: PROPERTIES_BY_SEARCH_SUB,
    entity: entities.PROPERTY,
    error: errorFromProps,
    ...props,
  });

  const preparedData = getAppendDefaultEntity({
    entityPath: ['property'],
    allEntitiesPath: ['searchProperties'],
    props: {
      property: data?.property,
      searchProperties: result?.searchProperties,
    },
  });

  const selectProps = {
    ...props,
    error,
    isClearable: true,
    name: 'PropertiesSelect',
    onInputChange: onSearchChange,
    placeholder: 'Start typing property',
    options: getPropertyOptions({ includeAllOption, staticOptions })(
      preparedData,
    ),
  };

  return <Select {...selectProps} />;
}

PropertySelect.displayName = 'PropertySelect';

PropertySelect.propTypes = {
  error: string,
  value: string,
  includeAllOption: bool,
  staticOptions: arrayOf(arrayOf(string)),
};

export function PropertySelectWithRouter(props) {
  const dispatch = useDispatch();

  const { updateQueryParams, routerQuery } =
    useSetDefaultValueToURL('propertyId');

  const onChange = (value) => {
    updateQueryParams({ propertyId: value });
    dispatch(setSelectedRows([]));
  };

  return (
    <PropertySelect
      {...props}
      width="200px"
      onChange={onChange}
      value={routerQuery?.propertyId || ''}
    />
  );
}

PropertySelectWithRouter.displayName = 'PropertySelectWithRouter';

// getOCRInvoicePropertyStaticOptions :: FormData -> [[String]]
const getOCRInvoicePropertyStaticOptions = R.compose(
  R.unless(R.isEmpty, ofArrayLegacy),
  R.pathOr([], ['invoiceDetails', 'propertyOCR']),
);

export function OCRInvoicePropertySelect(props) {
  const { formData } = props;

  const staticOptions = getOCRInvoicePropertyStaticOptions(formData);

  return (
    <PropertySelect
      {...props}
      includeAllOption={false}
      staticOptions={staticOptions}
    />
  );
}

OCRInvoicePropertySelect.displayName = 'OCRInvoicePropertySelect';

OCRInvoicePropertySelect.propTypes = {
  formData: shape({
    invoiceDetails: shape({
      propertyOCR: arrayOf(arrayOf(string)),
    }),
  }),
};
