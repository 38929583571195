import * as R from 'ramda';
import React from 'react';
import { addressValidators, useEntityDuplication } from '@poly/client-utils';
import {
  AddressSection,
  BaseServiceTypesMultiSelectField,
} from '@poly/admin-ui';
import { SupplierSources } from '@poly/constants';
import { shape, string } from 'prop-types';
import { DuplicationInput } from '../../../components/DuplicationInput.js';
import { SupplierStatusSelect } from '../../../components/selects/SupplierStatusSelect.js';
import { SupplierTypeSelect } from '../../../components/selects/SupplierTypeSelect.js';
import { SUPPLIERS_BY_SEARCH_FILTER } from '../apollo/useSuppliersBySearch.js';
import { supplierNameQueryFromProps, supplierNamesFromProps } from './utils.js';

const fullWidth = '100%';

function SupplierInputComp({ formData, ...restProps }) {
  const skipSupplierId = formData?._id || '';

  const duplicationProps = useEntityDuplication({
    query: SUPPLIERS_BY_SEARCH_FILTER,
    itemsFromProps: supplierNamesFromProps,
    variablesFromProps: supplierNameQueryFromProps,
    props: { ...restProps, skipSupplierId },
  });

  return (
    <DuplicationInput
      {...restProps}
      {...duplicationProps}
      skipSupplierId={skipSupplierId}
    />
  );
}

SupplierInputComp.propTypes = {
  formData: shape({
    _id: string,
  }),
};

export const createSupplierModalSections = [
  {
    label: '',
    order: 1,
    layout: { column: 1 },
    fields: [
      {
        label: 'Company Name',
        order: 1,
        layout: { row: 1, width: fullWidth },
        field: {
          name: 'company.name',
          withFormData: true,
          Component: SupplierInputComp,
        },
        required: true,
        validators: [[R.identity, 'Supplier name is required']],
      },
      {
        order: 2,
        layout: { row: 2, width: fullWidth },
        field: {
          withFormData: true,
          name: 'company.servicesIds',
          Component: (props) => (
            <BaseServiceTypesMultiSelectField
              {...props}
              supplierSource={SupplierSources.TIAA}
            />
          ),
        },
        required: true,
        validators: [
          [R.complement(R.isEmpty), 'At least one service should be added'],
        ],
      },
      {
        order: 3,
        layout: { row: 3 },
        field: {
          name: 'company.address',
          Component: AddressSection,
        },
        required: true,
        validators: addressValidators,
      },
      {
        label: 'Status',
        order: 4,
        layout: { row: 4, width: fullWidth },
        field: {
          name: 'status',
          Component: SupplierStatusSelect,
        },
        required: true,
        validators: [[R.identity, 'Status is required']],
      },
      {
        label: 'Supplier Type',
        order: 5,
        layout: { row: 5, width: fullWidth },
        field: {
          name: 'type',
          Component: SupplierTypeSelect,
        },
        required: true,
        validators: [[R.identity, 'Supplier type is required']],
      },
    ],
  },
];
