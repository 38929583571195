import * as R from 'ramda';
import styled from 'styled-components';
import React, { useState } from 'react';
import {
  TableCardWithoutTabs,
  PageHeaderContainer,
  DefaultBodyWrapper,
  PageHeaderLinks,
  Heading,
  Button,
} from '@poly/admin-book';

import { Is1099Select } from './components/Is1099Select.js';
import { SearchInput } from '../../containers/SearchInput.js';
import { ThresholdSelect } from './components/ThresholdSelect.js';
import { YearSelect } from '../../components/selects/YearSelect.js';
import { Report1099Table } from './Report1099Table/Report1099Table.js';
import { ToolBarBtnDivider } from '../../components/lib/toolBar.js';
import { START_YEAR_PERIOD } from '../../utils/constants/general.js';
import { Report1099PagePlaceholder } from './components/Report1099PagePlaceholder.js';
import { Report1099PrintPDFBtn } from './Report1099PrintPDFBtn.js';
import { Report1099ExportXLSBtn } from './Report1099ExportXLSBtn.js';
import { use1099Report } from './use1099Report.js';

const PageHeaderLinksS = styled(PageHeaderLinks)`
  > div:last-child {
    > div:not(:last-child),
    > button {
      margin-right: 15px;
    }
  }
`;

export function Report1099Page() {
  const [year, setYear] = useState(START_YEAR_PERIOD);
  const [is1099, setIs1099] = useState(true);
  const [threshold, setThreshold] = useState(600);
  const [filter, setFilter] = useState({});

  const onFilterClick = () => setFilter({ year, is1099, threshold });

  const { loading, tableProps, filteredRows } = use1099Report({
    ...filter,
  });

  const disabled = filteredRows.length === 0;

  return (
    <>
      <PageHeaderContainer>
        <Heading size="h2">1099 Report</Heading>
        <PageHeaderLinksS
          topLinks={
            <>
              <Report1099PrintPDFBtn
                {...{ ...filter, disabled, filteredRows }}
              />
              <ToolBarBtnDivider />
              <Report1099ExportXLSBtn
                {...{ ...filter, disabled, filteredRows }}
              />
            </>
          }
          bottomLinks={
            <>
              <ThresholdSelect
                value={threshold}
                onChange={(value) => setThreshold(value)}
                required
              />
              <Is1099Select
                value={is1099}
                onChange={(value) => setIs1099(value)}
                required
              />
              <YearSelect
                value={year}
                onChange={(value) => setYear(value)}
                required
              />
              <Button
                size="small"
                data-testid="report-1099-filter-button"
                onClick={onFilterClick}
              >
                Filter
              </Button>
              <SearchInput />
            </>
          }
        />
      </PageHeaderContainer>
      <DefaultBodyWrapper>
        <TableCardWithoutTabs>
          {R.isEmpty(filter) ? (
            <Report1099PagePlaceholder />
          ) : (
            <Report1099Table loading={loading} {...tableProps} />
          )}
        </TableCardWithoutTabs>
      </DefaultBodyWrapper>
    </>
  );
}
