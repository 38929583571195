import * as R from 'ramda';
import { formatSocialSecurity, formatTaxId } from '@poly/utils';

// prepare1099ReportData :: Suppliers1099Report -> [Object]
export const prepare1099ReportData = R.compose(
  R.map(
    R.applySpec({
      _id: R.prop('_id'),
      compensation: R.prop('compensation'),
      name: R.path(['supplier', 'company', 'name']),
      taxId: R.ifElse(
        R.path(['supplier', 'tax', 'id']),
        R.compose(formatTaxId, R.path(['supplier', 'tax', 'id'])),
        R.compose(
          formatSocialSecurity,
          R.pathOr('', ['supplier', 'tax', 'socialSecurityNumber']),
        ),
      ),
      is1099: R.compose(
        R.ifElse(R.identity, R.always('1099'), R.always('')),
        R.path(['supplier', 'tax', 'is1099']),
      ),
      address: R.compose(
        R.compose(R.replace(/, USA$/, ''), R.defaultTo('')),
        R.converge(R.concat, [
          R.pathOr('', [
            'supplier',
            'company',
            'address',
            'address_parts',
            'street_number',
          ]),
          R.pipe(
            R.pathOr('', [
              'supplier',
              'company',
              'address',
              'address_parts',
              'route',
            ]),
            R.concat(' '),
          ),
        ]),
      ),
      addressTwo: R.pathOr('', ['supplier', 'company', 'addressTwo']),
      city: R.pathOr('', [
        'supplier',
        'company',
        'address',
        'address_parts',
        'locality',
      ]),
      state: R.pathOr('', [
        'supplier',
        'company',
        'address',
        'address_parts',
        'administrative_area_level_1',
      ]),
      zip: R.pathOr('', [
        'supplier',
        'company',
        'address',
        'address_parts',
        'postal_code',
      ]),
    }),
  ),
  R.defaultTo([]),
  R.prop('suppliersCompensationReport'),
);
