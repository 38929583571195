import React from 'react';
import styled from 'styled-components';
import { oneOfType, arrayOf, element } from 'prop-types';
import { AuthRoutesRedirect } from '@poly/client-utils';

import { CenterContent } from '../lib/centerContent.js';
import { routes, authRoutesList } from '../../routes/constants.js';

const AuthLayoutWrapper = styled(CenterContent)`
  height: 100vh;
`;

export function AuthLayout({ children }) {
  return (
    <AuthLayoutWrapper>
      {children}
      <AuthRoutesRedirect
        homeRoute={routes.BATCHES}
        authRoutesList={authRoutesList}
      />
    </AuthLayoutWrapper>
  );
}

AuthLayout.propTypes = {
  children: oneOfType([arrayOf(element), element]).isRequired,
};
AuthLayout.displayName = 'LoginLayout';
