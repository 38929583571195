import styled from 'styled-components';
import React from 'react';
import {
  Table,
  TableCard,
  TableWithPaginationContainer,
} from '@poly/admin-book';
import { TablePagination } from '@poly/admin-ui/src/components/Pagination.js';

const TableS = styled(Table)`
  th:nth-child(1),
  td:nth-child(1) {
    width: 20%;
  }

  th:nth-child(3),
  th:nth-child(4),
  td:nth-child(3),
  td:nth-child(4) {
    width: 15%;
  }

  th:last-child,
  td:last-child {
    width: 60px;
    text-align: center;
  }
`;

export function ServiceTypesTableC(props) {
  return (
    <TableCard>
      <TableWithPaginationContainer paginationVisible>
        <TableS {...props} />
      </TableWithPaginationContainer>
      <TablePagination />
    </TableCard>
  );
}
