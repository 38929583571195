import React from 'react';
import { FormField, Button, Input } from '@poly/admin-book';
import styled from 'styled-components';
import { useModalContext, CancelBtn, PhoneNumberInput } from '@poly/admin-ui';
import { func, string } from 'prop-types';
import { UserStatusSelect } from './UserStatusSelect.js';
import { UserRoleSelect } from './UserRoleSelect.js';

const ButtonsWrapper = styled.div`
  display: flex;
  margin-top: 35px;
  gap: 10px;
`;

const FormFieldS = styled(FormField)`
  margin: 20px 0;
`;

const FormFieldForSelectS = styled(FormField)`
  margin-bottom: 20px;
`;

export function UserForm({ formId, handleSubmit }) {
  const { closeModal } = useModalContext();

  return (
    <form onSubmit={handleSubmit}>
      <FormFieldS
        name="firstName"
        Component={Input}
        additionalProps={{
          label: 'First Name',
          required: true,
        }}
      />
      <FormFieldS
        name="lastName"
        Component={Input}
        additionalProps={{
          label: 'Last Name',
          required: true,
        }}
      />
      <FormFieldS
        name="email"
        Component={Input}
        additionalProps={{
          label: 'Email',
          required: true,
        }}
      />
      <FormFieldS
        name="work"
        Component={PhoneNumberInput}
        additionalProps={{
          label: 'Phone',
        }}
      />
      <FormFieldS
        name="cell"
        Component={PhoneNumberInput}
        additionalProps={{
          label: 'Mobile',
        }}
      />
      <FormFieldForSelectS
        name="userGroupId"
        Component={UserRoleSelect}
        additionalProps={{
          label: 'Role',
          required: true,
        }}
      />
      <FormFieldForSelectS
        name="status"
        Component={UserStatusSelect}
        additionalProps={{
          label: 'Status',
          required: true,
        }}
      />
      <ButtonsWrapper>
        <CancelBtn onClick={() => closeModal(formId)} />
        <Button size="small" type="submit">
          Save
        </Button>
      </ButtonsWrapper>
    </form>
  );
}

UserForm.propTypes = {
  formId: string.isRequired,
  handleSubmit: func.isRequired,
};
