import React from 'react';
import { CommonTablePrintBtn, CommonPrintLayout } from '@poly/admin-ui';
import { PRINT_TYPES } from '@poly/constants';
import { string } from 'prop-types';
import { setOpenModal } from '../../state/modules/modal.js';
import { ModalNames } from '../../utils/constants/modals.js';
import {
  StoreProvider,
  ThemeProvider,
  ApolloClientProvider,
} from '../../providers.js';
import {
  PrintPropertyInvoicesTable,
  PrintSupplierInvoicesTable,
} from '../../modules/invoice/EntityInvoicesTab/index.js';
import { PrintSupplierTaxReportTable } from '../../modules/supplier/SupplierTaxReportPage/SupplierTaxReportTable/SupplierTaxReportTable.js';

export const printTables = {
  [PRINT_TYPES.REPORT_SUPPLIER_STATUS]: PrintSupplierTaxReportTable,
  [PRINT_TYPES.PROPERTY_INVOICES]: PrintPropertyInvoicesTable,
  [PRINT_TYPES.SUPPLIER_INVOICES_TIAA]: PrintSupplierInvoicesTable,
};

export const printTableFileNames = {
  [PRINT_TYPES.REPORT_SUPPLIER_STATUS]: 'supplier_tax_report',
  [PRINT_TYPES.REPORT_1099]: '1099_report',
  [PRINT_TYPES.PROPERTY_INVOICES]: 'property_invoices',
  [PRINT_TYPES.SUPPLIER_INVOICES_TIAA]: 'supplier_invoices',
};

export function PrintLayout(props) {
  return (
    <ApolloClientProvider>
      <ThemeProvider>
        <StoreProvider>
          <CommonPrintLayout {...props} isAAC />
        </StoreProvider>
      </ThemeProvider>
    </ApolloClientProvider>
  );
}

const openWarningModal = ({ data, printType, metaData, ...actions }) =>
  actions.setOpenModal({
    name: ModalNames.CONFIRM_PRINT,
    payload: {
      data,
      metaData,
      printType,
    },
  });

export function TablePrintBtn({ printType, ...restProps }) {
  return (
    <CommonTablePrintBtn
      {...restProps}
      openWarningModal={openWarningModal}
      Layout={PrintLayout}
      Table={printTables[printType]}
      fileName={printTableFileNames[printType]}
      setOpenModal={setOpenModal}
      printType={printType}
    />
  );
}

TablePrintBtn.displayName = 'TablePrintBtn';

TablePrintBtn.propTypes = {
  printType: string,
};
