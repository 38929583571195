import { useState } from 'react';
import { debounce } from '@poly/utils';
import { useQuery } from '@apollo/client';

export const useEntityDuplication = ({
  query,
  itemsFromProps,
  variablesFromProps,
  props,
}) => {
  const [nameSearch, setNameSearch] = useState('');

  const debouncedNameSearch = debounce(400)(setNameSearch);

  const onChange = (e) => {
    const {
      target: { value },
    } = e;
    debouncedNameSearch(value.trim());
    props.onChange(e);
  };

  const { data } = useQuery(query, {
    variables: variablesFromProps({ nameSearch }),
    notifyOnNetworkStatusChange: true,
    alias: 'useEntityDuplication',
  });

  return {
    onChange,
    nameSearch,
    setNameSearch,
    ...itemsFromProps({ data, ownProps: props }),
  };
};
