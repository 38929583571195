import React from 'react';
import { matchRoutes, useLocation } from '@poly/client-routing';
import { pathEqLegacy } from '@poly/utils';

import { routes } from '../../routes/constants.js';
import { appBarMenuItems } from './appBarMenuItems.js';
import { AppBarC } from '../../components/appBar/AppBarC.js';
import {
  prepareMenuItems,
  useCustomActiveByVerifiedInvoice,
} from './helpers.js';

// checkIfIsItInvoiceRoute :: Object -> Bool
export const checkIfIsItInvoiceRoute = (location) =>
  pathEqLegacy(
    [0, 'route', 'path'],
    routes.INVOICE,
    matchRoutes([{ path: routes.INVOICE }], location),
  );

export function AppBar() {
  const location = useLocation();

  const isInvoiceRoute = checkIfIsItInvoiceRoute(location);

  const { customActive } = useCustomActiveByVerifiedInvoice(isInvoiceRoute);

  const menuItems = prepareMenuItems(customActive, appBarMenuItems);

  return <AppBarC menuItems={menuItems} />;
}

AppBar.displayName = 'AppBar';
