import React from 'react';
import { PSCategoryDescriptionByXeroAccountCode } from '@poly/constants';
import { formatSelectOptionsByConstants } from '@poly/client-utils';
import { Select } from '@poly/admin-book';

export function ServiceTypeXeroAccountCodeSelect(props) {
  const selectProps = {
    ...props,
    allowModalOverflow: true,
    name: 'ServiceTypeXeroAccountCodeSelect',
    options: formatSelectOptionsByConstants(
      PSCategoryDescriptionByXeroAccountCode,
    ),
  };

  return <Select {...selectProps} />;
}

ServiceTypeXeroAccountCodeSelect.displayName =
  'ServiceTypeXeroAccountCodeSelect';
