import * as R from 'ramda';
import { formatTotal, calcOCRInvoiceTotalByLines } from '@poly/utils';

// getLineSubtotalByIndex :: ([Line], number) -> String
export const getLineSubtotalByIndex = R.compose(
  formatTotal,
  R.converge(R.multiply, [
    ([values, index]) =>
      R.compose(R.defaultTo(0), R.path([index, 'price']))(values),
    ([values, index]) =>
      R.compose(R.defaultTo(0), R.path([index, 'quantity']))(values),
  ]),
  Array.of,
);

// getLinesTotal :: [Line] -> String
// Line = Object
export const getLinesTotal = R.pipe(calcOCRInvoiceTotalByLines, formatTotal);

/**
 * invoiceDetailsFromInvoice :: Invoice -> Object
 */
export const invoiceDetailsFromInvoice = R.applySpec({
  _id: R.prop('_id'),
  invoiceNumber: R.prop('invoiceNumber'),
  invoiceFileLink: R.prop('invoiceFileLink'),
  invoiceId: R.prop('invoiceId'),
  invoiceDate: R.prop('invoiceDate'),
  status: R.pathOr('', ['workflow', 'status']),
  supplierOcrAddress: R.path([
    'workflow',
    'unvalidatedData',
    'supplierAddress',
  ]),
  propertyOcrAddress: R.path([
    'workflow',
    'unvalidatedData',
    'propertyAddress',
  ]),
});
