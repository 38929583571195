import styled from 'styled-components';
import { getThemeColor, VerticalDivider } from '@poly/admin-book';

export const ToolBarBtnDivider = styled(VerticalDivider)`
  width: 2px;
  height: 14px;
  opacity: 0.12;
  margin: 0 12px;
  flex-shrink: 0;
  background-color: transparent;
  border: 1px solid ${getThemeColor(['primary'])};
`;
