import React from 'react';
import { useModalContext } from '@poly/admin-ui';
import { TextButton } from '@poly/admin-book';

import { AddUserForm, addUserFormId } from './userForm/AddUserForm.js';

export function AddUserButton() {
  const { openModal } = useModalContext();

  const openAddUserForm = () =>
    openModal({
      id: addUserFormId,
      title: 'Create User',
      content: <AddUserForm />,
    });

  return <TextButton onClick={openAddUserForm}>Add New</TextButton>;
}
