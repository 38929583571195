import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { string } from 'prop-types';
import { TableLayoutList, WhiteCard, Loader } from '@poly/admin-book';
import { useLocation } from '@poly/client-routing';
import { checkIfIsItInvoiceRoute } from '../../../../containers/appBar/AppBar.js';
import { prepareDataForDisplay } from './utils.js';
import {
  BatchLink,
  InvoicePDFLink,
  PropertyLink,
  SupplierLink,
} from '../../../../containers/links/Links.js';
import { useInvoiceAccount } from '../../apollo/useInvoiceAccount.js';

const TableLayoutListS = styled(TableLayoutList)`
  width: ${R.propOr('20%', 'width')};
  padding: 5px;
`;

function ServiceLocationComp({ propertyName, propertyAddress, propertyId }) {
  return (
    <>
      <PropertyLink name={propertyName} _id={propertyId} />
      <br />
      {propertyAddress}
    </>
  );
}

ServiceLocationComp.propTypes = {
  propertyName: string.isRequired,
  propertyAddress: string.isRequired,
  propertyId: string.isRequired,
};

const sectionsConfig = [
  {
    list: [
      [
        'Supplier',
        ({ supplierCompanyName, supplierId }) => (
          <SupplierLink _id={supplierId} name={supplierCompanyName} />
        ),
      ],
      ['Service Location', ServiceLocationComp],
      ['Manager', R.prop('managerFullName')],
    ],
    key: 1,
    width: '25%',
    fullWidth: true,
  },
  {
    list: [
      ['Invoice Amount', R.prop('total')],
      ['Invoice Date', R.prop('invoiceDate')],
      ['Account Number', R.prop('supplierOuterContractId')],
      ['Recurring', R.prop('isRecurringInvoice')],
    ],
    key: 2,
    width: '25%',
    fullWidth: true,
  },
  {
    list: [
      ['Invoice #', (props) => <InvoicePDFLink {...props} />],
      ['Receive Date', R.prop('receiveDate')],
    ],
    key: 3,
    fullWidth: true,
  },
  {
    list: [
      ['Due Date', R.prop('dueDate')],
      [
        'Batch #',
        R.ifElse(
          R.prop('batch'),
          ({ batch }) => <BatchLink {...batch} />,
          R.always('None'),
        ),
      ],
    ],
    key: 4,
    fullWidth: true,
  },
];

const WhiteCardS = styled(WhiteCard)`
  padding: 30px;
  align-items: flex-start;
`;

function TiaaInvoiceSectionsComp(props) {
  return (
    <WhiteCardS>
      {sectionsConfig.map(({ list, ...rest }) => (
        <TableLayoutListS
          list={list.map(([title, getValue]) => ({
            title,
            elem: getValue(props),
            key: title,
          }))}
          {...rest}
        />
      ))}
    </WhiteCardS>
  );
}

export function InvoiceDetails() {
  const location = useLocation();

  const isInvoiceRoute = checkIfIsItInvoiceRoute(location);

  const { data, loading } = useInvoiceAccount(isInvoiceRoute);

  if (loading) return <Loader />;

  const commonProps = prepareDataForDisplay(data?.ocrInvoice);

  return <TiaaInvoiceSectionsComp {...commonProps} />;
}
