import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { formatTotal } from '@poly/utils';
import { NOTHING_UI_STRING, OCRInvoiceStatuses } from '@poly/constants';
import {
  FlexColumn,
  FlexFullWidthJustifyStart,
  SearchResultItemContainer,
  SearchResultItemDivider,
  SearchResultItemLinked,
  SearchResultItemTitle,
  FlexContainer,
  highlightedTextPropType,
} from '@poly/admin-ui';

const Wrapper = styled(FlexColumn)`
  width: 190px;
  margin-left: 20px;
  flex-shrink: 0;
  letter-spacing: 1px;
  align-items: center;
  align-items: flex-start;
`;

const SearchResultStatus = styled(SearchResultItemLinked)`
  flex-shrink: 0;
`;

function InvoicesSearchResultComp({
  invoiceId,
  invoiceNumber,
  checkNumber,
  status,
  supplierName,
  amount,
}) {
  return (
    <SearchResultItemContainer>
      <FlexColumn>
        <FlexFullWidthJustifyStart>
          <FlexContainer>
            <SearchResultItemLinked>{invoiceId}</SearchResultItemLinked>
            <SearchResultItemLinked>{supplierName}</SearchResultItemLinked>
          </FlexContainer>
        </FlexFullWidthJustifyStart>
        <SearchResultItemDivider />
        <SearchResultItemTitle>
          INV#
          {invoiceNumber}
        </SearchResultItemTitle>
      </FlexColumn>
      <Wrapper>
        <FlexContainer>
          <SearchResultStatus>{status}</SearchResultStatus>
          {status === OCRInvoiceStatuses.PAID && (
            <SearchResultItemTitle>
              CK#
              {checkNumber}
            </SearchResultItemTitle>
          )}
        </FlexContainer>
        {status !== OCRInvoiceStatuses.BLOCKED && (
          <SearchResultItemLinked>
            Amount &nbsp;
            {amount}
          </SearchResultItemLinked>
        )}
      </Wrapper>
    </SearchResultItemContainer>
  );
}

InvoicesSearchResultComp.propTypes = {
  invoiceNumber: highlightedTextPropType.isRequired,
  checkNumber: highlightedTextPropType.isRequired,
  status: highlightedTextPropType.isRequired,
  invoiceId: highlightedTextPropType.isRequired,
  supplierName: highlightedTextPropType,
  amount: highlightedTextPropType.isRequired,
};

// getCommonProps :: Properties -> Object
const getCommonProps = R.applySpec({
  invoiceNumber: R.prop('invoiceNumber'),
  checkNumber: R.pathOr(NOTHING_UI_STRING, [
    'workflow',
    'check',
    'checkNumber',
  ]),
  status: R.compose(R.toUpper, R.path(['workflow', 'status'])),
  invoiceId: R.prop('invoiceId'),
  supplierName: R.path(['supplier', 'company', 'name']),
  amount: R.compose(formatTotal, R.prop('total')),
});

export function InvoicesSearchResult(props) {
  const commonProps = getCommonProps(props);

  return <InvoicesSearchResultComp {...props} {...commonProps} />;
}
