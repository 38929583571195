import React from 'react';
import { useDispatch } from 'react-redux';
import { FormCreator } from '@poly/admin-book';
import { usePristineSubscribe } from '@poly/client-routing';
import { useOnSubmitSetStopSubmitting, useProcessState } from '@poly/admin-ui';
import { createServiceTypeModalSections } from './sections.js';
import { createServiceTypeFormModal, initialValues } from './constants.js';
import { ModalFormWrapper } from '../../../containers/modal/ModalWrapper.js';
import { prepareFormDataForMutation } from './utils.js';
import { useCreateServiceType } from '../apollo/withCreateServiceType.js';
import { setOpenModal } from '../../../state/modules/modal.js';

function CreateServiceTypeFormModal(props) {
  const { createServiceType } = useCreateServiceType();

  const onSubmitHandler = async (data) => {
    await createServiceType(prepareFormDataForMutation(data));
  };

  const { onSubmit } = useOnSubmitSetStopSubmitting(
    createServiceTypeFormModal,
    onSubmitHandler,
  );

  const subscriptionProps = usePristineSubscribe();

  return (
    <FormCreator
      {...props}
      {...subscriptionProps}
      onSubmit={onSubmit}
      id={createServiceTypeFormModal}
      initialValues={initialValues}
      sections={createServiceTypeModalSections}
      layout={{
        width: '400px',
        overflow: 'visible',
        card: false,
      }}
    />
  );
}

CreateServiceTypeFormModal.displayName = 'CreateServiceTypeFormModal';

export function CreateServiceTypeModal(props) {
  const dispatch = useDispatch();

  const { process } = useProcessState(createServiceTypeFormModal);

  const onCancel = () => dispatch(setOpenModal({ name: null }));

  return (
    <ModalFormWrapper
      {...props}
      actionInProgress={!!process}
      title="Create Service Type"
      buttonLabel="Create"
      formId={createServiceTypeFormModal}
      Form={CreateServiceTypeFormModal}
      width="400px"
      onCancel={onCancel}
    />
  );
}
