import React from 'react';
import { TasksTab as TasksTabImp, useModalContext } from '@poly/admin-ui';
import { string, shape } from 'prop-types';
import {
  CompleteTaskForm,
  completeTaskFormId,
} from '../forms/taskForm/CompleteTaskForm.js';
import {
  PostTaskUpdateForm,
  postTaskUpdateFormId,
} from '../forms/taskForm/PostTaskUpdateForm.js';
import { AddTaskForm, addTaskFormId } from '../forms/taskForm/AddTaskForm.js';
import {
  EditTaskForm,
  editTaskFormId,
} from '../forms/taskForm/EditTaskForm.js';

const useCommonTaskHandlers = ({
  collection,
  entity,
  defaultQuery,
  taskType,
  apollo,
}) => {
  const { openModal } = useModalContext();

  const onCompleteTask = ({ _id }) =>
    openModal({
      id: completeTaskFormId,
      formId: completeTaskFormId,
      content: (
        <CompleteTaskForm
          payload={{
            _id,
            entity,
            taskType,
            collection,
            apollo,
          }}
        />
      ),
    });

  const onEditTask = ({ _id }) =>
    openModal({
      id: editTaskFormId,
      formId: editTaskFormId,
      content: (
        <EditTaskForm
          payload={{
            _id,
            entity,
            taskType,
            collection,
          }}
        />
      ),
    });

  const onUpdateTask = ({ _id }) =>
    openModal({
      id: postTaskUpdateFormId,
      formId: postTaskUpdateFormId,
      content: (
        <PostTaskUpdateForm
          payload={{
            _id,
            entity,
            taskType,
            collection,
          }}
        />
      ),
    });

  const onAddTask = () =>
    openModal({
      id: addTaskFormId,
      formId: addTaskFormId,
      content: (
        <AddTaskForm
          payload={{
            entity,
            collection,
            defaultQuery,
            _id: entity._id,
          }}
        />
      ),
    });

  return {
    onCompleteTask,
    onEditTask,
    onUpdateTask,
    onAddTask,
  };
};

export function TasksTab(props) {
  const taskHandlers = useCommonTaskHandlers(props);

  return <TasksTabImp {...props} {...taskHandlers} />;
}

TasksTab.displayName = 'TasksTab';

TasksTab.propTypes = {
  collection: string,
  entity: shape({
    name: string,
    _id: string,
    humanReadableId: string,
  }),
  defaultQuery: shape({
    kind: string,
  }),
  routerParams: shape({
    invoiceId: string,
  }),
};
