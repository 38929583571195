import React from 'react';
import { useOnSubmitSetStopSubmitting, useProcessState } from '@poly/admin-ui';
import { Button, useNotificationContext } from '@poly/admin-book';
import { gql, useMutation } from '@apollo/client';
import { useRouterParams } from '@poly/client-routing';

const PAY_TIAA_BATCH_CITI_MUTATION = gql`
  mutation PAY_TIAA_BATCH_CITI_MUTATION($batchId: ID!) {
    payTiaaBatchInCiti(batchId: $batchId)
  }
`;

const usePayBatchCiti = () => {
  const [mutate] = useMutation(PAY_TIAA_BATCH_CITI_MUTATION);

  return {
    payTiaaBatchInCiti: (batchId) =>
      mutate({
        variables: {
          batchId,
        },
      }),
  };
};

const payCitiProcessId = 'payCitiProcessId';

export function PayCityBankBtn() {
  const { batchId } = useRouterParams(['batchId']);

  const { process } = useProcessState(payCitiProcessId);

  const { payTiaaBatchInCiti } = usePayBatchCiti();

  const { showSuccessNotification } = useNotificationContext();

  const onSubmitHandler = async () => {
    await payTiaaBatchInCiti(batchId);
    showSuccessNotification('Payment file was sent to Citi Bank');
  };

  const { onSubmit } = useOnSubmitSetStopSubmitting(
    payCitiProcessId,
    onSubmitHandler,
  );

  return (
    <Button
      id={payCitiProcessId}
      size="small"
      onClick={onSubmit}
      loader={process}
      disabled={process}
    >
      Pay (CITI)
    </Button>
  );
}

PayCityBankBtn.displayName = 'PayCityBankBtn';
