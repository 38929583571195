import React from 'react';
import styled from 'styled-components';
import { arrayOf, bool, shape, string } from 'prop-types';
import { Input } from '@poly/admin-book';
import { SimilarNamesInputComp } from '@poly/admin-ui';

const Wrapper = styled.div`
  flex-direction: column;
  margin-top: 10px;
`;

export function DuplicationInput({
  items,
  enabledSimilarNamesInput = true,
  ...props
}) {
  return (
    <Wrapper>
      <Input {...props} />
      {enabledSimilarNamesInput ? (
        <SimilarNamesInputComp items={items} />
      ) : null}
    </Wrapper>
  );
}

DuplicationInput.propTypes = {
  items: arrayOf(
    shape({
      url: string,
      name: string.isRequired,
    }),
  ),
  enabledSimilarNamesInput: bool,
};
