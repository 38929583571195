import React from 'react';
import { shape, string, arrayOf } from 'prop-types';
import styled from 'styled-components';
import {
  MenuGenerator,
  VerticalDivider,
  AppBar as AppBarWrapper,
} from '@poly/admin-book';
import { GlobalSearchInput } from '@poly/admin-ui';

import { UserPopUp } from '../../containers/appBar/userPopUp.js';
import { GlobalSearchResult } from '../../containers/globalSearch/globalSearchResult/GlobalSearchResult.js';
import { TiaaLogo } from '../lib/tiaaLogo.js';
import { GlobalAddButton } from '../../containers/appBar/GlobalAddButton.js';

const FlexRightAligned = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

const VerticalDividerS = styled(VerticalDivider)`
  margin-right: 20px;
  height: 34px;
`;

const AACLogoWrapper = styled.div`
  width: 80px;
  height: 30px;
  margin-left: 20px;
  margin-right: 10px;
`;

const TiaaLogoS = styled(TiaaLogo)`
  background-color: #fff;
  padding: 5px;
  border-radius: 7px;
`;

export function AppBarC({ menuItems }) {
  return (
    <AppBarWrapper>
      <AACLogoWrapper>
        <TiaaLogoS />
      </AACLogoWrapper>
      <MenuGenerator menuItems={menuItems} />
      <GlobalAddButton />
      <GlobalSearchInput GlobalSearchResult={GlobalSearchResult} />
      <FlexRightAligned>
        <VerticalDividerS />
        <UserPopUp />
      </FlexRightAligned>
    </AppBarWrapper>
  );
}

AppBarC.displayName = 'AppBar';
AppBarC.propTypes = {
  menuItems: arrayOf(shape({ title: string })).isRequired,
};
