import * as R from 'ramda';
import { gql, useLazyQuery } from '@apollo/client';
import { useEntitySubscriptionQuery } from '@poly/client-utils';
import { useDispatch } from 'react-redux';
import { propEqLegacy } from '@poly/utils';

import { setOpenModal } from '../../../../state/modules/modal.js';
import { ModalNames } from '../../../../utils/constants/modals.js';
import { usePropertyDetails } from '../../../property/apollo/withPropertyDetails.js';

const INVOICE_DUPLICATES_QUERY = gql`
  query INVOICE_DUPLICATES_QUERY($searchInput: CollectionSearchParams!) {
    searchOCRInvoices(input: $searchInput) {
      hits {
        _id
        invoiceNumber
        supplier {
          _id
        }
      }
    }
  }
`;

export const useHandleInvoiceSave = () => {
  const dispatch = useDispatch();

  const [queryHandler] = useLazyQuery(INVOICE_DUPLICATES_QUERY);

  return {
    handleInvoiceSave: ({
      invoiceUpdate,
      invoice,
      saveWorkflow,
      whenNoDuplicates,
    }) =>
      queryHandler({
        variables: {
          searchInput: {
            query: {
              bool: {
                must_not: [{ match: { _id: invoice._id } }],
                must: [
                  {
                    match: {
                      invoiceNumber:
                        invoiceUpdate.update.invoiceNumber ||
                        invoice.invoiceNumber,
                    },
                  },
                  {
                    match: {
                      supplierId:
                        invoiceUpdate.update.supplierId || invoice.supplierId,
                    },
                  },
                ],
              },
            },
          },
        },
      })
        .then(
          R.pipe(
            R.path(['data', 'searchOCRInvoices', 'hits']),
            R.reject(propEqLegacy('_id', invoice._id)),
            R.length,
          ),
        )
        .then(
          R.ifElse(
            R.identity,
            (count) =>
              dispatch(
                setOpenModal({
                  name: ModalNames.CONFIRM_INVOICE_SAVE,
                  payload: {
                    invoiceDuplicatesCount: count,
                    invoiceUpdate,
                    invoiceNumber:
                      invoiceUpdate.update.invoiceNumber ||
                      invoice.invoiceNumber,
                    saveWorkflow,
                  },
                }),
              ),
            whenNoDuplicates,
          ),
        ),
  };
};

export const usePropertyAddressLogic = (componentProps) => {
  const { data } = usePropertyDetails(componentProps.value);

  return {
    address: R.path(['property', 'address', 'formatted_address'], data),
    addressByOcr: R.path(
      ['formData', 'invoiceDetails', 'propertyOcrAddress'],
      componentProps,
    ),
    label: 'Property',
  };
};

const SUPPLIER_ADDRESS = gql`
  query SUPPLIER_ADDRESS($id: ID!) {
    supplier(id: $id) {
      _id
      company {
        name
        address {
          formatted_address
        }
      }
    }
  }
`;

const SUPPLIER_ADDRESS_CHANGED = gql`
  subscription SUPPLIER_CHANGED($input: SingleDocSubInput!) {
    supplierChanged(input: $input) {
      id
      type
    }
  }
`;

// getIdFromProps :: Object -> Object
const getIdFromProps = R.compose(R.objOf('id'), R.prop('value'));

export const useSupplierAddressLogic = (componentProps) => {
  const { restProps } = useEntitySubscriptionQuery({
    gqlQuery: SUPPLIER_ADDRESS,
    gqlSubscription: SUPPLIER_ADDRESS_CHANGED,
    queryEndpointName: 'supplier',
    subscriptionEndpointName: 'supplierChanged',
    queryParamsFromProps: getIdFromProps,
    subscriptionParamsFromProps: getIdFromProps,
    propsOfComponent: { value: componentProps?.value },
    skipQuery: !componentProps?.value,
    alias: 'useSupplierAddressLogic',
  });

  return {
    address: R.path(
      ['supplier', 'company', 'address', 'formatted_address'],
      restProps,
    ),
    addressByOcr: R.path(
      ['formData', 'invoiceDetails', 'supplierOcrAddress'],
      componentProps,
    ),
    label: 'Supplier',
  };
};
