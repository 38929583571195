import React from 'react';
import { gql } from '@apollo/client';
import { UpdateCollections } from '@poly/constants';
import { commonUpdateFields, entities } from '@poly/admin-ui';
import { useRouterParams } from '@poly/client-routing/src/hooks.js';
import { Loader } from '@poly/admin-book';
import { UpdatesTab } from '../../../containers/tabs/UpdatesTab.js';
import { usePropertyDetails } from '../apollo/withPropertyDetails.js';

export const PROPERTY_UPDATES = gql`
  query PROPERTY_UPDATES(
    $id: ID!
    $documentUpdatesInput: DocumentUpdatesInput!
  ) {
    property(id: $id) {
      _id
      documentUpdates(input: $documentUpdatesInput) {
        hits {
          ...commonUpdateFields
        }
        total
      }
    }
  }
  ${commonUpdateFields}
`;

export function PropertyUpdatesTab() {
  const { propertyId } = useRouterParams(['propertyId']);

  const { data, loading } = usePropertyDetails(propertyId);

  if (loading) return <Loader />;

  return (
    <UpdatesTab
      collection={UpdateCollections.properties}
      updatesQuery={PROPERTY_UPDATES}
      entity={{
        _id: data?.property._id,
        name: entities.PROPERTY,
        humanReadableId: data?.property.name,
      }}
    />
  );
}
