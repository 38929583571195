import React from 'react';
import styled from 'styled-components';
import { string, func } from 'prop-types';
import { Link } from '@poly/client-routing';
import { Heading, IconButton, getThemeColor } from '@poly/admin-book';

export const HeaderFlexRow = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  min-width: 0;

  > div {
    flex-shrink: 0;
    width: auto;
    margin-right: 50px;
  }
`;

export const EntityId = styled(Heading).attrs({ size: 'h2' })`
  color: ${getThemeColor(['primary'])};
  flex-shrink: 0;
`;

const LinkS = styled(Link)`
  padding-top: 2px;
  margin-right: 10px;
`;

export function BackButton({ link, onClick }) {
  return (
    <LinkS href={link}>
      <IconButton onClick={onClick} name="arrow-back" size={13} />
    </LinkS>
  );
}

BackButton.displayName = 'BackButton';
BackButton.propTypes = {
  link: string.isRequired,
  onClick: func,
};
BackButton.defaultProps = { onClick: () => {} };
