import styled from 'styled-components';
import React from 'react';
import {
  DefaultBodyWrapper,
  PageHeaderContainer,
  MainHeader,
} from '@poly/admin-book';

import { SpendDetailsToolbar } from './spendDetailsToolbar/SpendDetailsToolbar.js';
import { SpendDetailsTable } from './SpendDetailsTable/SpendDetailsTable.js';
import { routes } from '../../../routes/constants.js';
import { FlexContainer } from '../InvoicePage/common/styles.js';
import { BackButton } from '../../../containers/buttons/BackButton.js';

const DefaultBodyWrapperS = styled(DefaultBodyWrapper)`
  height: auto;
  overflow-y: scroll;
`;

const TableWrapper = styled.div`
  min-height: calc(100vh - 160px);
`;

const TitleWrapper = styled(FlexContainer)`
  margin: 5px;
  align-items: center;
`;

export function SpendDetailsPage() {
  return (
    <>
      <PageHeaderContainer headerStyles="height: 100px;">
        <TitleWrapper>
          <BackButton defaultLink={routes.BATCHES} />
          <MainHeader>Spend Detail</MainHeader>
        </TitleWrapper>
        <SpendDetailsToolbar />
      </PageHeaderContainer>
      <DefaultBodyWrapperS>
        <TableWrapper>
          <SpendDetailsTable />
        </TableWrapper>
      </DefaultBodyWrapperS>
    </>
  );
}
