import React from 'react';
import styled from 'styled-components';
import { Button, ModalActions } from '@poly/admin-book';
import { bool, func, number, string } from 'prop-types';

const Container = styled.div`
  width: 400px;
`;

const CancelBtn = styled(Button)`
  margin-right: 20px;
`;

export function ConfirmInvoiceSaveModalC({
  invoiceNumber,
  onConfirm,
  onCancel,
  isMutating,
  invoiceDuplicatesCount,
}) {
  return (
    <Container>
      <p>
        Invoice #<b>{` ${invoiceNumber} `}</b>
        {`is already used for selected supplier in
      ${invoiceDuplicatesCount} invoice(s)`}
      </p>
      <p>Are you sure you want to save?</p>
      <ModalActions>
        <CancelBtn disabled={isMutating} onClick={onCancel} size="small">
          No
        </CancelBtn>
        <Button
          size="small"
          styleType="basicSecondary"
          disabled={isMutating}
          loader={isMutating}
          onClick={onConfirm}
        >
          Yes
        </Button>
      </ModalActions>
    </Container>
  );
}

ConfirmInvoiceSaveModalC.propTypes = {
  invoiceNumber: string.isRequired,
  onConfirm: func.isRequired,
  onCancel: func.isRequired,
  isMutating: bool.isRequired,
  invoiceDuplicatesCount: number.isRequired,
};
