import styled from 'styled-components';
import { Table, moneyColumnStyles } from '@poly/admin-book';

export const SupplierTaxReportTableC = styled(Table)`
  th:nth-child(1),
  td:nth-child(1) {
    width: 35%;
  }

  th:nth-child(2),
  td:nth-child(2) {
    width: 10%;
  }

  th:nth-child(3),
  td:nth-child(3) {
    width: 30%;
  }

  th:nth-child(4),
  td:nth-child(4) {
    width: 10%;
  }

  ${moneyColumnStyles(5)};
`;
