import {
  USER_SEARCH_CHANGED,
  useSearchQueryWithPagination,
} from '@poly/admin-ui';
import { gql } from '@apollo/client';
import {
  CLIENT_APP_AIT,
  TIAA_PORTAL_APP_NAME,
  getSearchAccessItemsQueryByAIT,
} from '@poly/security';
import * as R from 'ramda';

const SEARCH_USERS = gql`
  query SEARCH_USERS($searchInput: CollectionSearchParams!) {
    searchUsers(input: $searchInput) {
      hits {
        _id
        fullName
        email
        status
      }
      total
    }
  }
`;

const query = getSearchAccessItemsQueryByAIT(
  CLIENT_APP_AIT,
  [],
  TIAA_PORTAL_APP_NAME,
);

export const useUserManagementQuery = (sortQuery) => {
  const { data, loading } = useSearchQueryWithPagination(
    SEARCH_USERS,
    USER_SEARCH_CHANGED,
    {
      queryName: 'searchUsers',
      fetchPolicy: 'network-only',
      elasticQuery: query,
      sortQuery,
    },
  );

  const users = R.pathOr([], ['searchUsers', 'hits'], data);

  return {
    users,
    loading,
  };
};
