import * as R from 'ramda';
import { applySpecWithFields, removePropDeep } from '@poly/utils';
import { invoiceDetailsFromInvoice } from '../../InvoicePage/common/utils.js';

export const getInitialFormData = (createdSupplier) =>
  R.applySpec({
    _id: R.prop('_id'),
    invoiceNumber: R.prop('invoiceNumber'),
    supplierId: R.ifElse(
      () => R.prop('supplierId', createdSupplier),
      () => R.prop('supplierId', createdSupplier),
      R.pathOr('', ['supplier', '_id']),
    ),
    propertyId: R.pathOr('', ['property', '_id']),
    lines: R.compose(
      R.map(
        R.pipe(
          applySpecWithFields({
            serviceTypeId: R.path(['serviceType', '_id']),
          }),
          R.omit(['serviceType']),
        ),
      ),
      removePropDeep('__typename'),
      R.prop('lines'),
    ),
    supplierOuterContractId: R.prop('supplierOuterContractId'),
    invoiceDetails: invoiceDetailsFromInvoice,
  });

// unsetSupplierOuterContractId :: UpdateOCRInvoiceInput -> UpdateOCRInvoiceInput
export const unsetSupplierOuterContractId = R.over(
  R.lensProp('supplierOuterContractId'),
  R.when(R.either(R.isEmpty, R.isNil), R.always(null)),
);

// prepareInvoiceDataToVerify :: Object -> UpdateOCRInvoiceInput
export const prepareInvoiceDataToVerify = R.compose(
  unsetSupplierOuterContractId,
  R.omit(['invoiceDetails', 'total', '_id']),
);
