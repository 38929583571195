import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { bool, func, object, oneOfType, string } from 'prop-types';
import { ModalActions, Text } from '@poly/admin-book';
import { CancelModalFormBtn, SubmitBtn } from '../buttons/ActionButtons.js';

const ModalWrapper = styled.div`
  width: ${R.propOr('600px', 'width')};
`;

export function ModalFormWrapper({
  actionInProgress,
  title,
  buttonLabel,
  formId,
  Form,
  width,
  ...props
}) {
  return (
    <ModalWrapper width={width}>
      <Text size="20px">{title}</Text>
      <Form {...{ ...props, formId }} />
      <ModalActions>
        <CancelModalFormBtn formId={formId} disabled={actionInProgress} />
        <SubmitBtn
          disabled={actionInProgress}
          loader={actionInProgress}
          form={formId}
        >
          {buttonLabel}
        </SubmitBtn>
      </ModalActions>
    </ModalWrapper>
  );
}

ModalFormWrapper.propTypes = {
  actionInProgress: bool.isRequired,
  title: string.isRequired,
  buttonLabel: string.isRequired,
  formId: string.isRequired,
  Form: oneOfType([func, object]).isRequired,
  width: string,
};
