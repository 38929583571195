import React from 'react';
import { Loader } from '@poly/admin-book';
import { prepareSupplierForDisplaying } from './utils.js';
import { SupplierAccountTabComp } from './SupplierAccountTabC.js';
import { useSupplierAccountSubscription } from '../apollo/useSupplierAccount.js';

export function SupplierAccountInfo() {
  const { result } = useSupplierAccountSubscription();

  if (result.loading) return <Loader />;

  const commonProps = prepareSupplierForDisplaying(result);

  return <SupplierAccountTabComp {...commonProps} />;
}

SupplierAccountInfo.displayNAme = 'SupplierAccountInfo';
