import * as R from 'ramda';
import { useProcessState } from '@poly/admin-ui/src/index.js';
import { processes } from '../../../../state/modules/process.js';
import { getLinesTotal } from '../../InvoicePage/common/utils.js';

// getTotalValue :: Object -> Number
const getTotalValue = R.compose(getLinesTotal, R.path(['formData', 'lines']));

export const useInvoiceFooterLogic = (componentProps) => {
  const { process } = useProcessState(processes.INVOICE_SAVING);

  return {
    status: componentProps?.formData.invoiceDetails.status,
    total: getTotalValue(componentProps),
    _id: componentProps.formData.invoiceDetails._id,
    loading: process,
    addItem: () => componentProps.mutators.push('lines', {}),
  };
};
