import React from 'react';
import { formatSelectOptionsWithTitleCased } from '@poly/client-utils';
import { propertyOwnershipTypes } from '@poly/constants';
import { Select } from '@poly/admin-book';

export function PropertyOwnershipSelect(props) {
  const options = formatSelectOptionsWithTitleCased(propertyOwnershipTypes);

  const selectProps = {
    ...props,
    options,
    name: 'PropertyOwnershipSelect',
  };

  return <Select {...selectProps} />;
}

PropertyOwnershipSelect.displayName = 'PropertyOwnershipSelect';
