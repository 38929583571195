import React from 'react';
import * as R from 'ramda';
import { useDispatch } from 'react-redux';
import { arrayOf, bool, string } from 'prop-types';
import { useSetDefaultValueToURL } from '@poly/client-utils';
import { OCRInvoiceStatuses } from '@poly/constants';
import { Select } from '@poly/admin-book';
import { ofArrayLegacy } from '@poly/utils';

import { ALL } from '../../utils/constants/general.js';
import { uiInvoiceStatuses } from '../../utils/constants/statuses.js';
import { setSelectedRows } from '../../state/modules/selected-rows.js';

const financialStatuses = {
  pending: 'Pending',
  blocked: 'Blocked',
  approved: 'Approved',
  paid: 'Paid',
};

// [review status, user friendly status, financial status]
const invoiceStatusesMap = {
  [OCRInvoiceStatuses.VOID]: ['Void', financialStatuses.pending],
  [OCRInvoiceStatuses.XTRACTA_EXPORT]: [
    'Xtracta export',
    financialStatuses.pending,
  ],
  [OCRInvoiceStatuses.SCANNING]: ['Scanning', financialStatuses.pending],
  [OCRInvoiceStatuses.NEW]: ['New', financialStatuses.pending],
  [OCRInvoiceStatuses.OCR_READY]: ['OCR ready', financialStatuses.pending],
  [OCRInvoiceStatuses.OCR_ERROR]: ['OCR error', financialStatuses.pending],
  [OCRInvoiceStatuses.VERIFIED]: ['Verified', financialStatuses.pending],
  [OCRInvoiceStatuses.BLOCKED]: ['Blocked', financialStatuses.blocked],
  [OCRInvoiceStatuses.PENDING]: ['Pending', financialStatuses.pending],
  [OCRInvoiceStatuses.EXCEPTION]: ['Exception', financialStatuses.pending],
  [OCRInvoiceStatuses.APPROVED]: ['Approved', financialStatuses.approved],
  [OCRInvoiceStatuses.PAID]: ['Paid', financialStatuses.paid],
};

// getFinancialStatusByInvoice :: String -> String
const getFinancialStatusByInvoice = R.compose(
  R.last,
  R.propOr([], R.__, invoiceStatusesMap),
);

// getFinancialStatusByInvoice :: String -> String
const getFinancialStatusOptionsByInvoice = R.compose(
  R.map(R.applySpec({ value: R.nth(1), label: R.nth(0) })),
  ofArrayLegacy,
  R.propOr([], R.__, invoiceStatusesMap),
);

// getOCRInvoiceStatusOptions :: ([String], Boolean) -> [Option]
const getOCRInvoiceStatusOptions = (filterOptions, includeAllOption) =>
  R.compose(
    R.map(R.applySpec({ value: R.nth(0), label: R.nth(1) })),
    R.when(R.always(includeAllOption), R.prepend([ALL, 'All'])),
    R.toPairs,
    R.omit(filterOptions),
  )(uiInvoiceStatuses);

export function OCRInvoiceStatusSelect({
  omitOptions = [],
  includeAllOption = false,
  ...restProps
}) {
  const selectProps = {
    ...restProps,
    name: 'OCRInvoiceStatusSelect',
    options: getOCRInvoiceStatusOptions(omitOptions, includeAllOption),
  };

  return <Select {...selectProps} />;
}

OCRInvoiceStatusSelect.displayName = 'OCRInvoiceStatusSelect';

OCRInvoiceStatusSelect.propTypes = {
  includeAllOption: bool,
  omitOptions: arrayOf(string),
};

export function OCRInvoiceFinancialStatusDisabledSelect({
  value,
  ...restProps
}) {
  const selectProps = {
    ...restProps,
    disabled: true,
    name: 'OCRInvoiceStatusSelect',
    value: getFinancialStatusByInvoice(value),
    options: getFinancialStatusOptionsByInvoice(value),
  };

  return <Select {...selectProps} />;
}

OCRInvoiceFinancialStatusDisabledSelect.displayName =
  'OCRInvoiceFinancialStatusDisabledSelect';

OCRInvoiceFinancialStatusDisabledSelect.propTypes = {
  value: string,
};

export function OCRInvoiceStatusSelectWithRouter() {
  const dispatch = useDispatch();

  const { updateQueryParams, routerQuery } =
    useSetDefaultValueToURL('OCRInvoiceStatus');

  const onChange = (value) => {
    updateQueryParams({ OCRInvoiceStatus: value });
    dispatch(setSelectedRows([]));
  };

  const value = routerQuery?.OCRInvoiceStatus || '';

  return (
    <OCRInvoiceStatusSelect
      required
      value={value}
      width="200px"
      includeAllOption
      onChange={onChange}
      omitOptions={[OCRInvoiceStatuses.VOID]}
    />
  );
}

OCRInvoiceStatusSelectWithRouter.displayName =
  'OCRInvoiceStatusSelectWithRouter';
