import React from 'react';
import { Input, Checkbox } from '@poly/admin-book';
import { shape, string } from 'prop-types';
import {
  InvoiceFooter,
  InvoiceInfoComp,
  InvoiceLineField,
  LinesHeader,
  SupplierSelectWithBtnComp,
} from './components.js';
import { useInvoiceFooterLogic } from './useInvoiceFooterLogic.js';
import { OCRInvoicePropertySelect } from '../../../property/PropertySelect/PropertySelect.js';
import {
  invoiceNumberRules,
  lineFieldValidationRules,
  validateLineField,
} from '../../../../utils/forms/validators.js';
import { InvoiceNumberInput } from './InvoiceNumberInput.js';
import {
  PropertyAddressComp,
  SupplierAddressComp,
} from '../../InvoicePage/InvoiceEditForm/sections.js';

function InvoiceFooterComp(props) {
  const footerProps = useInvoiceFooterLogic(props);

  return <InvoiceFooter {...footerProps} />;
}

function InvoiceInfoCompC({ value }) {
  return <InvoiceInfoComp {...value} />;
}

InvoiceInfoCompC.propTypes = {
  value: shape({
    invoiceId: string.isRequired,
    invoiceNumber: string.isRequired,
    invoiceDate: string.isRequired,
    invoiceFileLink: string.isRequired,
  }),
};

export const verifyInvoiceFormSection = [
  {
    id: 'mainSection',
    order: 3,
    layout: {
      column: 1,
      height: '100%',
      margin: 0,
    },
    fields: [
      {
        id: 'invoiceDetails',
        layout: { row: 1 },
        field: {
          name: 'invoiceDetails',
          withFormData: true,
          Component: InvoiceInfoCompC,
        },
      },
      {
        label: 'Property',
        layout: {
          row: 2,
          padding: '0 0 10px 0',
          width: '44%',
          label: { position: 'left', width: '100px' },
        },
        field: {
          name: 'propertyId',
          withFormData: true,
          Component: OCRInvoicePropertySelect,
        },
        required: true,
      },
      {
        id: 'propertyId',
        layout: { row: 2, padding: '0 0 10px 0', width: '54%' },
        field: {
          name: 'propertyId',
          withFormData: true,
          Component: PropertyAddressComp,
        },
      },
      {
        label: 'Supplier',
        layout: {
          row: 3,
          padding: '0 0 10px 0',
          width: '44%',
          label: { position: 'left', width: '100px' },
        },
        field: {
          name: 'supplierId',
          withFormData: true,
          Component: SupplierSelectWithBtnComp,
        },
        required: true,
      },
      {
        id: 'supplierId',
        layout: { row: 3, padding: '0 0 10px 0', width: '54%' },
        field: {
          name: 'supplierId',
          withFormData: true,
          Component: SupplierAddressComp,
        },
      },
      {
        label: 'Account Number',
        layout: {
          row: 4,
          padding: '0 0 10px 0',
          width: '44%',
          label: { position: 'left', width: '100px' },
        },
        field: {
          name: 'supplierOuterContractId',
          Component: Input,
        },
      },
      {
        label: 'Invoice Number',
        layout: {
          row: 4,
          width: '54%',
          label: { position: 'left', width: '100px' },
        },
        field: {
          name: 'invoiceNumber',
          withFormData: true,
          Component: InvoiceNumberInput,
        },
        validators: invoiceNumberRules,
      },
      {
        label: ' ',
        layout: {
          row: 5,
          padding: '0 0 20px 0',
          width: '44%',
          label: { position: 'left', width: '100px' },
        },
        field: {
          name: 'isNotRecurringInvoice',
          Component: (props) => (
            <Checkbox {...props} label="This is not recurring invoice" />
          ),
        },
      },
      {
        id: 'lines',
        order: 1,
        label: <LinesHeader />,
        layout: { row: 6, flex: '1 5', overflow: 'auto' },
        field: {
          name: 'lines',
          withFormData: true,
          isArrayField: true,
          Component: InvoiceLineField,
        },
        validateFunction: validateLineField,
        validators: lineFieldValidationRules,
      },
      {
        id: 'totalInfo',
        order: 1,
        layout: { row: 7, padding: 0 },
        field: {
          name: 'lines',
          withFormData: true,
          Component: InvoiceFooterComp,
        },
      },
    ],
  },
];
