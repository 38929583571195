import React from 'react';
import { string, shape } from 'prop-types';
import styled from 'styled-components';
import { getThemeFont, getThemeColor, Loader } from '@poly/admin-book';

import { withGetOpenModal } from '../../state/hocs.js';

const ModalContentS = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ModalText = styled.div`
  font-weight: ${getThemeFont(['regular'])};
  font-size: 14px;
  line-height: 1.5;
  color: ${getThemeColor(['newPrimary'])};
`;

const LoaderS = styled(Loader)`
  position: relative;
  left: 0;
  top: 0;
  margin-bottom: 25px;

  circle {
    stroke: ${getThemeColor(['newPrimary'])};
  }
`;

export const OpenXtractaModal = withGetOpenModal(({ payload }) => (
  <ModalContentS>
    <LoaderS size={50} />
    <ModalText>
      {payload}
      ... Please stand by
    </ModalText>
  </ModalContentS>
));

OpenXtractaModal.propTypes = {
  openModal: shape({ payload: string }),
};
