import * as R from 'ramda';
import { nullToUndefinedDeep, propEqLegacy } from '@poly/utils';
import { BooleanTextWithDot as BooleanTextWithDotImp } from '@poly/admin-book';
import { pathOrNothingUI } from '@poly/client-utils';
import {
  getPhoneByType as getPhoneByTypeImp,
  supplierTaxClassesUI,
  getActiveOfficeHours,
  isNullRemark,
} from '@poly/admin-ui';
import {
  supplierEmailsTypes,
  supplierPhoneTypes,
  supplierFinTermsUI,
  NOTHING_UI_STRING,
} from '@poly/constants';

export const BooleanTextWithDot = BooleanTextWithDotImp;

const runIfExistOrDash = R.curry((func, path) =>
  R.pipe(R.path(path), R.ifElse(R.isNil, R.always(NOTHING_UI_STRING), func)),
);

const getEmailByType = (emails, type) =>
  R.pipe(
    R.find(propEqLegacy('type', type)),
    pathOrNothingUI(['email']),
  )(emails);

/**
 * getPhoneByType :: String -> [Object] -> String
 */
const getPhoneByType = getPhoneByTypeImp;

/**
 * setDefaultRemarks :: Supplier -> Any
 */
const setDefaultRemarksIfEmpty = R.compose(
  R.when(R.either(R.isNil, isNullRemark), R.always('')),
  R.prop('remarks'),
);

export const prepareSupplierForDisplaying = R.pipe(
  R.evolve({ supplier: nullToUndefinedDeep }),
  ({ supplier }) => {
    const { emails, phones = [], address, addressTwo } = supplier.company;
    return {
      _id: supplier._id,
      type: supplier.type,
      generalInfo: {
        name: pathOrNothingUI(['company', 'name'], supplier),
        dba: R.path(['company', 'dba'], supplier),
        fax: R.path(['company', 'fax'], supplier),
        phone: getPhoneByType(supplierPhoneTypes.GENERAL, phones),
        address,
        addressTwo,
        emails: {
          [supplierEmailsTypes.GENERAL]: getEmailByType(
            emails,
            supplierEmailsTypes.GENERAL,
          ),
          [supplierEmailsTypes.ACCOUNT]: getEmailByType(
            emails,
            supplierEmailsTypes.ACCOUNT,
          ),
          [supplierEmailsTypes.SERVICE]: getEmailByType(
            emails,
            supplierEmailsTypes.SERVICE,
          ),
        },
        website: pathOrNothingUI(['company', 'website'], supplier),
        service_24_7: supplier.service_24_7,
        services: R.path(['company', 'services'], supplier),
        masterSupplier: R.prop('masterSupplier', supplier),
        officeHours: getActiveOfficeHours(supplier),
      },

      remarks: setDefaultRemarksIfEmpty(supplier),

      remitInfo: {
        name: pathOrNothingUI(['remit', 'name'], supplier),
        address: R.pathOr({}, ['remit', 'address'], supplier),
        addressTwo: R.path(['remit', 'addressTwo'], supplier),
      },

      financialInfo: {
        terms: runIfExistOrDash(R.prop(R.__, supplierFinTermsUI), [
          'finance',
          'terms',
        ])(supplier),
        acceptsCreditCard: R.pathOr(false, ['finance', 'acceptsCreditCard'])(
          supplier,
        ),
        is1099: R.pathOr(false, ['tax', 'is1099'])(supplier),
        exempt: R.path(['tax', 'exempt'])(supplier),
        w9FileName: pathOrNothingUI(['tax', 'w9File', 'fileName'], supplier),
        w9FileUrl: pathOrNothingUI(['tax', 'w9File', 'url'], supplier),
        taxId: pathOrNothingUI(['tax', 'id'], supplier),
        socialSecurityNumber: pathOrNothingUI(
          ['tax', 'socialSecurityNumber'],
          supplier,
        ),
        taxAddress1: R.pathOr({}, ['tax', 'address'], supplier),
        taxAddress2: pathOrNothingUI(['tax', 'addressTwo'], supplier),
        taxClass: runIfExistOrDash(R.prop(R.__, supplierTaxClassesUI), [
          'tax',
          'classification',
        ])(supplier),
        taxName: pathOrNothingUI(['tax', 'name'], supplier),
      },

      bankACH: {
        routingNumber: pathOrNothingUI(['bank', 'routingNumber'], supplier),
        accountingNumber: pathOrNothingUI(['bank', 'accountNumber'], supplier),
        remittanceEmail: pathOrNothingUI(['bank', 'remittanceEmail'], supplier),
        payByACH: R.pathOr(false, ['bank', 'payByACH'])(supplier),
      },

      documentsInfo: {
        ...(supplier.documents || {}),
      },
      ratesInfo: {
        ...(supplier.rates || {}),
      },
      mwbe: R.compose(R.defaultTo({}), R.path(['mwbe']))(supplier),
    };
  },
);
