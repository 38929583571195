import React from 'react';
import * as R from 'ramda';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { DESC_SORT_ORDER } from '@poly/constants';
import {
  TableCardWithPaginationContainer,
  usePagination,
  useSetInitialPaginationOnMount,
  useSetItemsCount,
  useTableSorting,
  useMapConfigToTableProps,
} from '@poly/admin-ui';
import {
  commonSortQuery,
  keywordSortQuery,
  listLengthSortQuery,
  formatDatePath,
  formatDateProp,
  useEntitiesByReactiveSearch,
  useSubscriptionByChanges,
} from '@poly/client-utils';
import { moneyColumnStyles, Status, Table, Loader } from '@poly/admin-book';
import { formatCurrencyPath } from '@poly/utils';
import { objectOf, string } from 'prop-types';
import { uiAndRealAACBatchStatusesRel } from '../../utils/constants/statuses.js';
import { BatchLink } from '../../containers/links/Links.js';
import { BATCHES_QUERY, BATCHES_SUBS } from './apollo/useBatchInvoices.js';
import { OCR_INVOICES_BY_SEARCH_SUB } from '../invoice/apollo/useOcrInvoicesBySearch.js';
import { getBatchStatusColorAndText } from '../../utils/statuses.js';

const TableS = styled(Table)`
  min-width: 1024px;

  th:nth-child(2),
  td:nth-child(2) {
    text-align: right;
  }

  th:nth-child(2) {
    padding-right: 7%;
  }

  td:nth-child(2) {
    padding-right: 7.5%;
  }

  ${moneyColumnStyles(3)};

  @media (max-width: 1500px) {
    th:nth-child(2) {
      padding-right: 5%;
    }

    td:nth-child(2) {
      padding-right: 5.5%;
    }
  }
`;

function BatchesTableC(props) {
  return (
    <TableCardWithPaginationContainer>
      <TableS {...props} />
    </TableCardWithPaginationContainer>
  );
}

const invoicesCount = R.pipe(R.prop('documentIds'), R.length);
const approvedAtPs = ['workflow', 'approvedAt'];
const fundedAtPs = ['workflow', 'xeroFundedAt'];
const totalPs = ['workflow', 'total'];

function BatchStatus({ batchUIStatuses, ...restProps }) {
  const colorProps = R.pipe(
    R.path(['workflow', 'status']),
    getBatchStatusColorAndText(batchUIStatuses),
  )(restProps);

  return <Status {...restProps} {...colorProps} />;
}

BatchStatus.propTypes = {
  batchUIStatuses: objectOf(string),
};

const tableConfig = (batchUIStatuses) => [
  ['Batch ID', BatchLink, keywordSortQuery(['batchId'])],
  ['Invoices', invoicesCount, listLengthSortQuery(['documentIds'])],
  ['Amount', formatCurrencyPath(totalPs), commonSortQuery(totalPs)],
  ['Created', formatDateProp('createdAt'), commonSortQuery(['createdAt'])],
  ['Approved', formatDatePath(approvedAtPs), commonSortQuery(approvedAtPs)],
  ['Funded', formatDatePath(fundedAtPs), commonSortQuery(fundedAtPs)],
  [
    'Status',
    (props) => <BatchStatus {...props} batchUIStatuses={batchUIStatuses} />,
    commonSortQuery(['workflow', 'status']),
  ],
];

const useBatchesBySearch = (sort) => {
  const { pagination } = usePagination();
  const searchText = useSelector((state) => state.searchText);

  const { loading, refetch, result } = useEntitiesByReactiveSearch({
    gqlSearchQuery: BATCHES_QUERY,
    gqlSearchChangedQuery: BATCHES_SUBS,
    sort,
    pagination,
    searchText,
    alias: 'useBatchesBySearch',
  });

  useSubscriptionByChanges({
    gqlQueryChanged: OCR_INVOICES_BY_SEARCH_SUB,
    refetch,
    result,
  });

  return {
    loading,
    refetch,
    result,
  };
};

// getBatchesTotal :; SearchBatchesQueryResult -> Number
const getBatchesTotal = R.pathOr(0, ['searchBatches', 'total']);

// getBatches :; SearchBatchesQueryResult -> [Batch]
const getBatches = R.pathOr([], ['searchBatches', 'hits']);

export function BatchesTable() {
  useSetInitialPaginationOnMount();

  const { sort, ...tableSortingProps } = useTableSorting({
    tableConfig: tableConfig(uiAndRealAACBatchStatusesRel),
    column: 4,
    order: DESC_SORT_ORDER,
  });

  const { loading, result } = useBatchesBySearch(sort);

  useSetItemsCount(getBatchesTotal, result);

  const tableProps = useMapConfigToTableProps(
    getBatches,
    tableConfig(uiAndRealAACBatchStatusesRel),
    result,
  );

  if (loading) return <Loader />;

  return <BatchesTableC {...tableSortingProps} {...tableProps} />;
}

BatchesTable.displayName = 'BatchesTable';
