import React from 'react';
import styled from 'styled-components';
import { TableCardWithPaginationContainer } from '@poly/admin-ui';
import { Table, moneyColumnStyles } from '@poly/admin-book';

const SpendDetailsTableC = styled(Table)`
  padding: 30px 0 15px 0;

  ${moneyColumnStyles(8)};
`;

export function SpendDetailsTable(props) {
  return (
    <TableCardWithPaginationContainer>
      <SpendDetailsTableC {...props} />
    </TableCardWithPaginationContainer>
  );
}
