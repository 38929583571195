import { useRouterParams } from '@poly/client-routing';
import { gql, useQuery } from '@apollo/client';

const OCR_INVOICE_DETAILS_FOR_EDIT_FORM = gql`
  query OCR_INVOICE_DETAILS_FOR_EDIT_FORM($invoiceId: ID!) {
    ocrInvoice(id: $invoiceId) {
      _id
      invoiceNumber
      invoiceDate
      total
      invoiceFileLink
      supplierOuterContractId
      isNotRecurringInvoice
      receiveDate
      dueDate
      invoiceId
      invoiceFileLink
      manager {
        _id
        fullName
      }
      lines {
        lineNumber
        price
        quantity
        description
        measure
        subtotal
        serviceType {
          _id
          name
          psCategoryId
          tiaaCategoryName
        }
      }
      workflow {
        ... on TiaaInvoiceWorkflow {
          check {
            _id
            checkNumber
            issuanceDate
          }
          status
          unvalidatedData {
            supplierAddress
            propertyAddress
          }
        }
      }
      supplier {
        _id
        company {
          name
          emails {
            email
            type
          }
          address {
            formatted_address
          }
        }
      }
      property {
        _id
        name
        address {
          formatted_address
        }
      }
    }
  }
`;

export const useOcrInvoiceDetailsForEditForm = () => {
  const { invoiceId } = useRouterParams(['invoiceId']);

  const { data, loading } = useQuery(OCR_INVOICE_DETAILS_FOR_EDIT_FORM, {
    variables: { invoiceId },
    fetchPolicy: 'network-only',
    skip: !invoiceId,
    alias: 'useOcrInvoiceDetailsForEditForm',
  });

  return {
    data,
    loading,
  };
};
