import * as R from 'ramda';
import { pathEqLegacy } from '@poly/utils';

import { routes } from '../../../../routes/constants.js';

export const getPreviousLink = R.ifElse(
  pathEqLegacy(['previous', 'route'], routes.INVOICE_VERIFY),
  R.always(routes.QUEUE),
  R.pathOr(routes.BATCHES, ['previous', 'pathname']),
);
