import React from 'react';
import downloadFile from 'downloadjs';
import { useOnSubmitSetStopSubmitting, useProcessState } from '@poly/admin-ui';
import { LinkButtonWithLoader, useNotificationContext } from '@poly/admin-book';
import { gql, useMutation } from '@apollo/client';
import { useRouterParams } from '@poly/client-routing';

const GENERATE_CHECK_PAYMENT_FILE_MUTATION = gql`
  mutation GENERATE_CHECK_PAYMENT_FILE_MUTATION($batchId: ID!) {
    generateBatchCheckPaymentFile(batchId: $batchId)
  }
`;

const useGenerateCheckPaymentFile = () => {
  const [mutate] = useMutation(GENERATE_CHECK_PAYMENT_FILE_MUTATION);

  return {
    generateBatchCheckPaymentFile: (batchId) =>
      mutate({
        variables: {
          batchId,
        },
      }),
  };
};

const generateCheckPaymentFileProcessId = 'generateCheckPaymentFileProcessId';

export function CheckPaymentFileBtn() {
  const { batchId } = useRouterParams(['batchId']);

  const { process } = useProcessState(generateCheckPaymentFileProcessId);

  const { generateBatchCheckPaymentFile } = useGenerateCheckPaymentFile();

  const { showSuccessNotification } = useNotificationContext();

  const onSubmitHandler = async () => {
    const { data } = await generateBatchCheckPaymentFile(batchId);

    downloadFile(data?.generateBatchCheckPaymentFile);
    showSuccessNotification('Check payment file has been attached to batch');
  };

  const { onSubmit } = useOnSubmitSetStopSubmitting(
    generateCheckPaymentFileProcessId,
    onSubmitHandler,
  );

  return (
    <LinkButtonWithLoader
      id={generateCheckPaymentFileProcessId}
      onClick={onSubmit}
      loader={process}
    >
      Check Payment File
    </LinkButtonWithLoader>
  );
}

CheckPaymentFileBtn.displayName = 'CheckPaymentFileBtn';
