import * as R from 'ramda';
import { gql, useQuery } from '@apollo/client';
import { READ_RECURRING_INVOICE_PERMISSION } from '@poly/security';
import {
  useHasUserAccessWithPermission,
  useSubscriptionByChanges,
} from '@poly/client-utils';

import { OCR_INVOICES_BY_SEARCH_SUB } from '../invoice/apollo/useOcrInvoicesBySearch.js';

const missingOCRInvoicesQuery = gql`
  query MISSING_OCR_INVOICES_QUERY {
    missingOCRInvoicesReport {
      expectedDates
    }
  }
`;

const getAdditionalCountProps = R.compose(
  R.objOf('children'),
  R.reduce(R.add, 0),
  R.map(R.pathOr(0, ['expectedDates', 'length'])),
  R.propOr([], 'missingOCRInvoicesReport'),
);

export const useMissingOCRInvoicesCounter = () => {
  const userHasPermission = useHasUserAccessWithPermission(
    READ_RECURRING_INVOICE_PERMISSION,
  );

  const { data, refetch } = useQuery(missingOCRInvoicesQuery, {
    skip: !userHasPermission,
    alias: 'useMissingOCRInvoicesCounter',
  });

  useSubscriptionByChanges({
    gqlQueryChanged: OCR_INVOICES_BY_SEARCH_SUB,
    refetch,
    result: data,
    extractQueryParamsFromProps: R.always({}),
    skipQuery: !userHasPermission,
  });

  return getAdditionalCountProps(data);
};
