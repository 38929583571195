import React from 'react';
import { bool, func } from 'prop-types';
import { Select } from '@poly/admin-book';

export function Is1099Select(props) {
  const is1099SelectOptions = [
    { value: false, label: 'All Suppliers' },
    { value: true, label: '1099 Suppliers' },
  ];

  const is1099SelectProps = {
    ...props,
    width: '170px',
    name: 'Is1099Select',
    dataTestId: '1099-select',
    options: is1099SelectOptions,
    placeholder: 'Select tax type',
  };

  return <Select {...is1099SelectProps} />;
}

Is1099Select.propTypes = {
  value: bool,
  onChange: func.isRequired,
  required: bool,
};
