import * as R from 'ramda';
import { string } from 'prop-types';
import React, { useMemo } from 'react';
import { useHasUserAccessToSupplierPrivateFields } from '@poly/client-utils';
import { supplierTypes } from '@poly/constants';
import {
  MasterSupplierInfoGeneralSection,
  useMasterSupplierDetailsQuery,
  prepareMasterSupplierDetails,
  SupplierDocumentsInfoSection,
  SupplierBankACHInfoSection,
  SupplierOfficeHoursSection,
  SupplierRemitInfoSection,
  SupplierRatesInfoSection,
  RemarksSection,
} from '@poly/admin-ui';
import {
  SectionsWrapper,
  CardsWrapper,
  SideSection,
  Loader,
} from '@poly/admin-book';

import { FinancialSection } from './FinancialSection.js';

export function MasterSupplierInfoTab({ supplierId }) {
  const { data, loading } = useMasterSupplierDetailsQuery(
    supplierId,
    'network-only',
  );

  const { hasUserTaxPrivateFieldsAccess, hasUserBankPrivateFieldsAccess } =
    useHasUserAccessToSupplierPrivateFields();

  const type = useMemo(() => R.path(['masterSupplier', 'type'], data), [data]);

  const { bank, rates, remarks, general, financial, documents, remit } =
    useMemo(() => prepareMasterSupplierDetails(data), [data]);

  if (loading) {
    return <Loader />;
  }

  return (
    <CardsWrapper>
      <SectionsWrapper>
        <SideSection>
          <MasterSupplierInfoGeneralSection {...general} />
          <SupplierOfficeHoursSection {...general} />
          {type === supplierTypes.SUBCONTRACTOR && (
            <SupplierRatesInfoSection {...rates} />
          )}
        </SideSection>
        <SideSection>
          <RemarksSection remarks={remarks} />
          <SupplierRemitInfoSection {...remit} />
          <FinancialSection
            {...financial}
            hasUserTaxPrivateFieldsAccess={hasUserTaxPrivateFieldsAccess}
            isMaster
          />
          <SupplierBankACHInfoSection
            {...bank}
            hasUserBankPrivateFieldsAccess={hasUserBankPrivateFieldsAccess}
          />
          {type === supplierTypes.SUBCONTRACTOR && (
            <SupplierDocumentsInfoSection {...documents} />
          )}
        </SideSection>
      </SectionsWrapper>
    </CardsWrapper>
  );
}

MasterSupplierInfoTab.propTypes = {
  supplierId: string.isRequired,
};
