import React from 'react';
import { ChangePasswordPageBaseAdmin } from '@poly/admin-ui';
import { routes } from '../routes/constants.js';
import { APP_TITLE } from './loginPage.js';

export function ResetPasswordPage() {
  return (
    <ChangePasswordPageBaseAdmin
      title={APP_TITLE}
      homeUrl={routes.BATCHES}
      forgotPasswordUrl={routes.FORGOT_PASSWD}
    />
  );
}
