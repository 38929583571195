import { FORGOT_PASSWORD_ROUTE } from '@poly/constants';

export const routes = {
  BATCHES: '/',
  QUEUE: '/queue',
  APPROVAL: '/approval',
  MISSING_INVOICES: '/missing-invoices',
  RECURRING_INVOICES: '/recurring-invoices',
  REPORT_1099: '/report-1099',
  SUPPLIER_TAX: '/supplier-tax',
  PROPERTIES: '/properties',
  SUPPLIERS: '/suppliers',
  SUPPLIER: '/supplier/:supplierId',
  BATCH: '/batch/:batchId',
  INVOICE: '/invoice/:invoiceId',
  INVOICE_VERIFY: '/invoice/:invoiceId/verify',
  SPEND_DETAIL: '/spend-detail',
  LOGIN: '/auth/login',
  FORGOT_PASSWD: FORGOT_PASSWORD_ROUTE,
  RESET_PASSWD: '/auth/set-password/:token',
  PROPERTY: '/property/:propertyId',
  SERVICE_TYPES: '/service-types',
  CONTACT: '/contact/:contactId',
  ADD_PROPERTY: '/add-property',
  ADD_SUPPLIER: '/add-supplier',
  MASTER_SUPPLIER: '/master-supplier/:supplierId',
  ADD_MASTER_SUPPLIER: '/add-master-supplier',
  MASTER_SUPPLIERS: '/master-suppliers',
  ADD_BRANCH_SUPPLIER: '/master-branch-supplier',
  USER_MANAGEMENT: '/user-management',
  NOT_FOUND: '/*',
};

export const authRoutesList = [
  routes.LOGIN,
  routes.FORGOT_PASSWD,
  routes.RESET_PASSWD,
];
