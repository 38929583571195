import React from 'react';
import * as R from 'ramda';
import { useDispatch } from 'react-redux';
import { gql, useMutation } from '@apollo/client';
import { useNotificationContext, FormCreator, Select } from '@poly/admin-book';
import { formatSelectOptionsByConstants } from '@poly/client-utils';
import { usePristineSubscribe } from '@poly/client-routing';
import {
  useOnSubmitSetStopSubmitting,
  closeCurrentModal,
  useProcessState,
} from '@poly/admin-ui';

import { ModalFormWrapper } from '../../containers/modal/ModalWrapper.js';
import { uiAndRealAACBatchStatusesRel } from '../../utils/constants/statuses.js';
import { useBatchDetails } from './apollo/useBatchSubscription.js';

const UPDATE_BATCH_MUTATION = gql`
  mutation UPDATE_BATCH_MUTATION($input: UpdateBatchInput!) {
    updateBatch(input: $input) {
      batch {
        _id
      }
    }
  }
`;

const useUpdateBatch = () => {
  const [mutate] = useMutation(UPDATE_BATCH_MUTATION);

  return {
    updateBatch: (id, update) =>
      mutate({ variables: { input: { id, update } } }),
  };
};

function BatchStatusSelect(props) {
  const selectProps = {
    ...props,
    allowModalOverflow: true,
    name: 'BatchStatusSelect',
    options: formatSelectOptionsByConstants(uiAndRealAACBatchStatusesRel),
  };

  return <Select {...selectProps} />;
}

BatchStatusSelect.displayName = 'BatchStatusSelect';

const sections = [
  {
    label: '',
    order: 1,
    layout: { column: 1 },
    fields: [
      {
        label: 'Status',
        order: 1,
        field: {
          name: 'workflow.status',
          Component: BatchStatusSelect,
        },
        required: true,
      },
    ],
  },
];

// batchToEditForm :: Batch -> Object
const batchToEditForm = R.applySpec({
  workflow: {
    status: R.path(['batch', 'workflow', 'status']),
  },
});

const updateBatchFormId = 'updateBatchFormId';

function UpdateBatchForm(props) {
  const dispatch = useDispatch();

  const { data, batchId } = useBatchDetails();

  const { showSuccessNotification } = useNotificationContext();

  const { updateBatch } = useUpdateBatch();

  const onSubmitHandler = async (input) => {
    await updateBatch(batchId, input);

    showSuccessNotification('Batch has been updated');
    dispatch(closeCurrentModal());
  };

  const { onSubmit } = useOnSubmitSetStopSubmitting(
    updateBatchFormId,
    onSubmitHandler,
  );

  const subscriptionProps = usePristineSubscribe();

  return (
    <FormCreator
      {...props}
      {...subscriptionProps}
      onSubmit={onSubmit}
      sections={sections}
      id={updateBatchFormId}
      initialValues={batchToEditForm(data)}
      layout={{
        overflow: 'visible',
        card: false,
      }}
    />
  );
}

UpdateBatchForm.displayName = 'UpdateBatchForm';

export function UpdateBatchModalForm(props) {
  const { process } = useProcessState(updateBatchFormId);

  return (
    <ModalFormWrapper
      {...props}
      actionInProgress={!!process}
      title="Edit Batch"
      buttonLabel="Save"
      formId={updateBatchFormId}
      Form={UpdateBatchForm}
      width="500px"
    />
  );
}
