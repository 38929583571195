import * as R from 'ramda';
import { useSelector } from 'react-redux';
import { gql, useQuery } from '@apollo/client';
import { formatSocialSecurity, formatTaxId, ramdaUseWith } from '@poly/utils';
import { SupplierSources } from '@poly/constants';

import { propToTitleCase } from '../../../utils/general.js';
import { is1099ToString } from '../../../utils/reports.js';

const SUPPLIERS_TAX_REPORT_QUERY = gql`
  query SUPPLIERS_TAX_REPORT_QUERY($input: SupplierTaxReportInput!) {
    suppliersTaxReport(input: $input) {
      _id
      supplier {
        company {
          name
        }
        tax {
          is1099
          id
          socialSecurityNumber
          classification
          exempt
        }
      }
      compensation
    }
  }
`;

// matchAnyValue :: String -> SuppliersTaxReport -> Boolean
const matchAnyValue = ramdaUseWith(R.any, [
  R.compose(
    R.test,
    (search) => new RegExp(search, 'i'),
    String,
    R.defaultTo(''),
  ),
  R.compose(R.values, R.defaultTo({})),
]);

// prepareSupplierTaxReportData :: SupplierTaxReportResult -> [SuppliersTaxReport]
const prepareSupplierTaxReportData = (searchText) =>
  R.compose(
    R.filter(matchAnyValue(searchText)),
    R.map(
      R.applySpec({
        _id: R.prop('_id'),
        compensation: R.prop('compensation'),
        name: R.path(['supplier', 'company', 'name']),
        is1099: R.compose(is1099ToString, R.path(['supplier', 'tax'])),
        taxId: R.ifElse(
          R.path(['supplier', 'tax', 'id']),
          R.compose(formatTaxId, R.path(['supplier', 'tax', 'id'])),
          R.compose(
            formatSocialSecurity,
            R.pathOr('', ['supplier', 'tax', 'socialSecurityNumber']),
          ),
        ),
        companyType: R.compose(
          propToTitleCase('classification'),
          R.pathOr('', ['supplier', 'tax']),
        ),
        exempt: R.pathOr('', ['supplier', 'tax', 'exempt']),
      }),
    ),
    R.defaultTo([]),
    R.prop('suppliersTaxReport'),
  );

export const useSupplierTaxReportQuery = (year) => {
  const searchText = useSelector((state) => state.searchText);

  const { data, loading } = useQuery(SUPPLIERS_TAX_REPORT_QUERY, {
    variables: { input: { source: SupplierSources.TIAA, year } },
    fetchPolicy: 'network-only',
  });

  return { report: prepareSupplierTaxReportData(searchText)(data), loading };
};
