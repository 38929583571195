import React, { useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { useUserLogout, useCurrentUserQuery } from '@poly/client-utils';
import { firstLettersOfEachWord } from '@poly/utils';

import { routes } from '../../routes/constants.js';
import { UserPopUpC } from '../../components/appBar/userPopUp.js';

export function UserPopUp() {
  const client = useApolloClient();
  const logOutUser = useUserLogout();
  const [isOpen, setIsOpen] = useState(false);
  const {
    user: { fullName = ' ' },
  } = useCurrentUserQuery();

  const onLogout = () =>
    logOutUser({
      client,
      localStorage,
      loginRoute: routes.LOGIN,
    });

  const popUpProps = {
    isOpen,
    fullName,
    onLogout,
    close: () => setIsOpen(false),
    toggleOpen: () => setIsOpen(!isOpen),
    initials: firstLettersOfEachWord(fullName),
  };

  return <UserPopUpC {...popUpProps} />;
}

UserPopUp.displayName = 'UserPopUp';
