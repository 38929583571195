import React from 'react';
import { formatSelectOptionsWithTitleCased } from '@poly/client-utils';
import { propertyOccupancyTypes } from '@poly/constants';
import { Select } from '@poly/admin-book';

export function PropertyOccupancySelect(props) {
  const options = formatSelectOptionsWithTitleCased(propertyOccupancyTypes);

  const selectOptions = {
    ...props,
    options,
    name: 'PropertyOccupancySelect',
  };

  return <Select {...selectOptions} />;
}

PropertyOccupancySelect.displayName = 'PropertyOccupancySelect';
