import { useEntitiesByReactiveSearch } from '@poly/client-utils';
import { gql } from '@apollo/client';
import { useSelector } from 'react-redux';
import { usePagination } from '@poly/admin-ui';
import { OCR_INVOICES_BY_SEARCH_SUB } from '../apollo/useOcrInvoicesBySearch.js';
import { getSpendDetailQuery } from './spendDetailsUtils.js';

const spendReportsInvoicesFields = gql`
  fragment spendReportsInvoicesFields on OCRInvoice {
    _id
    invoiceId
    dueDate
    invoiceFileLink
    lines {
      serviceType {
        _id
        name
        psCategoryId
      }
      subtotal
      lineNumber
      price
      quantity
    }
    invoiceNumber
    invoiceDate
    supplier {
      _id
      company {
        name
      }
    }
    property {
      _id
      name
      clientOuterId
    }
  }
`;

export const SPEND_REPORTS_INVOICES = gql`
  query SPEND_REPORTS_INVOICES($searchInput: CollectionSearchParams) {
    searchOCRInvoices(input: $searchInput) {
      hits {
        ...spendReportsInvoicesFields
      }
      total
    }
  }
  ${spendReportsInvoicesFields}
`;

export const useSpendDetailReport = (sort) => {
  const filters = useSelector((state) => state.filters);

  const { pagination } = usePagination();

  const searchText = useSelector((state) => state.searchText);

  const { loading, result } = useEntitiesByReactiveSearch({
    gqlSearchQuery: SPEND_REPORTS_INVOICES,
    gqlSearchChangedQuery: OCR_INVOICES_BY_SEARCH_SUB,
    query: getSpendDetailQuery(filters),
    pagination,
    sort,
    skipQuery: !filters?.property,
    searchText,
  });

  return {
    loading,
    result,
    filters,
  };
};
