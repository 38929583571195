import styled from 'styled-components';
import React from 'react';
import { element, oneOfType, arrayOf } from 'prop-types';
import { OutSidebarProvider } from '@poly/client-utils';
import { BaseSidebarLayout, ModalProvider } from '@poly/admin-ui';
import { getThemeColor } from '@poly/admin-book';
import { useCheckTokenWithRedirect } from '@poly/client-utils/src/hooks/useCheckTokenWithRedirect.js';

import { AppBar } from '../../containers/appBar/AppBar.js';
import { ConfirmModalWindow, Modal } from '../../containers/modal/Modal.js';
import { routes } from '../../routes/constants.js';

const MainLayoutWrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const DynamicContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${getThemeColor(['white'])};
  height: calc(100% - 60px);
`;

export function MainLayout({ children }) {
  const { isTokenValid, loading } = useCheckTokenWithRedirect(routes.LOGIN);

  if (loading || !isTokenValid) {
    return null;
  }

  return (
    <MainLayoutWrapper>
      <ModalProvider>
        <OutSidebarProvider Layout={BaseSidebarLayout}>
          <AppBar />
          <DynamicContentContainer>{children}</DynamicContentContainer>
          <Modal />
          <ConfirmModalWindow />
        </OutSidebarProvider>
      </ModalProvider>
    </MainLayoutWrapper>
  );
}

MainLayout.propTypes = {
  children: oneOfType([element, arrayOf(element)]).isRequired,
};
