import * as R from 'ramda';
import { Input } from '@poly/admin-book';
import { InvoiceSelectField } from '../components/index.js';
import { halfWidth } from '../../../common.js';

export const financialInformationSection = [
  {
    label: 'Financial Information',
    id: 'financialInfo',
    order: 2,
    layout: {
      column: 2,
    },
    fields: [
      {
        label: 'Invoice To',
        order: 1,
        layout: { row: 1, width: halfWidth },
        field: {
          name: 'invoiceTo',
          Component: InvoiceSelectField,
        },
      },
      {
        label: 'Coding',
        order: 2,
        layout: { row: 1, width: halfWidth },
        field: {
          name: 'financialCoding',
          Component: Input,
        },
        renderIf: R.path([
          'client',
          'configs',
          'propertyFields',
          'financialCoding',
        ]),
      },
    ],
  },
];
