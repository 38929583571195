import React from 'react';
import { SupplierSources } from '@poly/constants';
import { AddSupplierForm as CommonAddSupplierForm } from '@poly/admin-ui';
import { addSupplierFormId } from './constants.js';
import { routes } from '../../../routes/constants.js';

export function AddSupplierForm() {
  return (
    <CommonAddSupplierForm
      id={addSupplierFormId}
      supplierSource={SupplierSources.TIAA}
      rootUrl={routes.SUPPLIER}
      masterSupplierRootUrl={routes.MASTER_SUPPLIER}
    />
  );
}
