import React from 'react';
import styled from 'styled-components';
import { insertParamsIntoURL } from '@poly/utils';
import { Button, useNotificationContext } from '@poly/admin-book';
import { useNavigate } from '@poly/client-routing';
import { bool, string } from 'prop-types';
import { routes } from '../../../../routes/constants.js';
import { useBlockInvoices } from '../../apollo/useBlockInvoices.js';
import { useHandleXtractaDocument } from '../../../../utils/hooks/xtracta.js';

const CancelBtn = styled(Button).attrs(() => ({
  size: 'small',
  styleType: 'basicSecondary',
  type: 'button',
  children: 'Cancel',
}))``;

export function BlockInvoiceBtn({
  invoiceId,
  disabled: disabledFromProps,
  isMutating,
}) {
  const navigate = useNavigate();

  const { showSuccessNotification, showErrorNotification } =
    useNotificationContext();

  const { blockOCRInvoices } = useBlockInvoices();

  const onClick = async () => {
    try {
      await blockOCRInvoices([invoiceId]);
      showSuccessNotification('Invoice was successfully blocked');
      navigate(insertParamsIntoURL({ invoiceId }, routes.INVOICE));
    } catch (e) {
      showErrorNotification('An error occurred during invoice blocking');
    }
  };

  return (
    <Button
      onClick={onClick}
      styleType="primaryLighter"
      disabled={isMutating || disabledFromProps}
      loader={isMutating}
      size="small"
      type="button"
    >
      Block
    </Button>
  );
}

BlockInvoiceBtn.propTypes = {
  invoiceId: string,
  disabled: bool,
  isMutating: bool,
};

export function CancelVerifyingBtn({ invoiceId, ...restProps }) {
  const { handleXtractaDocument } = useHandleXtractaDocument();

  const onClick = () => handleXtractaDocument(invoiceId);

  return <CancelBtn {...restProps} onClick={onClick} />;
}

CancelVerifyingBtn.propTypes = {
  invoiceId: string,
};
