import React from 'react';
import { EditPropertyForm as CommonEditPropertyForm } from '@poly/admin-ui';
import { useNotificationContext } from '@poly/admin-book';
import {
  getCommonRouterProps,
  useRouterParams,
  useLocation,
  useParams,
} from '@poly/client-routing';

import { editPropertyFormId } from '../../../utils/constants/property.js';
import { transformPropertyFormSections } from './propertyFormHelpers.js';
import { usePropertyDetails } from '../apollo/withPropertyDetails.js';

export function EditPropertyForm() {
  const { propertyId } = useRouterParams(['propertyId']);

  const { data, loading } = usePropertyDetails(propertyId);

  const notificationContext = useNotificationContext();

  const location = useLocation();
  const params = useParams();

  const router = getCommonRouterProps({ params, location });

  return (
    <CommonEditPropertyForm
      {...notificationContext}
      {...data}
      router={router}
      loading={loading}
      propertyId={propertyId}
      formId={editPropertyFormId}
      transformConfig={transformPropertyFormSections}
    />
  );
}

EditPropertyForm.displayName = 'EditPropertyForm';
