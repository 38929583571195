import * as R from 'ramda';
import React from 'react';
import { useDispatch } from 'react-redux';
import { closeCurrentModal, useProcessState } from '@poly/admin-ui';
import { gql, useMutation } from '@apollo/client';
import { useNotificationContext } from '@poly/admin-book';
import { ModalFormWrapper } from '../../containers/modal/ModalWrapper.js';
import {
  createEditRecurringInvoiceFormId,
  RecurringOCRInvoiceForm,
} from './RecurringOCRInvoiceForm.js';

const commonMutationOptions = {
  refetchQueries: [
    'RECURRING_OCR_INVOICES_QUERY',
    'MISSING_OCR_INVOICES_QUERY',
  ],
  awaitRefetchQueries: true,
};

const createRecurringOCRInvoiceQuery = gql`
  mutation CREATE_RECURRING_OCR_INVOICE_MUTATION(
    $input: CreateRecurringOCRInvoiceInput!
  ) {
    createRecurringOCRInvoice(input: $input) {
      invoice {
        _id
      }
    }
  }
`;

const useCreateRecurringOCRInvoice = () => {
  const [mutate] = useMutation(createRecurringOCRInvoiceQuery, {
    alias: 'useCreateRecurringOCRInvoice',
  });

  return {
    createRecurringOCRInvoice: (input) =>
      mutate({ variables: { input }, ...commonMutationOptions }),
  };
};

const updateRecurringOCRInvoiceQuery = gql`
  mutation UPDATE_RECURRING_OCR_INVOICE_MUTATION(
    $id: ID!
    $update: UpdateRecurringOCRInvoiceInput!
  ) {
    updateRecurringOCRInvoice(id: $id, update: $update) {
      invoice {
        _id
      }
    }
  }
`;

const useUpdateRecurringOCRInvoice = () => {
  const [mutate] = useMutation(updateRecurringOCRInvoiceQuery, {
    alias: 'useUpdateRecurringOCRInvoice',
  });

  return {
    updateRecurringOCRInvoice: (id, update) =>
      mutate({ variables: { id, update }, ...commonMutationOptions }),
  };
};

// recurringOCRInvoiceToInitialValues :: Object -> RecurringOCRInvoice
const recurringOCRInvoiceToInitialValues = R.converge(R.mergeRight, [
  R.applySpec({
    propertyId: R.path(['property', '_id']),
    supplierId: R.path(['supplier', '_id']),
  }),
  R.pick([
    '_id',
    'supplierOuterContractId',
    'endDayOfTheMonth',
    'receiptMethod',
  ]),
]);

// modalPropsToInvoiceProps :: Object -> {invoice: RecurringOCRInvoice}
const modalPropsToInvoiceProps = R.compose(
  R.objOf('invoice'),
  R.ifElse(R.isNil, R.defaultTo({}), recurringOCRInvoiceToInitialValues),
  R.path(['payload', 'invoice']),
);

// invoiceFormDataToInput :: RecurringOCRInvoice -> UpdateRecurringOCRInvoiceInput
const invoiceFormDataToInput = R.compose(
  R.unless(
    R.propSatisfies(R.is(String), 'supplierOuterContractId'),
    R.assoc('supplierOuterContractId', null),
  ),
  R.omit(['_id']),
);

export function CreateEditRecurringInvoiceModal(props) {
  const dispatch = useDispatch();

  const { showSuccessNotification } = useNotificationContext();

  const { process } = useProcessState(createEditRecurringInvoiceFormId);

  const { invoice } = modalPropsToInvoiceProps(props);

  const { createRecurringOCRInvoice } = useCreateRecurringOCRInvoice();
  const { updateRecurringOCRInvoice } = useUpdateRecurringOCRInvoice();

  const onSubmit = async (formData) => {
    const isEditMode = invoice?._id;

    const onMutationFinished = () => {
      const successMessage = `Recurring invoice successfully ${
        isEditMode ? 'updated' : 'created'
      }`;
      showSuccessNotification(successMessage);
      dispatch(closeCurrentModal());
    };

    const input = invoiceFormDataToInput(formData);

    const mutationResultP = isEditMode
      ? updateRecurringOCRInvoice(invoice._id, input)
      : createRecurringOCRInvoice(input);
    return mutationResultP.then(() => onMutationFinished());
  };

  return (
    <ModalFormWrapper
      actionInProgress={!!process}
      title={`${invoice._id ? 'Edit' : 'Create'} Recurring Invoice`}
      buttonLabel="Save"
      formId={createEditRecurringInvoiceFormId}
      Form={RecurringOCRInvoiceForm}
      invoice={invoice}
      onSubmit={onSubmit}
    />
  );
}
