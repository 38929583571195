import { getYear, subYears } from 'date-fns';
import { alwaysNewDate } from '@poly/utils';

export const ALL_VALUES = 'ALL_VALUES';

export const ITEMS_PER_SCROLL = 30;

export const ITEMS_LIMIT = 100;

export const START_YEAR_PERIOD = getYear(subYears(alwaysNewDate(), 1));

export const ALL = 'GLOBAL_ALL_IDENTIFIER';
