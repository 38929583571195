import React from 'react';
import * as R from 'ramda';
import { shape, string } from 'prop-types';
import { FileLink } from '@poly/admin-ui';
import { SectionWithCard } from '@poly/admin-book';
import { formatDate } from '@poly/utils';
import { NOTHING_UI_STRING } from '@poly/constants';

const fileShape = { fileName: string, url: string };
const fileName = R.path(['fileName']);
const fileUrl = R.path(['url']);

const prepareDate = R.ifElse(R.isNil, R.always(NOTHING_UI_STRING), formatDate);

export function DocumentsSection({
  wcFile,
  wcExp,
  glFile,
  glExp,
  scaFile,
  sqqFile,
}) {
  return (
    <SectionWithCard
      title="Documents"
      list={[
        {
          title: 'Workers Comp Insurance',
          elem: <FileLink url={fileUrl(wcFile)} fileName={fileName(wcFile)} />,
        },
        { title: 'EXP', elem: prepareDate(wcExp), key: 'wcExp' },
        {
          title: 'Liability Insurance',
          elem: <FileLink url={fileUrl(glFile)} fileName={fileName(glFile)} />,
        },
        { title: 'EXP', elem: prepareDate(glExp), key: 'glExp' },
        {
          title: 'Subcontractor Agreement',
          elem: (
            <FileLink url={fileUrl(scaFile)} fileName={fileName(scaFile)} />
          ),
        },
        {
          title: 'Subcontractor Questionnaire',
          elem: (
            <FileLink url={fileUrl(sqqFile)} fileName={fileName(sqqFile)} />
          ),
        },
      ]}
    />
  );
}

DocumentsSection.propTypes = {
  wcFile: shape(fileShape),
  wcExp: string,
  glFile: shape(fileShape),
  glExp: string,
  scaFile: shape(fileShape),
  sqqFile: shape(fileShape),
};

DocumentsSection.defaultProps = {
  wcFile: {},
  wcExp: null,
  glFile: {},
  glExp: null,
  scaFile: {},
  sqqFile: {},
};
