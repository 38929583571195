import React from 'react';
import { useRouterParams, useRouterQuery } from '@poly/client-routing';
import {
  useReactiveQuery,
  useHasUserAccessWithPermission,
} from '@poly/client-utils';
import { SupplierStatuses } from '@poly/constants';
import {
  useMasterSupplierBranchesQuery,
  useMasterSupplierDetailsQuery,
  MasterSupplierPageHeader,
  SearchInputTabsDisplay,
  UPDATES_SEARCH_CHANGED,
  TableSearchInput,
  MAX_ITEMS,
  NotFoundPage,
} from '@poly/admin-ui';
import { READ_UPDATE_PERMISSION } from '@poly/security';

import { MasterSupplierInfoTab } from './MasterSupplierInfoTab.js';
import {
  MASTER_SUPPLIER_UPDATES,
  MasterSupplierUpdatesTab,
} from './MasterSupplierUpdatesTab.js';
import { routes } from '../../routes/constants.js';
import { MasterSupplierBranchesTab } from './MasterSupplierBranchesTab.js';
import { PageTabs } from '../../components/tabs/PageTabs.js';
import {
  EditMasterSupplier,
  editMasterSupplierFormId,
} from './EditMasterSupplier.js';

const masterSupplierTabs = {
  masterSupplierAccount: 'master-supplier-account',
  masterSupplierBranches: 'master-supplier-branches',
  masterSupplierUpdates: 'master-supplier-updates',
};

const prefetchPolicy = 'network-only';

const { ACTIVE, BLOCKED } = SupplierStatuses;

const usePrefetchMasterSupplierCard = (masterSupplierId) => {
  const hasUserPermissionToReadUpdates = useHasUserAccessWithPermission(
    READ_UPDATE_PERMISSION,
  );

  const { data, loading } = useMasterSupplierDetailsQuery(
    masterSupplierId,
    prefetchPolicy,
  );
  useMasterSupplierBranchesQuery(
    masterSupplierId,
    ACTIVE,
    null,
    prefetchPolicy,
  );
  useMasterSupplierBranchesQuery(
    masterSupplierId,
    BLOCKED,
    null,
    prefetchPolicy,
  );
  useReactiveQuery(MASTER_SUPPLIER_UPDATES, UPDATES_SEARCH_CHANGED, {
    queryOptions: {
      variables: {
        id: masterSupplierId,
        documentUpdatesInput: {
          includeAudit: false,
          sort: { createdAt: -1 },
          from: 0,
          size: MAX_ITEMS,
        },
      },
      fetchPolicy: prefetchPolicy,
      skip: !hasUserPermissionToReadUpdates,
    },
    subscriptionOptions: {
      variables: { searchInput: { documentId: masterSupplierId } },
    },
  });
  return { data, loading };
};

export function MasterSupplierPage() {
  const { supplierId } = useRouterParams(['supplierId']);
  const { isEdit } = useRouterQuery(['isEdit']);

  const { data, loading } = usePrefetchMasterSupplierCard(supplierId);

  if (!loading && !data.masterSupplier) {
    return <NotFoundPage />;
  }

  return (
    <>
      <MasterSupplierPageHeader
        defaultTab={masterSupplierTabs.masterSupplierAccount}
        getFallBackLink={() => routes.MASTER_SUPPLIERS}
        formId={editMasterSupplierFormId}
        supplierId={supplierId}
      />
      {isEdit ? (
        <EditMasterSupplier supplierId={supplierId} />
      ) : (
        <PageTabs
          toolBar={
            <SearchInputTabsDisplay
              {...{ permit: [masterSupplierTabs.masterSupplierBranches] }}
            >
              <TableSearchInput />
            </SearchInputTabsDisplay>
          }
          tabs={[
            [
              'Info',
              masterSupplierTabs.masterSupplierAccount,
              <MasterSupplierInfoTab
                supplierId={supplierId}
                key={masterSupplierTabs.masterSupplierAccount}
              />,
            ],
            [
              'Branches',
              masterSupplierTabs.masterSupplierBranches,
              <MasterSupplierBranchesTab
                key={masterSupplierTabs.masterSupplierBranches}
              />,
            ],
            [
              'Updates',
              masterSupplierTabs.masterSupplierUpdates,
              <MasterSupplierUpdatesTab
                key={masterSupplierTabs.masterSupplierUpdates}
              />,
              READ_UPDATE_PERMISSION,
            ],
          ]}
          defaultValue={masterSupplierTabs.masterSupplierAccount}
        />
      )}
    </>
  );
}
