import { Heading, PageHeaderContainer } from '@poly/admin-book';
import {
  ASC_SORT_ORDER,
  MasterSupplierStatus,
  SupplierTypes,
} from '@poly/constants';
import React from 'react';
import { useNavigate } from '@poly/client-routing';
import * as R from 'ramda';
import {
  MasterSuppliersTable,
  MasterSupplierTabTitle,
  TableCardWithPaginationContainer,
  useSearchMasterSuppliersQuery,
} from '@poly/admin-ui';
import {
  keywordSortQuery,
  useHasUserAccessWithPermission,
} from '@poly/client-utils';
import { CREATE_MASTER_SUPPLIER_PERMISSION } from '@poly/security';
import { string } from 'prop-types';

import { routes } from '../../routes/constants.js';
import { LinkButtonS } from '../invoice/InvoicePage/common/styles.js';
import { PageTabs } from '../../components/tabs/PageTabs.js';
import { SearchInput } from '../../containers/SearchInput.js';

function AddMasterSupplierLink() {
  const navigate = useNavigate();
  const hasAccess = useHasUserAccessWithPermission(
    CREATE_MASTER_SUPPLIER_PERMISSION,
  );

  return hasAccess ? (
    <LinkButtonS
      onClick={() => navigate(routes.ADD_MASTER_SUPPLIER)}
      type="button"
    >
      Add New
    </LinkButtonS>
  ) : null;
}

const masterSuppliersInitialSortQuery = keywordSortQuery(['company', 'name'])(
  ASC_SORT_ORDER,
);

function MasterSuppliersTab({ status }) {
  return (
    <TableCardWithPaginationContainer>
      <MasterSuppliersTable
        {...{
          type: SupplierTypes.ADMINISTRATIVE,
          masterSupplierRootUrl: routes.MASTER_SUPPLIER,
          status,
        }}
      />
    </TableCardWithPaginationContainer>
  );
}

MasterSuppliersTab.propTypes = {
  status: string.isRequired,
};

const masterSuppliersTabs = R.map(
  (status) => [
    <MasterSupplierTabTitle
      status={status}
      sorting={masterSuppliersInitialSortQuery[1]}
      key={`${status}-master-suppliers-tab-title`}
    />,
    status,
    <MasterSuppliersTab
      status={status}
      key={`${status}-master-suppliers-tab`}
    />,
  ],
  R.values(MasterSupplierStatus),
);

const usePrefetchAdministrativeSuppliers = (status) =>
  useSearchMasterSuppliersQuery(
    status,
    SupplierTypes.ADMINISTRATIVE,
    masterSuppliersInitialSortQuery,
    'network-only',
  );

export function MasterSuppliersPage() {
  usePrefetchAdministrativeSuppliers(MasterSupplierStatus.ACTIVE);
  usePrefetchAdministrativeSuppliers(MasterSupplierStatus.BLOCKED);

  return (
    <>
      <PageHeaderContainer>
        <Heading size="h2">Master Suppliers</Heading>
        <AddMasterSupplierLink />
      </PageHeaderContainer>
      <PageTabs
        toolBar={<SearchInput />}
        tabs={masterSuppliersTabs}
        defaultValue={MasterSupplierStatus.ACTIVE}
      />
    </>
  );
}
