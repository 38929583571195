import * as R from 'ramda';
import React from 'react';
import { ITEMS_LIMIT } from '@poly/admin-ui';
import { DESC_SORT_ORDER, PRINT_TYPES } from '@poly/constants';
import { useRouterParams } from '@poly/client-routing';
import { commonSortQuery } from '@poly/client-utils';
import { TablePrintBtn } from '../../../containers/buttons/TablePrintPDFBtn.js';
import { usePropertyInvoicesBySearch } from '../../invoice/EntityInvoicesTab/index.js';
import { getOCRInvoices } from '../../supplier/SupplierDetailsPage/PrintSupplierInvoicesBtn.js';

// getPropertyName :: SearchPropertyQueryResult -> String
const getPropertyName = R.path([0, 'property', 'name']);

const useCommonPropertyInvoicesMapper = () => {
  const { propertyId } = useRouterParams(['propertyId']);

  const { result } = usePropertyInvoicesBySearch({
    query: { match: { propertyId } },
    sort: commonSortQuery(['invoiceId'])(DESC_SORT_ORDER),
  });

  return {
    data: getOCRInvoices(result),
  };
};

export function PropertyInvoicesPrintPDFBtn() {
  const { data } = useCommonPropertyInvoicesMapper();

  return (
    <TablePrintBtn
      data={data}
      printType={PRINT_TYPES.PROPERTY_INVOICES}
      showWarningModal={data.length > ITEMS_LIMIT}
      metaData={{
        title: 'Invoices',
        additional: `Property: ${getPropertyName(data)}`,
      }}
    />
  );
}

PropertyInvoicesPrintPDFBtn.displayName = 'PropertyInvoicesPrintPDFBtn';
