import React from 'react';
import { string, oneOfType, arrayOf, object, shape, array } from 'prop-types';
import { insertParamsIntoURL } from '@poly/utils';
import { Link } from '@poly/client-routing';
import { routes } from '../../routes/constants.js';

const { BATCH, INVOICE } = routes;

const highlightAleStringPropType = oneOfType([
  string,
  arrayOf(oneOfType([string, object])),
]);

export function BatchLink({ _id, batchId }) {
  return (
    <Link href={insertParamsIntoURL({ batchId: _id })(BATCH)}>{batchId}</Link>
  );
}

BatchLink.displayName = 'BatchLink';

BatchLink.propTypes = {
  _id: string.isRequired,
  batchId: string.isRequired,
};

export function InvoicePDFLink({ invoiceNumber = '–', invoiceFileLink }) {
  return (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a target="_blank" href={invoiceFileLink}>
      {invoiceNumber}
    </a>
  );
}

InvoicePDFLink.displayName = 'InvoicePDFLink';

InvoicePDFLink.propTypes = {
  invoiceFileLink: string.isRequired,
  invoiceNumber: oneOfType([string, array]),
};

export function InvoiceLink({ _id, invoiceId }) {
  return (
    <Link href={insertParamsIntoURL({ invoiceId: _id })(INVOICE)}>
      {invoiceId}
    </Link>
  );
}

InvoiceLink.displayName = 'InvoiceLink';

InvoiceLink.propTypes = {
  _id: oneOfType([string, array]).isRequired,
  invoiceId: oneOfType([string, array, object]).isRequired,
};

export function SupplierLink({ _id, name }) {
  return <Link href={`/supplier/${_id}`}>{name}</Link>;
}

SupplierLink.displayName = 'SupplierLink';

SupplierLink.propTypes = {
  _id: string.isRequired,
  name: highlightAleStringPropType.isRequired,
};

export function InvoiceSupplierLink({ supplier }) {
  return <SupplierLink _id={supplier._id} name={supplier.company.name} />;
}

InvoiceSupplierLink.propTypes = {
  supplier: shape({
    _id: string,
    company: shape({
      name: string,
    }),
  }),
};

export function PropertyLink({ _id, name }) {
  return <Link href={`/property/${_id}`}>{name}</Link>;
}

PropertyLink.displayName = 'PropertyLink';

PropertyLink.propTypes = {
  name: highlightAleStringPropType.isRequired,
  _id: string.isRequired,
};
