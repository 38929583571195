import React from 'react';
import { useApolloClient } from '@apollo/client';
import { AddTaskForm as CommonAddTaskForm } from '@poly/admin-ui';

export const addTaskFormId = 'addTaskFormId';

export function AddTaskForm(props) {
  const apolloClient = useApolloClient();

  const formProps = {
    ...props,
    apolloClient,
    formId: addTaskFormId,
    disableMentions: true,
    isTaskUpdateMessageOptional: true,
  };

  return <CommonAddTaskForm {...formProps} />;
}
