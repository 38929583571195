import * as R from 'ramda';
import { applySpecWithFields, assocBy, removePropDeep } from '@poly/utils';
import { parseFieldToNumberIfExists } from '@poly/client-utils';

import {
  buildingTypeOptionsConfig,
  customPropertyBuildingType,
} from '../form/sections/buildinInformationSection.js';

const prepareAddressObject = R.compose(
  R.dissoc('addressTwo'),
  assocBy(
    'address',
    R.converge(R.mergeRight, [R.prop('address'), R.pick(['addressTwo'])]),
  ),
);

const propertyFields = [
  'name',
  'status',
  'addressTwo',
  'phone',
  'fax',
  'invoiceTo',
  'remarks',
  'address',
  'aacContractMGR',
  'invoiceTo',
  'occupancy',
  'ownership',
  'clientOuterId',
  'clientExternalId',
  'tier',
  'clientUnitName',
  'typeByClient',
  'square',
  'storeNumber',
  'division',
  'buildingInfo',
  'alarmInfo',
  'financialCoding',
  'clientDistrictId',
];

const propertyFormFields = [
  '_id',
  'client',
  'branchManager',
  'districtFacilityManager',
];

const propertyMutationFields = ['branchManagerId', 'districtFacilityManagerId'];

// isNotCustomBuildingType :: String -> Boolean
const isNotCustomBuildingType = R.includes(R.__, buildingTypeOptionsConfig);

const prepareBuildingTypeToDisplay = ({
  buildingType,
  ...otherBuildingInfo
}) => ({
  ...otherBuildingInfo,
  buildingType: isNotCustomBuildingType(buildingType)
    ? buildingType
    : customPropertyBuildingType,
  customBuildingType: isNotCustomBuildingType(buildingType) ? '' : buildingType,
});

// propertyToEditForm :: Property -> FormObject
export const propertyToEditForm = R.compose(
  R.over(
    R.lensProp('buildingInfo'),
    R.ifElse(
      R.propSatisfies(R.isNil, 'buildingType'),
      R.identity,
      prepareBuildingTypeToDisplay,
    ),
  ),
  parseFieldToNumberIfExists('storeNumber'),
  R.pick([...propertyFields, ...propertyFormFields]),
  prepareAddressObject,
  removePropDeep('__typename'),
  applySpecWithFields({
    aacContractMGR: R.path(['aacContractMGR', '_id']),
    clientDistrictId: R.path(['clientDistrict', '_id']),
  }),
);

const prepareBuildingType = ({
  buildingType,
  customBuildingType = '',
  ...otherBuildingInfo
}) => ({
  ...otherBuildingInfo,
  buildingType:
    buildingType === customPropertyBuildingType
      ? customBuildingType
      : buildingType,
});

// propertyToMutationData :: FormObject -> MutationObject
export const propertyFormObjectToMutation = R.compose(
  R.map(R.when(R.isNil, R.always(null))),
  R.map(R.when(R.is(String), (str) => str.trim())),
  parseFieldToNumberIfExists('storeNumber'),
  R.ifElse(
    R.propSatisfies(R.isNil, 'buildingInfo'),
    R.identity,
    R.over(R.lensProp('buildingInfo'), prepareBuildingType),
  ),
  R.pickAll([...propertyFields, ...propertyMutationFields]),
  removePropDeep('__typename'),
  R.dissocPath(['address', 'addressTwo']),
  applySpecWithFields({
    addressTwo: R.pathOr('', ['address', 'addressTwo']),
  }),
);
