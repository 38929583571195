import React from 'react';
import {
  Heading,
  DefaultBodyWrapper,
  PageHeaderContainer,
} from '@poly/admin-book';

import { BatchesTable } from './BatchesTable.js';

export function BatchesPage() {
  return (
    <>
      <PageHeaderContainer>
        <Heading size="h2">Invoice Batches</Heading>
      </PageHeaderContainer>
      <DefaultBodyWrapper style={{ overflow: 'auto' }}>
        <div>
          <BatchesTable />
        </div>
      </DefaultBodyWrapper>
    </>
  );
}
