import {
  OCRInvoiceStatuses,
  propertyStatuses,
  SupplierStatuses,
  uiBatchStatuses,
} from '@poly/constants';

import { theme } from '../../providers.js';

export const batchStatuses = {
  NEW: 'NEW',
  APPROVED: 'APPROVED',
  PENDING_SYNC: 'PENDING_SYNC',
  SYNC_OK: 'SYNC_OK',
  SYNC_ERROR: 'SYNC_ERROR',
  SENT_TO_AP: 'SENT_TO_AP',
  AP_SYNC_ERROR: 'AP_SYNC_ERROR',
  FUNDED: 'FUNDED',
  SENT_TO_CITI: 'SENT_TO_CITI',
  CITY_SYNC_ERROR: 'CITY_SYNC_ERROR',
};

export const uiAndRealAACBatchStatusesRel = {
  [batchStatuses.NEW]: uiBatchStatuses.NEW,
  [batchStatuses.APPROVED]: uiBatchStatuses.APPROVED,
  [batchStatuses.PENDING_SYNC]: uiBatchStatuses.PENDING_SYNC,
  [batchStatuses.SYNC_OK]: uiBatchStatuses.SYNC_OK,
  [batchStatuses.SYNC_ERROR]: uiBatchStatuses.SYNC_ERROR,
  [batchStatuses.SENT_TO_AP]: uiBatchStatuses.SENT_TO_AP,
  [batchStatuses.AP_SYNC_ERROR]: uiBatchStatuses.AP_SYNC_ERROR,
  [batchStatuses.FUNDED]: uiBatchStatuses.FUNDED,
  [batchStatuses.SENT_TO_CITI]: uiBatchStatuses.SENT_TO_CITI,
  [batchStatuses.CITY_SYNC_ERROR]: uiBatchStatuses.CITY_SYNC_ERROR,
};

const {
  colors: {
    statuses: { pending, ready, funded, active, inactive, error, blocked },
  },
} = theme;

export const uiBatchStatusesColors = {
  [uiBatchStatuses.PENDING_APPROVAL]: pending,
  [uiBatchStatuses.APPROVED]: ready,
  [uiBatchStatuses.FUNDED]: funded,
  [uiBatchStatuses.NEW]: active,
  [uiBatchStatuses.APPROVED]: ready,
  [uiBatchStatuses.PENDING_SYNC]: pending,
  [uiBatchStatuses.SYNC_OK]: ready,
  [uiBatchStatuses.SYNC_ERROR]: error,
  [uiBatchStatuses.SENT_TO_AP]: ready,
  [uiBatchStatuses.AP_SYNC_ERROR]: error,
  [uiBatchStatuses.FUNDED]: funded,
  [uiBatchStatuses.SENT_TO_CITI]: ready,
  [uiBatchStatuses.CITY_SYNC_ERROR]: error,
};

export const uiInvoiceStatuses = {
  [OCRInvoiceStatuses.PENDING]: 'Pending Approval',
  [OCRInvoiceStatuses.APPROVED]: 'Approved',
  [OCRInvoiceStatuses.VERIFIED]: 'Verified',
  [OCRInvoiceStatuses.PAID]: 'Paid',
  [OCRInvoiceStatuses.BLOCKED]: 'Blocked',
  [OCRInvoiceStatuses.NEW]: 'New',
  [OCRInvoiceStatuses.SCANNING]: 'Scanning',
  [OCRInvoiceStatuses.EXCEPTION]: 'Exception',
  [OCRInvoiceStatuses.OCR_READY]: 'OCR ready',
  [OCRInvoiceStatuses.OCR_ERROR]: 'OCR error',
};

export const uiAndRealInvoiceStatusesRel = {
  [OCRInvoiceStatuses.NEW]: uiInvoiceStatuses.PENDING,
  [OCRInvoiceStatuses.VERIFIED]: uiInvoiceStatuses.VERIFIED,
  [OCRInvoiceStatuses.PENDING]: uiInvoiceStatuses.PENDING,
  [OCRInvoiceStatuses.EXCEPTION]: uiInvoiceStatuses.PENDING,
  [OCRInvoiceStatuses.APPROVED]: uiInvoiceStatuses.APPROVED,
  [OCRInvoiceStatuses.PAID]: uiInvoiceStatuses.PAID,
  [OCRInvoiceStatuses.BLOCKED]: uiInvoiceStatuses.BLOCKED,
  [OCRInvoiceStatuses.OCR_READY]: uiInvoiceStatuses.PENDING,
};

export const uiInvoiceStatusesColors = {
  [uiInvoiceStatuses.PENDING]: pending,
  [uiInvoiceStatuses.VERIFIED]: ready,
  [uiInvoiceStatuses.APPROVED]: ready,
  [uiInvoiceStatuses.PAID]: active,
  [uiInvoiceStatuses.BLOCKED]: blocked,
};

export const propertyStatusesColors = {
  [propertyStatuses.ACTIVE]: active,
  [propertyStatuses.INACTIVE]: inactive,
};

export const supplierStatusesColors = {
  [SupplierStatuses.ACTIVE]: active,
  [SupplierStatuses.BLOCKED]: blocked,
};
