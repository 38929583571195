import React from 'react';
import { useDispatch } from 'react-redux';
import {
  AddFilesForm as CommonAddFilesForm,
  useProcessState,
} from '@poly/admin-ui';
import { shape, string } from 'prop-types';
import { addFilesFormId } from './constants.js';
import { setOpenModal } from '../../../state/modules/modal.js';
import { ModalFormWrapper } from '../../modal/ModalWrapper.js';

function AddFilesForm({ payload }) {
  const dispatch = useDispatch();

  const onCancel = () => dispatch(setOpenModal({ name: null }));

  return payload.documentId ? (
    <CommonAddFilesForm
      {...payload}
      successMessage="File was successfully added"
      formId={addFilesFormId}
      onCancel={onCancel}
    />
  ) : null;
}

AddFilesForm.propTypes = {
  payload: shape({
    collection: string,
    documentId: string,
  }),
};

export function AddFilesModal(props) {
  const { process } = useProcessState(addFilesFormId);

  return (
    <ModalFormWrapper
      {...props}
      actionInProgress={!!process}
      title="Add Files"
      buttonLabel="Save"
      formId={addFilesFormId}
      Form={AddFilesForm}
    />
  );
}
