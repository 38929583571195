import React from 'react';
import { useApolloClient } from '@apollo/client';
import { PostTaskUpdateForm as CommonPostTaskUpdateForm } from '@poly/admin-ui';

export const postTaskUpdateFormId = 'postTaskUpdateFormId';

export function PostTaskUpdateForm(props) {
  const apolloClient = useApolloClient();

  const formProps = {
    ...props,
    apolloClient,
    disableMentions: true,
    formId: postTaskUpdateFormId,
  };

  return <CommonPostTaskUpdateForm {...formProps} />;
}
