import React from 'react';
import * as R from 'ramda';
import { propertyTiers } from '@poly/constants';
import { Select } from '@poly/admin-book';

// getPropertyTierOptions :: [Number] -> [Option]
const getPropertyTierOptions = R.map(
  R.compose(
    R.converge(R.mergeLeft, [R.objOf('value'), R.objOf('label')]),
    R.toString,
  ),
);

export function PropertyTierSelect(props) {
  const selectProps = {
    ...props,
    name: 'PropertyTierSelect',
    options: getPropertyTierOptions(propertyTiers),
  };

  return <Select {...selectProps} />;
}

PropertyTierSelect.displayName = 'PropertyTierSelect';
