import React from 'react';
import * as R from 'ramda';
import { number } from 'prop-types';
import { useHighlightMatchesBySearch } from '@poly/client-utils';
import { useMapConfigToTableProps } from '@poly/admin-ui';
import {
  mapConfigToTableProps,
  useTableSortable,
  Loader,
} from '@poly/admin-book';

import { SupplierLink } from '../../../../containers/links/Links.js';
import { SupplierTaxReportTableC } from './SupplierTaxReportTableC.js';
import { formatCurrencyProp } from '../../../../utils/foramatters/index.js';
import { useSupplierTaxReportQuery } from '../useSupplierTaxReportQuery.js';

// getCompanyTypeUI :: SuppliersTaxReport -> String
const getCompanyTypeUI = R.ifElse(
  R.prop(['exempt']),
  R.always('Exempt'),
  R.prop('companyType'),
);

// getTaxIdUI :: SuppliersTaxReport -> String
const getTaxIdUI = R.ifElse(
  R.prop(['exempt']),
  R.always('Exempt'),
  R.prop('taxId'),
);

const tableConfig = [
  ['Name', SupplierLink, R.prop('name')],
  ['Is 1099', R.prop('is1099'), R.prop('is1099')],
  ['Company Type', getCompanyTypeUI, R.prop('companyType')],
  ['Tax ID', getTaxIdUI, R.prop('taxId')],
  ['Compensation', formatCurrencyProp('compensation'), R.prop('compensation')],
];

export function SupplierTaxReportTable({ year }) {
  const { report, loading } = useSupplierTaxReportQuery(year);

  const tableConfigProps = useMapConfigToTableProps(
    R.identity,
    tableConfig,
    report,
  );

  const { rows, ...tableSortProps } = useTableSortable(tableConfigProps);

  const { highlightedRows } = useHighlightMatchesBySearch(
    R.identity,
    ['name', 'is1099', 'companyType', 'taxId'],
    rows,
  );

  if (loading) return <Loader />;

  return (
    <SupplierTaxReportTableC
      {...tableConfigProps}
      {...tableSortProps}
      rows={highlightedRows}
    />
  );
}

SupplierTaxReportTable.displayName = 'SupplierTaxReportTable';
SupplierTaxReportTable.propTypes = { year: number.isRequired };

export const PrintSupplierTaxReportTable = mapConfigToTableProps(
  R.prop('data'),
  tableConfig,
)(SupplierTaxReportTableC);
