import React from 'react';
import { EditMasterSupplierForm } from '@poly/admin-ui';
import { Loader } from '@poly/admin-book';
import { useHasUserAccessToUpdateSupplierProtected } from '@poly/client-utils';
import { string } from 'prop-types';

import { routes } from '../../routes/constants.js';

export const editMasterSupplierFormId = 'editMasterSupplierFormId';

export function EditMasterSupplier({ supplierId }) {
  const { protectedFieldsAccess, accessLoading } =
    useHasUserAccessToUpdateSupplierProtected();

  if (accessLoading) {
    return <Loader />;
  }

  return (
    <EditMasterSupplierForm
      masterSupplierRootUrl={routes.MASTER_SUPPLIER}
      supplierRootUrl={routes.SUPPLIER}
      formId={editMasterSupplierFormId}
      supplierId={supplierId}
      protectedFieldsAccess={protectedFieldsAccess}
    />
  );
}

EditMasterSupplier.propTypes = {
  supplierId: string.isRequired,
};
