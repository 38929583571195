import React from 'react';
import { useApolloClient } from '@apollo/client';
import { CompleteTaskForm as CommonCompleteTaskForm } from '@poly/admin-ui';

import { confirmButtonNames } from '../../modal/confirmButtons/constants.js';

export const completeTaskFormId = 'completeTaskFormId';

export function CompleteTaskForm(props) {
  const apolloClient = useApolloClient();

  const formProps = {
    ...props,
    apolloClient,
    disableMentions: true,
    formId: completeTaskFormId,
    isTaskUpdateMessageOptional: true,
    confirmButtonName: confirmButtonNames.CONFIRM_CLOSE_MODAL,
  };

  return <CommonCompleteTaskForm {...formProps} />;
}
