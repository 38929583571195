import React from 'react';
import { propertyStatuses } from '@poly/constants';
import styled from 'styled-components';
import {
  defaultTheme,
  Heading,
  PageHeaderContainer,
  Status,
} from '@poly/admin-book';
import { string } from 'prop-types';
import { PageTabs } from '../../../components/tabs/PageTabs.js';
import { PropertiesTable } from '../propertiesTable/PropertiesTable.js';
import { SearchInput } from '../../../containers/SearchInput.js';
import { usePrefetchProperties } from '../apollo/prefetches.js';
import { usePropertiesBySearchQuery } from '../apollo/usePropertiesBySearch.js';
import { AddPropertyBtn } from './AddPropertyBtn.js';

const {
  colors: {
    statuses: { active, inactive },
  },
} = defaultTheme;
const { ACTIVE, INACTIVE } = propertyStatuses;

const propertyStatusColors = {
  [ACTIVE]: active,
  [INACTIVE]: inactive,
};

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

function TitleComp({ status, text }) {
  const { result } = usePropertiesBySearchQuery(status);

  return (
    <Status
      text={`${text} (${result?.searchProperties?.total || 0})`}
      color={propertyStatusColors[status]}
    />
  );
}

TitleComp.displayName = 'TitleComp';

TitleComp.propTypes = {
  status: string,
  text: string,
};

function TabsWithPrefetch(props) {
  const { activePropertiesQueryResult, inactivePropertiesQueryResult } =
    usePrefetchProperties();

  return (
    <PageTabs
      {...props}
      {...activePropertiesQueryResult}
      {...inactivePropertiesQueryResult}
    />
  );
}

const tabs = {
  active: 'active-properties',
  inactive: 'inactive-properties',
};

export function PropertiesPage() {
  return (
    <>
      <PageHeaderContainer>
        <Heading size="h2">Properties</Heading>
      </PageHeaderContainer>
      <TabsWithPrefetch
        toolBar={
          <FlexColumn>
            <AddPropertyBtn />
            <SearchInput />
          </FlexColumn>
        }
        tabs={[
          [
            <TitleComp
              text="Active"
              status={ACTIVE}
              key={`title-${tabs.active}`}
            />,
            tabs.active,
            <PropertiesTable status={ACTIVE} key={tabs.active} />,
          ],
          [
            <TitleComp
              text="Inactive"
              status={INACTIVE}
              key={`title-${tabs.inactive}`}
            />,
            tabs.inactive,
            <PropertiesTable status={INACTIVE} key={tabs.inactive} />,
          ],
        ]}
        defaultValue={tabs.active}
      />
    </>
  );
}
