import * as R from 'ramda';

import { processesReducerByDefaultState } from '@poly/admin-ui';

export const processes = {
  INVOICE_SAVING: 'INVOICE_SAVING',
  EXPORTING_TO_PDF: 'EXPORTING_TO_PDF',
  ADD_SUPPLIER_MODAL: 'ADD_SUPPLIER_MODAL',
  PROPERTY_UPDATING: 'PROPERTY_UPDATING',
};

const defaultState = R.pipe(
  R.keys,
  R.reduce((obj, key) => R.assoc(key, false, obj), {}),
)(processes);

export const processesReducer = processesReducerByDefaultState(defaultState);
