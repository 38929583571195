import {
  entities,
  useEntityFiles,
  useEntityUpdates,
  useContactUsersSearchByQuery,
} from '@poly/admin-ui';
import {
  collectionNames,
  peopleStatuses,
  propertyStatuses,
} from '@poly/constants';
import { READ_CONTACT_USER_PERMISSION } from '@poly/security';
import { useHasUserAccessWithPermission } from '@poly/client-utils';
import { usePropertiesBySearchQuery } from './usePropertiesBySearch.js';
import { usePropertyDetailsSubscription } from './withPropertyDetails.js';
import { PROPERTY_PEOPLE } from '../PropertyPage/PropertyPeopleTab.js';
import { PROPERTY_UPDATES } from '../PropertyPage/PropertyUpdatesTab.js';
import { useOcrInvoicesByTextSearch } from '../../invoice/apollo/useOcrInvoicesBySearch.js';

export const usePrefetchProperties = () => {
  const activePropertiesQueryResult = usePropertiesBySearchQuery(
    propertyStatuses.ACTIVE,
  );
  const inactivePropertiesQueryResult = usePropertiesBySearchQuery(
    propertyStatuses.INACTIVE,
  );

  return {
    activePropertiesQueryResult,
    inactivePropertiesQueryResult,
  };
};

const usePrefetchPropertyInvoices = (property) => {
  const { loading, result } = useOcrInvoicesByTextSearch({
    query: { match: { propertyId: property?._id } },
    skipQuery: !property?._id,
  });

  return {
    loading,
    result,
  };
};

const usePropertyPeople = (entity) => {
  const ifHasPermission = useHasUserAccessWithPermission(
    READ_CONTACT_USER_PERMISSION,
  );

  const activePropertiesContactsQueryResult = useContactUsersSearchByQuery({
    gqlSearchQuery: PROPERTY_PEOPLE,
    status: peopleStatuses.ACTIVE,
    entity,
    skipQuery: !ifHasPermission,
  });

  const inactivePropertiesContactsQueryResult = useContactUsersSearchByQuery({
    gqlSearchQuery: PROPERTY_PEOPLE,
    status: peopleStatuses.INACTIVE,
    entity,
    skipQuery: !ifHasPermission,
  });

  return {
    activePropertiesContactsQueryResult,
    inactivePropertiesContactsQueryResult,
  };
};

export function usePrefetchPropertyCard() {
  const { result } = usePropertyDetailsSubscription();

  const entity = { _id: result?.property?._id, name: entities.PROPERTY };

  useEntityFiles({
    collection: collectionNames.properties,
    entity,
  });

  useEntityUpdates({
    query: PROPERTY_UPDATES,
    entity,
  });

  const { result: prefetchedPropertyInvoices } = usePrefetchPropertyInvoices(
    result?.property,
  );

  const {
    activePropertiesContactsQueryResult,
    inactivePropertiesContactsQueryResult,
  } = usePropertyPeople(entity);

  return {
    loading: result.loading,
    propertyDetails: result,
    prefetchedPropertyInvoices,
    activePropertiesContactsQueryResult,
    inactivePropertiesContactsQueryResult,
  };
}
