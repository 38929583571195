import React from 'react';
import { useHasUserAccessWithPermission } from '@poly/client-utils';
import { CREATE_PROPERTY_PERMISSION } from '@poly/security';
import { TextButton } from '@poly/admin-book';
import { useNavigate } from '@poly/client-routing';
import { routes } from '../../../routes/constants.js';

export function AddPropertyBtn(props) {
  const ifHasPermission = useHasUserAccessWithPermission(
    CREATE_PROPERTY_PERMISSION,
  );

  const navigate = useNavigate();

  return ifHasPermission ? (
    <TextButton {...props} onClick={() => navigate(routes.ADD_PROPERTY)}>
      Add New
    </TextButton>
  ) : null;
}

AddPropertyBtn.displayName = 'AddPropertyBtn';
