import React from 'react';
import * as R from 'ramda';
import { shape, arrayOf, string } from 'prop-types';
import { Select, Input } from '@poly/admin-book';
import {
  entityToOptionByLabelPath,
  addressValidators,
  equalsOmitFields,
  validatePhone,
  ifNotEmpty,
} from '@poly/client-utils';

import { StatusSelect } from '../components/index.js';
import { AddressSection } from '../../../fields/AddressSection/index.js';
import { applyWidth, halfWidth } from '../../../common.js';
import { PhoneNumberInput } from '../../../fields/index.js';
import { StoreNumberInput } from '../../../fields/PhoneNumberInput.js';

// getDivisionSelectOptions :: { formData: PropertyFormData } -> [Option]
const getDivisionSelectOptions = R.compose(
  R.map(R.applySpec({ value: R.identity, label: R.identity })),
  R.pathOr([], ['formData', 'client', 'configs', 'propertyFields', 'division']),
);

function DivisionSelect(props) {
  const options = getDivisionSelectOptions(props);

  return <Select {...props} options={options} />;
}

// getClientDistrictOptions :: { formData: PropertyFormData } -> [Option]
const getClientDistrictOptions = R.compose(
  R.map(entityToOptionByLabelPath(['name'])),
  R.pathOr([], ['formData', 'client', 'districts']),
);

function ClientDistrictSelect(props) {
  const options = getClientDistrictOptions(props);

  return <Select {...props} options={options} />;
}

function ClientNameInput(props) {
  return <Input disabled {...props} />;
}

export const accountInformationSection = [
  {
    label: 'Account Information',
    id: 'accountInfo',
    order: 1,
    layout: {
      column: 1,
    },
    fields: [
      {
        label: 'Name',
        order: 1,
        layout: { row: 1 },
        field: {
          name: 'name',
          Component: Input,
        },
        required: true,
        validators: [[R.identity, 'Property name is required']],
      },
      {
        label: 'Main account',
        layout: { row: 2 },
        order: 2,
        field: {
          name: 'client.name',
          Component: ClientNameInput,
        },
      },
      {
        label: 'Status',
        order: 1,
        layout: { row: 3, width: halfWidth },
        required: true,
        field: {
          name: 'status',
          Component: StatusSelect,
        },
      },
      {
        label: 'District',
        order: 2,
        layout: { row: 3, width: halfWidth },
        field: {
          name: 'clientDistrictId',
          withFormData: true,
          Component: ClientDistrictSelect,
        },
        renderIf: R.hasPath(['client', 'districts', 'length']),
      },
      {
        label: 'Store #',
        order: 1,
        layout: { row: 4, width: halfWidth },
        field: {
          name: 'storeNumber',
          Component: StoreNumberInput,
        },
        renderIf: R.path([
          'client',
          'configs',
          'propertyFields',
          'storeNumber',
        ]),
      },
      {
        label: 'Division',
        order: 2,
        layout: { row: 4, width: halfWidth },
        field: {
          name: 'division',
          withFormData: true,
          Component: DivisionSelect,
        },
        renderIf: R.path(['client', 'configs', 'propertyFields', 'division']),
      },
      {
        layout: { row: 5 },
        order: 4,
        field: {
          name: 'address',
          Component: AddressSection,
          isEqual: equalsOmitFields([['formatted_address']]),
        },
        required: true,
        validators: addressValidators,
      },
      {
        label: 'Phone',
        order: 5,
        layout: { row: 6, width: halfWidth },
        field: {
          name: 'phone',
          Component: applyWidth(PhoneNumberInput),
        },
        validators: [[ifNotEmpty(validatePhone), 'Incorrect phone number']],
      },
      {
        label: 'Fax',
        order: 6,
        layout: { row: 6, width: halfWidth },
        field: {
          name: 'fax',
          Component: applyWidth(PhoneNumberInput),
        },
        validators: [[ifNotEmpty(validatePhone), 'Incorrect fax number']],
      },
    ],
  },
];

ClientDistrictSelect.propTypes = {
  formData: shape({
    client: shape({ districts: arrayOf(shape({ _id: string, name: string })) }),
  }),
};
