import * as R from 'ramda';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OCRInvoiceStatuses } from '@poly/constants';
import {
  useInfiniteScrollQueryWithSubscription,
  keywordSortQuery,
  commonSortQuery,
  formatDateProp,
} from '@poly/client-utils';
import {
  useSelectableTableRowsProps,
  useMapConfigToTableProps,
  useTableSorting,
  useProcessState,
} from '@poly/admin-ui';

import {
  InvoiceLink,
  InvoicePDFLink,
} from '../../../containers/links/Links.js';
import {
  OCR_INVOICES_BY_SEARCH,
  OCR_INVOICES_BY_SEARCH_SUB,
} from '../apollo/useOcrInvoicesBySearch.js';
import { ITEMS_PER_SCROLL } from '../../../utils/constants/general.js';
import { setSelectedRows } from '../../../state/modules/selected-rows.js';
import { formatCurrencyProp } from '../../../utils/foramatters/index.js';
import { getFormattedInvoicesTotal } from '../../../utils/invoices.js';

const approveInvoiceProcessId = 'approveInvoiceProcessId';

// getSupplierName :: OCRInvoice -> String
const getSupplierName = R.path(['supplier', 'company', 'name']);

const config = [
  ['Invoice ID', InvoiceLink, keywordSortQuery(['invoiceId'])],
  [
    'Supplier',
    getSupplierName,
    keywordSortQuery(['supplier', 'company', 'name']),
  ],
  ['Invoice #', InvoicePDFLink, keywordSortQuery(['invoiceNumber'])],
  ['Inv Date', formatDateProp('invoiceDate'), commonSortQuery(['invoiceDate'])],
  ['Due Date', formatDateProp('dueDate'), commonSortQuery(['dueDate'])],
  ['Amount', formatCurrencyProp('total'), commonSortQuery(['total'])],
];

// getOcrInvoices :: SearchOcrInvoicesQueryResult -> [Invoice]
const getOcrInvoices = R.pathOr([], ['searchOCRInvoices', 'hits']);

// getTotalValue :: (SearchApprovalInvoicesQueryResult, [ID]) -> String
const getTotalValue = (data, selectedRows) =>
  R.pipe(
    R.pathOr([], ['searchOCRInvoices', 'hits']),
    R.filter(({ _id }) => R.any(R.equals(_id))(selectedRows)),
    getFormattedInvoicesTotal,
  )(data);

export const useApprovalInvoices = () => {
  const dispatch = useDispatch();
  const { process } = useProcessState(approveInvoiceProcessId);
  const selectedRows = useSelector((state) => state.selectedRows);

  const { sort, ...tableSortingProps } = useTableSorting({
    tableConfig: config,
    column: 1,
  });

  const input = useMemo(
    () => ({
      sort,
      query: { match: { 'workflow.status': OCRInvoiceStatuses.VERIFIED } },
    }),
    [sort],
  );

  const { data, loading, debouncedRefetch, tableProps } =
    useInfiniteScrollQueryWithSubscription(
      OCR_INVOICES_BY_SEARCH,
      input,
      {
        inputName: 'searchInput',
        pageSize: ITEMS_PER_SCROLL,
        endpointName: 'searchOCRInvoices',
      },
      OCR_INVOICES_BY_SEARCH_SUB,
      { input },
      !!process,
    );

  const preparedData = useMemo(() => getOcrInvoices(data), [data]);

  const total = useMemo(
    () => getTotalValue(data, selectedRows),
    [data, selectedRows],
  );

  const setSelectedRowsDispatched = (rows) => dispatch(setSelectedRows(rows));

  const { toggleRow, toggleSelectAll } = useSelectableTableRowsProps(
    selectedRows,
    setSelectedRowsDispatched,
    preparedData,
  );

  const dataTableProps = useMapConfigToTableProps(
    R.identity,
    config,
    preparedData,
  );

  return {
    total,
    loading,
    selectedRows,
    debouncedRefetch,
    isLoading: process,
    setSelectedRowsDispatched,
    id: approveInvoiceProcessId,
    tableProps: {
      toggleRow,
      selectedRows,
      toggleSelectAll,
      isRowSelectable: R.T,
      ...tableProps,
      ...dataTableProps,
      ...tableSortingProps,
    },
  };
};
