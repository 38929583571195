import React from 'react';
import {
  DefaultBodyWrapper,
  MainHeader,
  PageHeaderContainer,
} from '@poly/admin-book';
import { useProcessState } from '@poly/admin-ui';
import { addSupplierFormId } from './SupplierForm/constants.js';
import {
  ButtonsContainer,
  CancelFormBtn,
  SubmitBtn,
} from '../../containers/buttons/ActionButtons.js';
import { routes } from '../../routes/constants.js';
import { AddSupplierForm } from './SupplierForm/AddSupplierForm.js';

function AddSupplierFormHeader() {
  const { process } = useProcessState(addSupplierFormId);

  return (
    <PageHeaderContainer>
      <MainHeader>Create a New Supplier</MainHeader>
      <ButtonsContainer>
        <SubmitBtn disabled={process} form={addSupplierFormId} loader={process}>
          Create
        </SubmitBtn>
        <CancelFormBtn disabled={process} redirectUrl={routes.SUPPLIERS} />
      </ButtonsContainer>
    </PageHeaderContainer>
  );
}

AddSupplierFormHeader.displayName = 'AddSupplierFormHeader';

export function AddSupplierPage() {
  return (
    <>
      <AddSupplierFormHeader />
      <DefaultBodyWrapper>
        <AddSupplierForm />
      </DefaultBodyWrapper>
    </>
  );
}
