import * as R from 'ramda';
import {
  _shouldRenderNothingByPermission,
  CURRENT_USER_ACCESS_ITEMS,
} from '@poly/client-utils';
import { useQuery } from '@apollo/client';

export const useCheckTabsPermissions = ({ typesByProps = () => {}, tabs }) => {
  const { data } = useQuery(CURRENT_USER_ACCESS_ITEMS);

  return {
    tabs: R.reject(
      R.compose(
        R.both(
          _shouldRenderNothingByPermission({ ...data, typesByProps }),
          R.identity,
        ),
        R.nth(3),
      ),
      tabs,
    ),
  };
};
