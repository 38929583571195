import * as R from 'ramda';
import React from 'react';
import { string } from 'prop-types';
import { useLocation } from '@poly/client-routing';
import { routes } from '../../routes/constants.js';
import { BackButton as BackButtonC } from '../../components/lib/pageHeader.js';

const getLink = (defaultLink, location) =>
  R.pathOr(defaultLink, ['state', 'previous', 'pathname'], location);

export function BackButton({ defaultLink = routes.BATCHES, ...restProps }) {
  const location = useLocation();

  const link = getLink(defaultLink, location);

  return <BackButtonC {...restProps} link={link} />;
}

BackButton.propTypes = {
  defaultLink: string,
};
