import * as R from 'ramda';
import { useSelector } from 'react-redux';
import { useQuery, gql } from '@apollo/client';
import { useMapConfigToTablePropsWithSorting } from '@poly/admin-ui';
import { SupplierSources } from '@poly/constants';
import {
  filterItemsBySearchText,
  useHighlightMatchesBySearch,
} from '@poly/client-utils';

import { prepare1099ReportData } from './prepare1099ReportData.js';
import { report1099TableConfig } from './Report1099Table/Report1099Table.js';

export const SUPPLIERS_1099_REPORT_QUERY = gql`
  query SUPPLIERS_1099_REPORT_QUERY($input: SuppliersCompensationReportInput!) {
    suppliersCompensationReport(input: $input) {
      _id
      supplier {
        company {
          name
          addressTwo
          address {
            address_parts {
              street_number
              route
              locality
              administrative_area_level_1
              postal_code
            }
          }
        }
        tax {
          is1099
          id
          socialSecurityNumber
        }
      }
      compensation
    }
  }
`;

const highlightPaths = [
  ['name'],
  ['taxId'],
  ['address'],
  ['addressTwo'],
  ['city'],
  ['state'],
  ['zip'],
];

export const use1099Report = ({ year, is1099, threshold }) => {
  const { loading, data } = useQuery(SUPPLIERS_1099_REPORT_QUERY, {
    variables: {
      input: { year, is1099, threshold, source: SupplierSources.TIAA },
    },
    fetchPolicy: 'no-cache',
    skip: !year,
  });

  const searchText = useSelector((state) => state.searchText);

  const { rows, ...tableProps } = useMapConfigToTablePropsWithSorting({
    tableConfig: report1099TableConfig,
    items: prepare1099ReportData(data),
  });

  const filteredRows = filterItemsBySearchText(
    searchText,
    highlightPaths,
    rows,
  );

  const { highlightedRows } = useHighlightMatchesBySearch(
    R.identity,
    highlightPaths,
    filteredRows,
  );

  return {
    loading,
    filteredRows,
    tableProps: {
      ...tableProps,
      rows: highlightedRows,
    },
  };
};
