import React from 'react';
import { gql } from '@apollo/client';
import { useRouterParams } from '@poly/client-routing';
import { collectionNames } from '@poly/constants';
import { entities } from '@poly/admin-ui';
import { TasksTab } from '../../../containers/tabs/TasksTab.js';

const DEFAULT_OCR_INVOICE = gql`
  query DEFAULT_OCR_INVOICE($id: ID!) {
    ocrInvoice(id: $id) {
      _id
      invoiceNumber
    }
  }
`;

export function InvoiceTasksTab() {
  const { invoiceId } = useRouterParams(['invoiceId']);

  return (
    <TasksTab
      collection={collectionNames.ocrInvoices}
      defaultQuery={DEFAULT_OCR_INVOICE}
      entity={{
        _id: invoiceId,
        name: entities.OCR_INVOICE,
        humanReadableId: invoiceId,
      }}
    />
  );
}
