import {
  MasterSuppliersSearchResult,
  PropertiesSearchResult,
  SuppliersSearchResult,
} from '@poly/admin-ui';
import { documentUrlGeneratorById } from '@poly/client-routing';

import { routes } from '../../../routes/constants.js';
import { InvoicesSearchResult } from './InvoicesSearchResult.js';
import { ChecksSearchResult } from './ChecksSearchResult.js';

const ocrInvoiceHighlightPaths = [
  ['invoiceNumber'],
  ['invoiceId'],
  ['workflow', 'check', 'checkNumber'],
];

export const globalSearchTabsConfig = [
  {
    index: 'searchOCRInvoices',
    title: 'Invoices',
    Component: InvoicesSearchResult,
    highlightPath: ocrInvoiceHighlightPaths,
    urlGenerator: documentUrlGeneratorById(routes.INVOICE),
  },
  {
    index: 'searchChecks',
    title: 'Checks',
    Component: ChecksSearchResult,
    highlightPath: ocrInvoiceHighlightPaths,
    urlGenerator: documentUrlGeneratorById(routes.INVOICE),
  },
  {
    index: 'searchSuppliers',
    title: 'Suppliers',
    Component: SuppliersSearchResult,
    highlightPath: [
      ['company', 'name'],
      ['company', 'dba'],
      ['company', 'address', 'formatted_address'],
      ['remit', 'name'],
      ['tax', 'name'],
    ],
    urlGenerator: documentUrlGeneratorById(routes.SUPPLIER),
  },
  {
    index: 'searchMasterSuppliers',
    title: 'Master Suppliers',
    Component: MasterSuppliersSearchResult,
    highlightPath: [
      ['company', 'name'],
      ['company', 'dba'],
      ['remit', 'name'],
      ['company', 'address', 'formatted_address'],
      ['tax', 'name'],
    ],
    urlGenerator: documentUrlGeneratorById(routes.MASTER_SUPPLIER),
  },
  {
    index: 'searchProperties',
    title: 'Properties',
    Component: PropertiesSearchResult,
    highlightPath: [['name'], ['address', 'formatted_address']],
    urlGenerator: documentUrlGeneratorById(routes.PROPERTY),
  },
];
